import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import TextBlock from '../../components/UI/TextBlock/TextBlock';

class PrivacyPolicy extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="privacy_policy" />
                <TextBlock {...this.props}
                    bgColor="white" textColor="blue" bgColorLeft="white" bgColorRight="white" multiple
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'privacyPolicy')['infos']} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(PrivacyPolicy)