import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';


const initialSatate = {
    jobs: null,
    error: true,
    jobStructuredData: null
}

const jobs = (state = initialSatate, action) => {
    switch (action.type) {
        case actionTypes.FETCH_JOBS_FAIL:
            return updateObject(state, { jobs: null, error: true });
        case actionTypes.FETCH_JOBS_SUCCESS:
            return updateObject(state, { jobs: action.jobs, error: false });
        case actionTypes.SET_JOB_STRUCTURED_DATA:
            return updateObject(state, { jobStructuredData: action.structured_data });
        default:
            return state;
    }
};

export default jobs;