import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import FAQType from '../UI/FAQType/FAQType';
import Auxiliary from '../../hoc/Auxiliary';

const FAQ = props => {
    const infos = props.infos;
    const infoKeys = Object.keys(infos);
    let displayInfo;
    let content = null;

    const getFaq = (i) => {

        return (
            <FAQType
                key={i}
                title={infos[i]['title']}
                text={infos[i]['text']}
                buttonText={infos[i]['buttonText']}
                color={infos[i]['color']}
                icon={infos[i]['icon']}
                to={props.t('routes:' + i + '.path')}
            />
        )
    }

    displayInfo = infoKeys.map(i => {
        content = [];
        let faq = getFaq(i);

        content.push(
            faq
        )

        return (
            <Col className=" p-0 px-0 px-md-2 mb-4 mb-md-0 col-11 col-md-6" key={i}>
                {content}
            </Col>
        )
    })

    return (
        <Auxiliary>
            {/* title */}
            <Container fluid className='FAQ-title' >
                <Container>
                    <Row className='title-bar'>
                        <Col xl="auto" className=' title'>
                            <span className='font-weight-800 '>FAQS</span>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className='FAQ-content padding-63-tb'>
                <Container >
                    <Row className='justify-content-around'>
                        {displayInfo}
                    </Row>
                </Container>
            </Container>
        </Auxiliary >


    );
}


export default FAQ;