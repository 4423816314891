import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';

export function* tryFetchArticleSaga(action) {
    if (action.id) {
        try {
            const response = yield axios.get('https://blog-human.s3.amazonaws.com/dev/' + action.id + "/content.txt");
            if (!response.status === 200) {
                yield put(actions.failFetchArticle());
            }
            const article = response.data;
            yield put(actions.successFetchArticle(article));
        } catch (error) {
            yield put(actions.failFetchArticle());
        }
    }
    /* if (action.id) {
        try {
            const response = yield instanceWordpress.get('wp-json/wp/v2/posts/' + action.id + "?_embed");
            if (!response.status === 200) {
                yield put(actions.failFetchArticle());
            }
            const article = response.data;
            yield put(actions.successFetchArticle(article));
        } catch (error) {
            yield put(actions.failFetchArticle());
        }
    } */
}