import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import axios from 'axios';


export function* tryFetchJobsSaga(action) {
    try {
        const response = yield axios.get('https://www.hereandnow.agency/api/get-jobs/humanprofiler');
        if (!response.status === 200) {
            yield put(actions.failFetchJobs());
        }
        const jobs = response.data;
        yield localStorage.setItem('jobs', JSON.stringify(jobs));
        yield put(actions.successFetchJobs(jobs));
    } catch (error) {
        yield put(actions.failFetchJobs());
    }
}