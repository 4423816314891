import React, { useEffect, useState } from 'react';
import RecentArticle from '../RecentArticle/RecentArticle';
import { connect } from 'react-redux';
import Spinner from '../../UI/Spinner/Spinner'


const RecentPosts = (props) => {

    const [posts, setPosts] = useState(false)
    const [articlesToDisplay, setArticlesToDisplay] = useState(null)
    const [isLoading, setisLoading] = useState(true)
    const [error, seterror] = useState(null)

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
        //check if posts are already in localStorage
        if (typeof posts !== "undefined" && JSON.parse(sessionStorage.getItem('recentPosts')) !== null) {
            setPosts(JSON.parse(sessionStorage.getItem('recentPosts')))
            seterror(false)
            return;
        } else {
            setPosts(props.recentPosts);
            seterror(false)
            return;
        }
    }, [props.recentPosts])

    useEffect(() => {
        if (posts && posts.length > 0) {
            const posts_ = posts.slice(0, props.total);
            const articlesKeys = Object.keys(posts_);
            let articlesToShow = articlesKeys.map((key) => {
                const image = posts[key]._embedded['wp:featuredmedia']['0']['source_url'] === "undefined" ? null : posts[key]._embedded['wp:featuredmedia']['0']['source_url'];
                return (
                    <RecentArticle
                        key={key}
                        img={image}
                        text={posts_[key]['title']['rendered']}
                        link={props.t('routes:blog.path') + '/' + posts_[key].slug + '?id=' + posts_[key].id}
                        color="grey-medium-dark"
                    />
                )
            })
            setArticlesToDisplay(articlesToShow)
            setisLoading(false)

        }
    }, [posts])
    return (
        <>
            {isLoading ? < Spinner small /> : error ? <p>{props.t("blog:section1.errorPosts")}</p> : articlesToDisplay}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        recentPosts: state.recentPosts.recentPosts,
        error: state.posts.error
    }
}


export default connect(mapStateToProps)(RecentPosts)