import React from 'react'
import { Container, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';
//import Button from '../../Button/Button';
import { Link } from 'react-router-dom';


const TextBlock = props => {
    let i = null;
    let displayInfo;
    let content = null;
    const infos = props.infos;
    const infoKeys = Object.keys(infos);
    if (props.multiple) {
        const getText = (i) => {
            return (
                <div key={i}>
                    {infos[i].title && <p className='m-0 font-weight-700 policyText title' >
                        <Trans
                            t={props.t}
                            i18nKey={infos[i].title}
                            components={
                                {
                                    Uppercase: <span className='text-uppercase'></span >
                                }
                            }
                        />
                    </p>}
                    {infos[i].text && <p className={'policyText textColor-' + props.textColor}>
                        <Trans
                            t={props.t}
                            i18nKey={infos[i].text}
                            components={
                                {
                                    spanOrange: <span className='text-orange-light'></span >,
                                    HomePage: <Link to={props.t('routes:homepage.path')} className='link'></Link>,
                                    Spacer: <span className='ms-3'></span >,
                                    UnderLine: <span className='underline'></span>,
                                    LinkEmail: <a href="mailto:dpo@humanprofiler.com" className='link'></a>,
                                    bold: <b></b>
                                }
                            }
                        />
                    </p>}
                </div>
            )
        }
        displayInfo = infoKeys.map(key => {
            content = [];
            i = parseInt(key);

            let block = getText(i);
            content.push(
                block
            )
            i += 1;

            return (
                <div className="col p-0" key={key}>
                    {content}
                </div>
            )
        })
    }

    return (
        <Container fluid className={'TextBlock p-0 bgColorLeft-' + props.bgColorLeft + ' bgColorRight-' + props.bgColorRight}>
            <Container className={'content bgColor-' + props.bgColor}>
                {infos.title && <p className='m-0 title' >
                    <Trans
                        t={props.t}
                        i18nKey={infos.title}
                        components={
                            {
                                spanOrange: <span className='text-orange'></span >,
                                spanOrangeMedium: <span className='text-orange-medium'></span >,
                                Uppercase: <span className='text-uppercase'></span>,
                                br: <br />
                            }
                        }
                    />
                </p>}
                {infos.text && <p className={'m-0 font-weight-700 textColor-' + props.textColor}>
                    <Trans
                        t={props.t}
                        i18nKey={infos.text}
                        components={
                            {
                                spanOrange: <span className='text-orange-light'></span >,
                                spanOrangeMedium: <span className='text-orange-medium'></span >,
                                bold: <b></b>,
                                br: <br />
                            }
                        }
                    />
                </p>}
                {infos.titlesmaller && <h2 className={'m-0 mt-3 font-weight-700 textColor-' + props.textColor}>
                    <Trans
                        t={props.t}
                        i18nKey={infos.titlesmaller}
                        components={
                            {
                                spanOrange: <span className='text-orange-light'></span >,
                                spanOrangeMedium: <span className='text-orange-medium'></span >,
                                bold: <b></b>,
                                br: <br />
                            }
                        }
                    />
                </h2>}
                {infos.list && <p className={'m-0 font-weight-700 textColor-' + props.textColor}>
                    {Object.keys(infos.list).map((listItems) => {
                        return <li key={listItems}>
                            <Trans
                                t={props.t}
                                i18nKey={infos.list[listItems]}

                            />
                        </li>
                    })}
                </p>}
                {props.multiple && displayInfo}
                {props.btn && <Button onClick={props.click}>{props.btnText}</Button>}
            </Container>
        </Container>
    );
}

export default TextBlock;