import React from 'react'
import { Trans } from 'react-i18next'

const InfoBorderBlue = (props) => {
    return (
        props.children ?
            (<div className="info-border-blue title-text w-100">
                {Object.keys(props.children).map((child, key) => {
                    return (<h3 key={key} className="text-blue font-weight-800 text-center">{props.children[child]["title"]}</h3>)
                })}

            </div>)

            : props.text ?
                (<div className={props.equalHeight ? "info-border-blue title-text equal-height" : "info-border-blue title-text"}>
                    {props.title && <h3 className="text-blue font-weight-800">
                        <Trans
                            t={props.t}
                            i18nKey={props.title}
                        />
                    </h3>}
                    {props.text && <p className='m-0 font-weight-600'>{props.text}</p>}
                </div>) :
                (<div className="info-border-blue">
                    {props.title && <h3 className="text-blue font-weight-800 text-center">
                        <Trans
                            t={props.t}
                            i18nKey={props.title}
                        />
                    </h3>}
                </div>)
    )
}

export default InfoBorderBlue