import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import TextBlock from '../../components/UI/TextBlock/TextBlock';

class PersonalData extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="personal_policy" />
                <TextBlock {...this.props}
                    bgColor="white"
                    textColor="blue"
                    bgColorLeft="white"
                    bgColorRight="white"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'personalData')['infos']}
                    multiple
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(PersonalData);
