import React, { useState, useEffect, useRef } from 'react'
import Spinner from '../Spinner/Spinner';

const InstaFeed = (props) => {

    const { token, counter } = props;
    const placeholder = useRef();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showImage, setShowImage] = useState(false);
    let getPostsUrl = `https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&&access_token=${token}`;
    let refreshTokenUrl = `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${token}`;

    const callback = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setShowImage(true);
            }
        });
    };

    const options = {
        threshold: 1.0
    };

    const observer = new IntersectionObserver(callback, options);

    useEffect(() => {
        const fetchInstaData = async () => {
            setIsLoading(true);
            fetch(getPostsUrl)
                .then(response => response.json())
                .then(result => {
                    if (result.hasOwnProperty("error")) {
                        setIsError(true)
                    } else {
                        //remove videos from fetched posts
                        let filteredData = result.data.filter((item) => {
                            if (item.media_type !== "VIDEO")
                                return item
                        })
                        setData(filteredData)

                        observer.observe(placeholder.current);
                        // intersection observer set-up
                        return () => observer.disconnect();
                    }
                })
                .catch((error) => setIsError(true));
        };

        const refreshToken = async () => {
            fetch(refreshTokenUrl)
                .then(response => response.json())
                .catch((error) => setIsError(true))
            setIsLoading(false);
        }

        fetchInstaData();
        refreshToken();

    }, [token]);


    return (
        <div>
            {isLoading ? (<Spinner />)
                : isError ? (
                    <div>
                        <p>{props.t('blog:section2.instagramFeedError')}</p>
                    </div>)
                    : (
                        <div className="instagram-items" ref={placeholder}>
                            {showImage && (
                                data.slice(0, counter).map((item, index) => (
                                    <div key={index} className="instagram-item">
                                        <a
                                            key={index}
                                            href={item.permalink}
                                            className="ig-instagram-link"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                className="instagram-img"
                                                key={index}
                                                src={item.media_url}
                                                alt={item.caption}
                                            />
                                        </a>
                                    </div>
                                ))
                            )}
                        </div>
                    )
            }
        </div>
    );
}

export default InstaFeed;