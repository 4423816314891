import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Banner from '../../components/UI/Banner/Banner';
import Posts from '../../components/Blog/Posts/Posts';

class Blog extends Component {

    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="blog" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'blog')['section1']['infos']}
                    search={false}
                    blog={true}
                />
                <Posts {...this.props} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Blog)