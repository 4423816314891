import React, { useState } from 'react';
import { Container, Image } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Trans, withTranslation } from 'react-i18next';
import stand from '../../../assets/images/footer/stand.jpg'
import feder from '../../../assets/images/footer/POCentro_PT2020_FEDER_Bom.jpg'

const FooterModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const content = props.content
    return (
        <div className='FooterModal'>
            <a className='OpenButton' onClick={handleShow}>
                {props.modalBtn}
            </a>

            <Modal show={show} animation={false} onHide={handleClose} dialogClassName="footermodal-container d-flex align-items-center justify-content-center" centered /* scrollable */>
                <div className="btn-close" onClick={handleClose}></div>
                <Modal.Header >
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <p className='text font-weight-700'>
                            <b className='title'>
                                <Trans t={props.t} i18nKey={content.title} />
                            </b>
                            {props.project && <Image className='images' fluid src={feder} />}
                            <Trans t={props.t} i18nKey={content.text} />
                            {props.project && <Image className='images' fluid src={stand} />}
                        </p>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default withTranslation()(FooterModal)
