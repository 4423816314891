import { React } from 'react';
import { Container } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Auxiliary from '../../hoc/Auxiliary';
import Button from "../Button/Button"

const CallToAction = props => {

    const infos = props.infos;
    return (
        <Container fluid className={'call-to-action before-after p-0 type-' + props.ctaType + ' bgLeftColor-' + props.bgLeftColor}>
            <Container className={'padding-63-tb d-flex flex-column align-items-center content type-' + props.ctaType}>
                {props.ctaType === "2btn" ?
                    <Auxiliary>
                        <h3 className={props.textLeft ? "text-left text-md-center mb-4 mt-0 font-weight-600 font-22" : "text-center mb-4 mt-0 font-weight-600 font-22"}>
                            <Trans
                                t={props.t}
                                i18nKey={infos.text}
                                components={
                                    {
                                        spanOrange: <span className='text-orange'></span >,
                                        spanOrangeMedium: <span className='text-orange-medium'></span >,
                                        textBlue: <span className='text-blue'></span>,
                                        textWhite: <span className='text-white'></span>,
                                        fontBlack: <span className='font-weight-800'></span>,
                                        longText: <span className='longText'></span>,
                                        textUppercase: <span className='text-uppercase'></span>
                                    }
                                }
                            />
                        </h3>
                    </Auxiliary>
                    :
                    (props.noBtn ?
                        <Auxiliary>
                            <h3 className={props.textLeft ? 'text-left text-md-center mb-4 mt-0 font-weight-600' : 'text-center mb-4 mt-0 font-weight-600'}>
                                <Trans
                                    t={props.t}
                                    i18nKey={infos.text}
                                    components={
                                        {
                                            textBlue: <span className='text-blue'></span>,
                                            fontBlack: <span className='font-weight-800'></span>,
                                            spanOrangeMedium: <span className='text-orange-medium'></span >,
                                            LinkFAQ: <Link to={props.t('routes:FAQ_candidate.path')} className='link'></Link>,
                                            LinkContacts: <Link to={props.t('routes:contacts.path')} className='link'></Link>,
                                            smallLine: <span className='smallLine'></span>
                                        }
                                    }
                                />
                            </h3>
                        </Auxiliary>
                        :
                        <Auxiliary>
                            <h3 className="text-center mb-4 mt-0 font-weight-600">
                                <Trans
                                    t={props.t}
                                    i18nKey={infos.text}
                                    components={
                                        {
                                            spanOrange: <span className='text-orange'></span >,
                                            spanOrangeMedium: <span className='text-orange-medium'></span >,
                                            textBlue: <span className='text-blue'></span>,
                                            textWhite: <span className='text-white'></span>,
                                            fontBlack: <span className='font-weight-800'></span>,
                                            longText: <span className='longText'></span>,
                                            textUppercase: <span className='text-uppercase'></span>
                                        }
                                    }
                                />
                            </h3>
                        </Auxiliary>
                    )
                }
                {props.ctaType === "2btn" ?
                    <div className='d-flex align-items-center justify-content-between buttons'>
                        <Button text={infos.btn1} to={props.btn1to} />
                        <Button text={infos.btn2} to={props.btn2to} />
                    </div>
                    : (props.noBtn ? "" : <Button text={infos.buttonText} to={props.to} />)

                }
            </Container>
        </Container >
    )
}

export default CallToAction
