import React, { Component, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const FixedSocials = props => {

    const [socialsOpen, setSocialsOpen] = useState(false)

    return (
        <>
            <div className='FixedSocialsDesktop d-none d-lg-block'>
                <div className="socials">
                    <a href="https://www.linkedin.com/company/humanprofiler/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a href="https://www.instagram.com/human_profiler/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.youtube.com/channel/UC5uAOWarjU3Sr4h4rbork7g" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a href="https://www.facebook.com/HumanProfiler/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </div>
            </div >
            <div className='FixedSocials d-lg-none'>
                <div className={socialsOpen ? 'toggle-container active' : 'toggle-container'} onClick={() => setSocialsOpen(!socialsOpen)}>
                    <FontAwesomeIcon className='icon' icon={faPlus} />
                    <div className={socialsOpen ? 'content d-flex flex-column align-items-center open' : 'content d-flex flex-column align-items-center'}>
                        <a href="https://www.linkedin.com/company/humanprofiler/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://www.instagram.com/human_profiler/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.youtube.com/channel/UC5uAOWarjU3Sr4h4rbork7g" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href="https://www.facebook.com/HumanProfiler/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FixedSocials;