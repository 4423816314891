import React, { useEffect, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from '../Button/Button';
import JobModal from '../UI/JobModal/JobModal';
import Separator from '../UI/Separator/Separator';
import { connect } from 'react-redux';

const ImageInfo = (props) => {
    const btnRef = useRef();

    useEffect(() => {
        if (window.location.href.includes("#spontaneous")) {
            btnRef.current.click()
        }
    }, [])
    return (
        <div className={props.orangeSquare ? "orangeSquare image-info image-" + props.imagePos : "bg-grey-light image-info image-" + props.imagePos} id={props.modal ? "spontaneous" : null}>
            <Container>
                {props.imagePos === "right" &&
                    <Row className="flex-column-reverse flex-lg-row">
                        <Col lg={6} className={"info bgColor-" + props.bgColor}>
                            <div className="d-flex flex-column align-items-lg-end" >
                                <h2 className="text-blue font-weight-800 text-center text-lg-end w-100">{props.title}</h2>
                                <p className={props.title ? "text-blue font-weight-600 text-lg-end" : "text-blue text-end font-weight-600 textOnly"}>{props.text}</p>
                                {
                                    props.btn && <Button to={props.to} text={props.btn} />
                                }
                                {
                                    props.modal && <JobModal titleModal={props.titleModal} infos={props.modalInfo} modalBtn={props.btnText} offer="Candidatura Espontânea" btnRef={btnRef} />
                                }
                            </div>
                        </Col>
                        <Col lg={6} className={props.orangeBefore ? "padding-0-lr orangeBefore" : "padding-0-lr d-flex justify-content-center"}>
                            <img src={props.img} className="img-fluid " alt='' />
                        </Col>
                        {props.windowWidth >= 992 ?
                            props.orangeBefore ?
                                <Separator bgLeftColor="grey-light" bgRightColor="orange-light" leftSide="grey-light" rightSide="orange-light" /> :
                                <Separator bgLeftColor="grey-light" bgRightColor="white" leftSide="grey-light" rightSide="white" />
                            : ""
                        }
                    </Row>}
                {props.imagePos === "left" &&
                    <Row >
                        <Col lg={6} className={props.orangeBefore ? "padding-0-lr orangeBefore" : "padding-0-lr d-flex justify-content-center"}>
                            <img src={props.img} className="img-fluid img-mobile" alt='' />
                        </Col>
                        <Col lg={6} className="info">
                            <div className="d-flex flex-column align-items-lg-start">
                                <h2 className="text-blue font-weight-800 text-center text-lg-start">{props.title}</h2>
                                <p className={props.title ? "text-blue font-weight-600" : "text-blue textOnly font-weight-600"}>{props.text}</p>
                                <div className='btnContainer'>
                                    {
                                        props.btn && <Button to={props.to} text={props.btn} />
                                    }
                                    {
                                        props.modal && <JobModal titleModal={props.titleModal} infos={props.modalInfo} modalBtn={props.btnText} offer="Candidatura Espontânea" btnRef={btnRef} />
                                    }
                                </div>
                            </div>
                        </Col>
                        {props.windowWidth >= 992 ?
                            props.orangeBefore ?
                                <Separator bgLeftColor="orange-light" bgRightColor="grey-light" leftSide="orange-light" rightSide="grey-light" /> :
                                <Separator bgLeftColor="white" bgRightColor="grey-light" leftSide="white" rightSide="grey-light" />
                            : ""
                        }
                    </Row>}
            </Container>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}


export default connect(mapStateToProps)(ImageInfo)