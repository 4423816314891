import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../shared/functions';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import ArticleGroup from '../../components/Blog/ArticleGroup/ArticleGroup';
import Spinner from '../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux';
import { fetchArticle } from "../../store/actions/article"
class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            post: null,
            title: "",
            description: "",
            date: "",
            sharedImage: "",
            originalURL: "",
            isLoading: true,
            searchConcluded: false
        };
    }

    componentDidMount() {
        this.getPostID();
    }

    componentDidUpdate() {
        if (JSON.parse(sessionStorage.getItem('posts')) !== null && this.state.post === null) {
            this.getPostID()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.getPostID()
        }
        if (this.props.article !== null && nextProps.article.id !== this.props.article.id) {
            this.getPost(nextProps.article)
        }
    }

    getPostID() {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get("id");
        var posts = JSON.parse(sessionStorage.getItem('posts')) !== null ? JSON.parse(sessionStorage.getItem('posts')) : this.props.posts;
        const uri = window.location.pathname.split("/")[2];
        //remove ID from URL
        queryParams.delete(queryParams.get("id"))
        // if the uri don't have the id parameter it will search in posts for an article with the same slug and get his id

        if (id === null && posts !== null) {
            let postsKeys = Object.keys(posts) //!safari error TypeError: null is not an object (evaluating 'Object.keys(e)')
            let exist = false
            postsKeys.map(key => {
                if (posts[key].id === parseInt(id) || posts[key].slug === uri) {
                    id = posts[key].id
                    exist = true
                    this.props.onTryFetchArticle(id);

                    if (this.props.article !== null && this.props.article.id === id) {
                        this.setState({
                            ...this.state,
                            id: id,
                            uri: uri,
                            originalURL: queryParams,
                            isLoading: true,
                        }, () => this.getPost(this.props.article))
                    }
                }
            });
            if (!exist) {
                this.props.navigate('/not-found', { replace: true });
            }
        } else {
            this.props.onTryFetchArticle(id);
            if (this.props.article !== null && this.props.article.id === id) {
                this.setState({
                    ...this.state,
                    id: id,
                    uri: uri,
                    originalURL: queryParams,
                    isLoading: true,
                }, () => this.getPost(this.props.article))
            }

        }
    }

    getPost(post) {
        if (post !== null && typeof post !== "undefined") {
            let { title, description, content, date, sharedImage } = this.state;
            const dateObj = new Date(post.date);
            const sharedImagePath = post._embedded['wp:featuredmedia']['0']['source_url'] === "undefined" ? "https://blog.humanprofiler.com/wp-content/uploads/" + post._embedded['wp:featuredmedia']['0']['media_details']['file'] : post._embedded['wp:featuredmedia']['0']['source_url'];
            // const sharedImagePath = post._embedded['wp:featuredmedia']['0']['source_url'] === "undefined" ? "http://3.221.74.13/blog_human/wp-content/uploads/" + post._embedded['wp:featuredmedia']['0']['media_details']['file'] : post._embedded['wp:featuredmedia']['0']['source_url'];
            //set variables
            title = post['title']['rendered'];
            description = post['excerpt']['rendered'];
            content = post['content']['rendered'];
            date = (dateObj.getDate() + " " + this.props.t("partials:months." + dateObj.getMonth().toString()) + " " + dateObj.getFullYear());
            sharedImage = sharedImagePath;

            //remove ID from URL
            this.props.navigate(this.state.originalURL, { replace: true });

            if (post === null) {
                this.props.navigate('/not-found', { replace: true });
            } else {
                this.setState({
                    ...this.state,
                    post: post,
                    title: title,
                    description: description,
                    content: content,
                    date: date,
                    sharedImage: sharedImage,
                    isLoading: false,
                    searchConcluded: true
                })
            }
        }
    }

    render() {
        //current base url
        const currentBaseLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin : 'http://www.humanprofiler.com';
        const currentBlogLink = currentBaseLink + this.props.t("routes:blog.path") + "/"

        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="post" title={this.state.title} description={this.state.description} img={this.state.sharedImage} />
                <ArticleGroup
                    {...this.props}
                    individualPost={true}
                >
                    {!this.state.isLoading ? <>
                        <p className="date font-weight-600">{this.state.date}</p>
                        <h1 className="font-weight-800" dangerouslySetInnerHTML={{ __html: this.state.title }}></h1>
                        <p dangerouslySetInnerHTML={{ __html: this.state.content.replace(new RegExp("(?!https://www.linkedin.com|https://www.facebook.com)http(.*?)blog.humanprofiler.com/(?!wp-content)", 'g'), currentBlogLink) }}></p></> : <div className='h-100 d-flex align-items-center justify-content-center'><Spinner /></div>}
                    {/* <p dangerouslySetInnerHTML={{ __html: this.state.content.replace(new RegExp("(?!https://www.linkedin.com|https://www.facebook.com)http(.*?)3.221.74.13/blog_human/(?!wp-content)", 'g'), currentBlogLink) }}></p></> : <div className='h-100 d-flex align-items-center justify-content-center'><Spinner /></div>} */}
                </ArticleGroup>
            </Auxiliary >
        )
    }
}
const mapStateProps = state => {
    return ({
        recentPosts: state.recentPosts.recentPosts,
        posts: state.posts.posts,
        error: state.posts.error,
        article: state.article.article,
    });
}

const mapDispatchToProps = dispatch => {
    return {
        onTryFetchArticle: (id) => dispatch(fetchArticle(id)),
    }
};

export default connect(mapStateProps, mapDispatchToProps)(withRouter(withTranslation()(Post)))

