import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col } from 'react-bootstrap';
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();

export const OfferContent = (props) => {
    //prepare link to share
    const url = window.location.href;
    let mailTo = props.t('jobs:section3.job_content.infos.mail-to').replace(';title', encodeURIComponent(props.id && props.title));
    mailTo = mailTo.replace(';url', url);

    const refTextarea = useRef(null);
    const refBtn = useRef(null);
    const [btnShared, setBtnShared] = useState(props.t('jobs:section3.job_content.infos.copy-url'));
    const [lang, setLang] = useState("");

    useEffect(() => {
        let tempLang = lngDetector.detect(props.requirements);
        tempLang[0][0] === 'portuguese' ? setLang('pt') : setLang('en');
    })

    //function to copy link
    const copy = (e) => {
        e.preventDefault();
        if (refTextarea.current) {
            refTextarea.current.value = url;
            refTextarea.current.select();
            refTextarea.current.setSelectionRange(0, 99999);
            document.execCommand("copy");
        } else
            navigator.clipboard.writeText(url);

        setBtnShared(props.t('jobs:section3.job_content.infos.copied-url'));

        setTimeout(() => {
            setBtnShared(props.t('jobs:section3.job_content.infos.copy-url'));
        }, 1500);
    }

    return (
        <Row className='gx-0'>
            <Col xs={1} className='bg-white h-100 d-md-none' />
            <Col xs={11} md={12}>
                <Container className="bg-grey-light job-content">
                    {props.category &&
                        <>
                            <span className='text-orange text.uppercase font-weight-800 text-uppercase'>{props.t("jobs:section3.job_content.infos.category", { lng: lang })}</span>
                            <p className="text-blue font-weight-700 mb-2 mb-md-4" dangerouslySetInnerHTML={{ __html: props.category }} />
                        </>}
                    {props.description &&
                        <>
                            <span className='text-orange text.uppercase font-weight-800 text-uppercase'>{props.t("jobs:section3.job_content.infos.opportunity", { lng: lang })}</span>
                            <p className="text-blue font-weight-700" dangerouslySetInnerHTML={{ __html: props.description.replace(new RegExp("\n", 'g'), "</br>") }} />
                        </>}
                    {props.requirements &&
                        <>
                            <br />
                            <span className='text-orange text.uppercase font-weight-800 text-uppercase'>{props.t("jobs:section3.job_content.infos.profile", { lng: lang })}</span>
                            <p className="text-blue font-weight-700 mb-2 mb-md-4" dangerouslySetInnerHTML={{ __html: props.requirements.replace(new RegExp("\n", 'g'), "</br>").replace("recrutamento@humanprofiler.com", "<a href='mailto:recrutamento@humanprofiler.com'>recrutamento@humanprofiler.com</a>") }} />
                        </>}

                    <div className="icons my-3">
                        <Tooltip title={props.t('jobs:section3.job_content.infos.share-email')} arrow>
                            <Link to='#' onClick={(e) => {
                                window.location = mailTo;
                                e.preventDefault();
                            }}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </ Link>

                        </Tooltip>
                        {/* <Tooltip title={props.t('jobs:section3.job_content.infos.share-lk')} arrow>
                            <Link target="new" to={"//www.linkedin.com/shareArticle?mini=true&url=" + url} >
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </ Link>
                        </Tooltip> */}
                        <Tooltip title={props.t('jobs:section3.job_content.infos.share-fb')} arrow>
                            <Link target="new" to={"//www.facebook.com/sharer/sharer.php?u=" + url} >
                                <FontAwesomeIcon icon={faFacebookF} />
                            </ Link>
                        </Tooltip>
                        <Tooltip title={btnShared} arrow>
                            <Link to="#" ref={refBtn} tip="" htmlFor="btnTooltip" onClick={(e) => copy(e)}>
                                <FontAwesomeIcon icon={faLink} />
                            </Link>
                        </Tooltip>

                        <textarea className="hide_copy" ref={refTextarea} />
                    </div>
                </Container>
            </Col>

        </Row>

    )
}
