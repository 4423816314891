import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import JobModal from '../../JobModal/JobModal';
import { connect } from 'react-redux';

const JobOffer = (props) => {

    let displayButons;

    if (props.windowWidth <= 1023) {
        displayButons =
            <Col>
                <Row className='p-0 px-md-2 py-md-3'>
                    <p className='m-0 title font-weight-900'>{props.title}</p>
                </Row>
                <Row className='p-0 px-md-2 py-md-3'>
                    <div className='d-flex align-items-center wrapper-location'>
                        {props.location ? <img src={props.icon} alt='' className='p-1' /> : ""}
                        <p className='m-0 location font-weight-900'>{props.location}</p>
                    </div>
                </Row>
                <Row>
                    <Col className='d-flex align-items-center justify-content-center'>
                        <Link to={props.t('routes:jobs.path') + '/' + props.id} className='m-0 more text-uppercase font-weight-900 w-50 h-100 bg-grey-medium d-flex align-items-center justify-content-center'>{props.t('jobs:section2.more')}</Link>
                        <div className='w-50'>
                            <JobModal {...props} titleModal={props.t('jobs:section3.titleModal')} id={props.id} offer={props.id} btn100w />
                        </div>
                    </Col>
                </Row>
            </Col>
    } else {
        displayButons =
            <Row>
                <Col sm={1} className='orange-cube' />
                <Col className='d-flex align-items-center'>
                    <p className='m-0 title  font-weight-900'>{props.title}</p>
                </Col>
                <Col sm={2} className='d-flex align-items-center wrapper-location'>
                    {props.location ? <img src={props.icon} alt='' className='p-1' /> : ""}
                    <p className='m-0 location font-weight-900'>{props.location}</p>
                </Col>
                <Col sm={1} className='d-flex align-items-center'>
                    <Link to={props.t('routes:jobs.path') + '/' + props.id} className='m-0 more text-uppercase font-weight-900'>{props.t('jobs:section2.more')}</Link>
                </Col>
                <Col sm={2} className="d-flex justify-content-end">
                    <JobModal {...props} titleModal={props.t('jobs:section3.titleModal')} id={props.id} offer={props.id} />
                </Col>
            </Row>
    }

    return (
        <Container className='JobOffer p-0'>
            {displayButons}
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(JobOffer)
