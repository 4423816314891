import React from 'react';

const TeamItemBottom = (props) => {
    return (

        <div className="description bg-white">
            <h4 className="d-none d-lg-block text-orange-medium text-center font-weight-800">{props.name}</h4>
            <p className="text-blue font-weight-600">
                {props.description}
            </p>
        </div>

    )
}

export default TeamItemBottom
