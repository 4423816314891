export const structuredDataJob = (job) => {
    const linkServer = 'https://www.humanprofiler.com';
    let logoLink = "";
    let logoPath = "/images/logo_google_jobs_Human_Profiler.png";
    logoLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin + logoPath : linkServer + logoPath;
    let today = new Date();
    let data = {
        "@context": "http://schema.org/",
        "@type": "JobPosting",
        "datePosted": today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
        "hiringOrganization": {
            "@type": "Organization",
            "name": "Human Profiler",
            "sameAs": "https://www.humanprofiler.com/",
            "logo": logoLink
        },
        "directApply": "True",
        "title": job.job_title,
        "description": job.job_description.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, "") + job.job_requirements.replace(/<[^>]+>/g, "").replace(/(\r\n\r\n\r\n)/gm, ""),
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": job.city_name,
                "addressCountry": job.country_name
            }
        }
    };

    return JSON.stringify(data);
};