import React, { Component } from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Banner from '../../components/UI/Banner/Banner';
import CallToAction from '../../components/CallToAction/CallToAction'
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import Services from '../../components/Services/Services';
import imageBanner from '../../../src/assets/images/banners/industrias.jpg';


class Industries extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="industries" />
                <Banner  {...this.props}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'industries')['section1']['infos']}
                    search={false}
                    img={imageBanner}
                />
                <OrangeTitle
                    squarePosition="left"
                    squareColor="grey-light"
                    title={this.props.t('industries:section2.title')}
                />
                <Services {...this.props} multipleRows={true} cols={3} titleColor="orange-medium"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'industries')['section2']['infos']}
                    iconsFolder="industries"
                />
                <CallToAction
                    ctaType="white"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'industries')['section3']['infos']}
                />
            </Auxiliary>
        )
    }
}
export default withTranslation()(Industries)