import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";

import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions/cookies';
import { useLocation } from 'react-router-dom'

//Google Tag Manager e Google Analytics
import * as ReactGA from "react-ga";
import TagManager from 'react-gtm-module';

const CookiesBanner = props => {

    //const [cookies, setcookies] = useState(props.cookies);
    const [showBanner, setshowbanner] = useState(props.banner_visibility);

    const location = useLocation();
    let initG = false; //! prod change to false

    //listen for cookie value changes
    {/* Uncomment for prod version*/ }
    useEffect(() => {
        //set GA and GTM
        if (props.cookies) {
            ReactGA.initialize('UA-72968223-1');
            const tagManagerArgs = {
                gtmId: 'GTM-N89CNZ3'
            }
            TagManager.initialize(tagManagerArgs);
            initG = true;
        } else {
            initG = false;
        }
    }, [props.cookies])

    //set pageview on page change
    {/* Uncomment for prod version*/ }
    useEffect(() => {
        if (props.cookies) {

            ReactGA.pageview(location.pathname + location.search);
        }
    }, [location])

    //control banner visibility
    useEffect(() => {
        setshowbanner(props.banner_visibility)
    }, [props.banner_visibility])

    //init cookies value
    useEffect(() => {
        if (getCookieConsentValue("cookiesAccepted") !== props.cookies && getCookieConsentValue("cookiesAccepted")) {
            if (getCookieConsentValue("cookiesAccepted") === 'true')
                props.onAcceptCookies()
            else
                props.onRemoveCookies()
        }
    }, [])

    const acceptAllCookies = () => {
        props.onAcceptCookies();
        props.onSetBannervisibility("byCookieValue")
    }

    //remove cookies
    const removeAllCookies = () => {
        props.onRemoveCookies();
        props.onSetBannervisibility("byCookieValue");

        let hostname = document.location.hostname.replace('www.', '');
        //remove google cookies
        {/* Uncomment for prod version*/ }
        Cookies.remove("_ga", { path: '/', domain: hostname });
        Cookies.remove("_gat", { path: '/', domain: hostname });
        Cookies.remove("_gat_gtag_UA_72968223_1", { path: '/', domain: hostname });
        Cookies.remove("_gid", { path: '/', domain: hostname });

        // //reload to remove script
        setTimeout(
            () => window.location.reload(),
            1000
        );
    }

    return (
        <CookieConsent
            location="bottom"
            cookieName="cookiesAccepted"
            visible={showBanner}
            onAccept={() => {
                acceptAllCookies();
            }}
            onDecline={() => {
                removeAllCookies();
            }}
            buttonText={props.t('partials:cookies-banner.btn3')}
            enableDeclineButton
            declineButtonText={props.t('partials:cookies-banner.btn2')}
            declineButtonStyle={{ color: "#fff", fontSize: "12px", backgroundColor: "transparent", textDecoration: "underline", marginLeft: 0, marginBottom: 0, paddingLeft: 0, paddingBottom: 0 }}
            buttonStyle={{ color: "#fff", fontSize: "16px", fontWeight: 700, backgroundColor: "#EF4123", marginTop: "10px", marginLeft: 0, padding: "12px 6px" }}
            style={{ background: " #0F329D" }}
            buttonWrapperClasses="button-group"
            buttonClasses="Button"
            contentClasses="cookies-content"
            expires={150}>
            <h2 className="margin-10px-bottom">
                <Trans
                    t={props.t}
                    i18nKey="partials:cookies-banner.title"
                    components={{ orange: <span className="text-orange-medium"></span> }}
                />
            </h2>
            <p className="text-small">
                <Trans
                    t={props.t}
                    i18nKey="partials:cookies-banner.text"
                    components={{ linkCookies: <Link className="white" to={props.t('routes:cookies_policy.path')}></Link> }}
                />
            </p>
        </CookieConsent>
    )
}

const mapStateToProps = state => {
    return {
        cookies: state.cookies.accepted_cookies,
        banner_visibility: state.cookies.banner_visibility
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onAcceptCookies: () => dispatch(actions.acceptCookies()),
        onRemoveCookies: () => dispatch(actions.removeCookies()),
        onSetBannervisibility: (value) => dispatch(actions.setBannerVisibility(value))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CookiesBanner);