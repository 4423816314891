import React from 'react'
import { Link } from 'react-router-dom';
import icon from '../../../assets/icons/svg/icon-azul.svg';

const IconWithInfo = (props) => {

    let iconSrc = props.icon ? require('../../../assets/icons/' + props.icon) : icon;
    return (
        <div className="icon-with-info d-flex flex-column align-items-center">
            <div className="icon bg-white d-flex align-items-center justify-content-center">
                <img src={iconSrc} className="img-fluid" alt='' />
            </div>
            <div className="info text-center ">
                {props.title &&
                    (<h2 className={"text-uppercase font-weight-800 text-" + props.titleColor}>{props.title}</h2>)
                }
                {props.link &&
                    (<Link to={props.link} className="text-orange text-uppercase text-orange-medium font-weight-800">{props.btn}</Link>)
                }
                {props.text &&
                    (<p className="text-white font-weight-600 px-3">{props.text}</p>)
                }
            </div>
        </div>
    )
}


export default IconWithInfo