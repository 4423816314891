import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';


const initialSatate = {
    recentPosts: null,
    error: false
}

const recentPosts = (state = initialSatate, action) => {
    switch (action.type) {
        case actionTypes.FETCH_RECENTPOSTS_FAIL:
            return updateObject(state, { recentPosts: null, error: true });
        case actionTypes.FETCH_RECENTPOSTS_SUCCESS:
            return updateObject(state, { recentPosts: action.recentPosts });
        default:
            return state;
    }
};

export default recentPosts;