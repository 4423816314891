import { React } from 'react';
import { Accordion } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const AccordionItem = props => {
    return (
        <Accordion.Item eventKey={props.number} className="mx-3 my-2 my-md-4">
            <Accordion.Header><h3 className='font-weight-900'>{props.title}</h3></Accordion.Header>
            <Accordion.Body>
                <p className='m-0 font-weight-600'>
                    <Trans
                        t={props.t}
                        i18nKey={props.text}
                        components={
                            {
                                ul: <ul></ul>,
                                li: <li></li>,
                                LinkNearshoring: <Link to={props.t('routes:nearshoring.path')} className='link'></Link>,
                                LinkApplication: <Link to={props.t('routes:jobs.path') + "#spontaneous"} className='link'></Link>,
                                LinkOursourcing: <Link to={props.t('routes:outsourcing.path')} className='link'></Link>,
                                LinkRecruimentSelection: <Link to={props.t('routes:recruitment_selection.path')} className='link'></Link>,
                                LinkRecruimentProcess: <Link to={props.t('routes:recruitment_process.path')} className='link'></Link>,
                                LinkExecutiveSearch: <Link to={props.t('routes:executive_search.path')} className='link'></Link>,
                                LinkContact: <Link to={props.t('routes:contacts.path')} className='link'></Link>,
                                LinkJobs: <Link to={props.t('routes:jobs.path')} className='link'></Link>,
                                LinkBlog: <Link to={props.t('routes:blog.path')} className='link'></Link>,
                                LinkEmail: <a href="mailto:recrutamento@humanprofiler.com" className='link'></a>,
                                LinkLinkedin: <a href="https://www.linkedin.com/company/humanprofiler/" className='link'></a>,
                                LinkIndustries: <Link to={props.t('routes:industries.path')} className='link'></Link>
                            }
                        }
                    />
                </p>
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default AccordionItem
