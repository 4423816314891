import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';


const initialSatate = {
    article: null,
    error: false
}

const article = (state = initialSatate, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ARTICLE_FAIL:
            return updateObject(state, { article: null, error: true });
        case actionTypes.FETCH_ARTICLE_SUCCESS:
            return updateObject(state, { article: action.article });
        default:
            return state;
    }
};

export default article;