import React from 'react'

import { Col, Container, Row } from 'react-bootstrap';

import Categories from '../Categories/Categories';
import SearchBar from '../SearchBar/SearchBar';
import TagsGroup from '../TagsGroup/TagsGroup';
import RecentPosts from '../RecentPosts/RecentPosts';
import InstaFeed from '../../UI/InstaFeed/InstaFeed';

const ArticleGroup = props => {

    const search = props.i18n.getResource(props.i18n.language, 'blog')['section2']['search'];

    return (
        <div className={"articleGroup " + (props.individualPost ? "individualPost" : "listPosts")}>
            <Container className="articleGroup-content p-md-0">
                <Row className='gx-0'>
                    <Col className='d-xl-none'>
                        {!props.individualPost ? <SearchBar
                            {...props}
                            data={props.data}
                            searchTitle={search.searchTitle}
                            searchPlaceHolder={search.searchPlaceHolder}
                        /> : null}
                    </Col>
                    <Col xl={8}>
                        {props.individualPost ? props.children : <Row xs={1} lg={2}>{props.children}</Row>}
                    </Col>
                    <Col className="leftSide d-none d-xl-block">
                        {!props.individualPost ? <SearchBar
                            {...props}
                            data={props.data}
                            searchTitle={search.searchTitle}
                            searchPlaceHolder={search.searchPlaceHolder}
                        /> : null}
                        {/* <Categories
                            categories={props.i18n.getResource(props.i18n.language, 'blog')['section2']['categories']['categories']}
                            categoriesTitle={props.t('blog:section2.categories.title')}
                        /> */}
                        <Container>
                            <p className='title font-weight-800'>{props.t('blog:section2.recentArticle')}</p>
                        </Container>
                        <RecentPosts {...props} total={3} />
                        {/* <TagsGroup
                            tags={props.i18n.getResource(props.i18n.language, 'blog')['section2']['tags']['tags']}
                            tagsTitle={props.t('blog:section2.tags.title')}
                        /> */}
                        <Container>
                            <p className='title font-weight-800'>Instagram</p>
                        </Container>
                        <InstaFeed
                            {...props}
                            token="IGQVJVMmIySmJNb0k3MVY5dXhoTV82enQtV1NsYVZAFeXlQaUdFRzZA4NUtpQjJsbnNfX0M0ZAlF6YlIxQ2dRM2txRU9hbTBMMlBTdnZAocGxBT2pDM2FlbUdlRHd2dEdnRTcwa3hZANzAzTnRYcmY3eERpYQZDZD"
                            counter={9}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


export default ArticleGroup;