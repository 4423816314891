import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'

const Menu = props => {

    const iconStyle = {
        "marginLeft": "10px",
        "float": "right"
    }

    const optionsList = props.list;
    const optionsListKeys = Object.keys(optionsList);

    let list = optionsListKeys.map((key) => {

        let route = key === "xchangejob" ?
            <a href='https://xchangejob.com/' target="_blank" rel="noreferrer" className='d-flex align-items-center justify-content-between'>
                {optionsList[key]}
                <FontAwesomeIcon icon={faAngleRight} style={iconStyle} />
            </a> :
            <NavLink to={props.t(`routes:${key}.path`)} className='d-flex align-items-center justify-content-between'>
                {optionsList[key]}
                <FontAwesomeIcon icon={faAngleRight} style={iconStyle} />
            </NavLink>;

        return (
            <li key={key}>
                {route}
            </li>
        )
    });


    return (
        <div className={["menu", props.isOpen ? 'visible' : 'invisible'].join(" ")}>
            <ul className="list-unstyled">
                {list}
            </ul>
        </div>
    )
}

export default Menu