import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Banner from '../../components/UI/Banner/Banner';
import AccordionGroup from '../../components/UI/AccordionGroup/AccordionGroup';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import CallToAction from '../../components/CallToAction/CallToAction';
import imageBanner from '../../../src/assets/images/banners/faq_candidato.jpg';

class FAQCandidate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        };
    }

    SearchValue = (searchText) => {
        this.setState({ searchText: searchText })
    }

    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="FAQ_candidate" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'faqCandidate')['section1']['infos']}
                    search={true}
                    btnSearchText={this.props.t('faqCandidate:section1.infos.btnText')}
                    img={imageBanner}
                    parentCallback={this.SearchValue}
                />
                <OrangeTitle title={this.props.t('faqCandidate:section2.title')}
                    squareColor="white"
                    squarePosition="left"
                />
                <AccordionGroup
                    {...this.props}
                    noResult={this.props.t('faqCompany:section2.noResult')}
                    searchText={this.state.searchText}
                    columns={1}
                    bg="grey"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'faqCandidate')['section2']['infos']}
                />
                <CallToAction
                    ctaType="white"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'faqCandidate')['section3']['infos']}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(FAQCandidate)