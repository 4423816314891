import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const TitleInfo = props => {
    return (
        <Container fluid className={'titleInfo bgLeftColor-' + props.bgLeftColor + ' bgRightColor-' + props.bgRightColor}>
            <Container>
                {
                    props.half ?
                        props.uppercase ?
                            <Row >
                                <Col className='content'>
                                    <h1 className='m-0 font-weight-800 half uppercase'>{props.text}</h1>
                                </Col>
                                <Col md={6} lg={6} xl={6} xxl={6} />
                            </Row>
                            :
                            <Row >
                                <Col className='content'>
                                    <h1 className='m-0 font-weight-800 half'>{props.text}</h1>
                                </Col>
                                <Col md={6} lg={6} xl={6} xxl={6} />
                            </Row>
                        :
                        <Row className={props.title ? 'content title' : 'content'} >
                            <h1 className='m-0 font-weight-800'>{props.text}</h1>
                        </Row>
                }
            </Container >

        </Container>

    )
}

export default TitleInfo