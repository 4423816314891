import { React, useState } from 'react';

import Menu from '../Menu/Menu';


const Droplink = props => {

    const [MenuOpen, setMenuOpen] = useState(false);

    return (
        <li className="nav-link" onMouseOver={() => setMenuOpen(true)} onMouseLeave={() => setMenuOpen(false)} >
            {props.text}
            <Menu {...props} isOpen={MenuOpen} list={props.list} />
        </li>
    )
}

export default Droplink
