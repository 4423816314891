import { React } from 'react';
import { Col, Container, Image } from 'react-bootstrap';
import Separator from '../UI/Separator/Separator';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { useLocation } from 'react-router';

import mail from '../../assets/icons/thankYou/icon-mail.svg';

const ThankYouPage = props => {
    const { state } = useLocation();
    const { title, text, socials1, socials2 } = state ?? {};

    return (
        <section className='ThankYouPage'>
            <Separator bgLeftColor="white" bgRightColor="orange-medium" leftSide="orange-medium" rightSide="orange-medium" />
            <Separator bgLeftColor="grey-light" bgRightColor="white" leftSide="white" rightSide="white" />
            <Container>
                <div className='content'>
                    <div className='main-text d-flex flex-column align-items-center justify-content-center'>
                        <Col className='title' md={{ offset: 5 }} lg={{ offset: 4 }}>
                            <Col className='d-flex position-relative d-md-none justify-content-center align-items-center' xs={12} md={5} lg={4}>
                                <Image fluid src={mail} />
                            </Col>
                            <h1 className='text-uppercase mb-4 mt-4 mt-md-0'>{title ? title : props.title}</h1>
                        </Col>
                        <div className='img-content col-md-12 d-flex flex-column flex-md-row align-items-center'>
                            <Col className='d-none position-relative d-md-flex justify-content-center align-items-center' xs={12} md={5} lg={4}>
                                <Image fluid src={mail} />
                            </Col>
                            <Col className='text d-flex align-items-center'>
                                <p className='text-uppercase m-0'>{text ? text : props.text}</p>
                            </Col>
                        </div>
                    </div>
                    <div className='socials d-flex flex-column align-items-center justify-content-center'>
                        <p className={socials2 ? "m-0" : ""}>{socials1 ? socials1 : props.socials1}</p>
                        {socials2 ? <p>{socials2}</p> : props.socials2 ? <p>{props.socials2}</p> : <></>}
                        <div className='socials-links'>
                            <a href="https://www.linkedin.com/company/humanprofiler/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                            <a href="https://www.instagram.com/human_profiler/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://www.youtube.com/channel/UC5uAOWarjU3Sr4h4rbork7g" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a href="https://www.facebook.com/HumanProfiler/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </div>
                    </div>
                    <div className='btn-container'>
                        <div>
                            <Button text={props.t("thankYou:btn")} to={props.t("routes:homepage.path")} />
                        </div>
                    </div>
                </div>
            </Container>
            <Separator bgLeftColor="white" bgRightColor="orange-medium" leftSide="orange-medium" rightSide="orange-medium" />
        </section>
    )
}

export default ThankYouPage;