import axios from "axios";

export const instanceWordpress = axios.create({
    baseURL: 'http://3.221.74.13/blog_human/',
    //baseURL: 'http://3.221.74.13/blog_human/'
});

export const instance_API_AWS = axios.create({
    // baseURL: 'https://oisljbz8m3.execute-api.eu-west-1.amazonaws.com/test',
    baseURL: 'https://oisljbz8m3.execute-api.eu-west-1.amazonaws.com/prod',
    headers: {
        'x-api-key': 'ev4nLoS6Gz6ev67rRj0BBaEWzriDCJQG4sbmpZnS',
    }
});
