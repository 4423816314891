import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';

const initialState = {
    //accepted_cookies: null,  
    banner_visibility: "byCookieValue"
}

const cookies = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCEPT_COOKIES:
            return updateObject(state, { accepted_cookies: true });
        case actionTypes.REMOVE_COOKIES:
            return updateObject(state, { accepted_cookies: false });
        case actionTypes.SET_BANNER_VISIBILITY:
            return updateObject(state, { banner_visibility: action.value });
        default:
            return state;
    }
}

export default cookies;