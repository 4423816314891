import React from 'react'
import { FormCheck } from 'react-bootstrap';

const CheckboxItem = props => {

    return (
        <div className='form-check d-flex align-items-center'>
            <FormCheck type="checkbox" className=' p-2 pe-3' />
            <h3 className='check-label font-weight-900'>{props.text} </h3>
        </div>
    );
}


export default CheckboxItem;