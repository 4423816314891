import React from 'react'
import icon from '../../../assets/icons/svg/icon-linhas-bola.svg';

const IconTitleText = (props) => {
    let iconSrc = props.icon ? require('../../../assets/icons/' + props.icon) : icon;
    return (
        <div className="d-flex flex-column align-items-center align-items-lg-start icon-title-text text-center">
            <img src={iconSrc} className="img-fluid mx-auto" alt="" />
            <h3 className="font-weight-900 text-white mx-auto">{props.title}</h3>
            <p className="text-white font-weight-700">{props.text}</p>
        </div>
    )
}

export default IconTitleText