import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import CallToAction from '../../components/CallToAction/CallToAction';
import SeparatorWithText from '../../components/UI/SeparatorWithText/SeparatorWithText';
import Head from '../../components/Partials/Head/Head';
import HomeBanner from '../../components/UI/HomeBanner/HomeBanner';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import Services from '../../components/Services/Services';
import Expertise from '../../components/Expertise/Expertise';
import ImageInfo from '../../components/ImageInfo/ImageInfo';
import FAQ from '../../components/FAQ/FAQ'
import HalfSeparator from '../../components/UI/HalfSeparator/HalfSeparator';
import Team from '../../components/Team/Team';
import Separator from '../../components/UI/Separator/Separator';
import img1 from '../../assets/images/homepage/empresa.jpg';
import img2 from '../../assets/images/homepage/emprego.jpg';
import img3 from '../../assets/images/homepage/expertise.jpg';
import SeparatorMobile from '../../components/UI/SeparatorMobile/SeparatorMobile';

class Homepage extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="homepage" />
                <HomeBanner {...this.props} />
                <Separator
                    bgLeftColor="blue"
                    bgRightColor="white"
                    leftSide="grey-light"
                    rightSide="white"
                    leftCube
                />
                <HalfSeparator
                    bg="grey-medium"
                    pos="right"
                />
                <ImageInfo
                    imagePos="left"
                    title={this.props.t('homepage:section2.1.title')}
                    text={this.props.t('homepage:section2.1.text')}
                    btn={this.props.t('homepage:section2.1.btn')}
                    img={img1}
                    to={this.props.t('routes:contacts.path')}
                />
                <SeparatorWithText
                    text="human"
                    textSide="right"
                    bgStart="grey"
                    bgEnd="white"
                />
                <Separator
                    bgLeftColor="white"
                    bgRightColor="orange-medium"
                    leftSide="orange-medium"
                    rightSide="orange-medium"
                    leftCube
                />
                <HalfSeparator
                    bg="grey-medium"
                    pos="left" />
                <ImageInfo
                    imagePos="right"
                    title={this.props.t('homepage:section2.2.title')}
                    text={this.props.t('homepage:section2.2.text')}
                    btn={this.props.t('homepage:section2.2.btn')}
                    img={img2}
                    to={this.props.t('routes:jobs.path')}
                />
                <SeparatorWithText
                    text="Profiler"
                    textSide="left"
                    spaceEnd={true}
                    bgStart="white"
                    bgEnd="blue" />
                <SeparatorMobile
                    leftColor="white"
                    rightColor="blue"
                />
                <OrangeTitle
                    uppercase
                    squarePosition="right"
                    squareColor="white"
                    title={this.props.t('homepage:section3.title')}
                />
                <Services
                    {...this.props}
                    btn
                    titleColor="white"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'homepage')['section3']}
                    iconsFolder="homepage"
                />
                <Separator
                    bgLeftColor="orange-light"
                    bgRightColor="white"
                    leftSide="white"
                    rightSide="grey-medium"
                    leftCube
                />
                <OrangeTitle
                    uppercase
                    squarePosition="left"
                    squareColor="white"
                    title={this.props.t('homepage:section4.title')}
                />
                <Expertise
                    {...this.props}
                    img={img3}
                />
                <Separator
                    bgLeftColor="orange-medium"
                    bgRightColor="white"
                    leftSide="white"
                    rightSide="grey-medium"
                />
                <FAQ
                    {...this.props}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'homepage')['section5']['infos']}
                />
                <Separator
                    bgLeftColor="white"
                    bgRightColor="orange-medium"
                    leftSide="grey-medium"
                    rightSide="white"
                />
                <HalfSeparator
                    bg="grey-medium"
                    pos="left"
                    mobile
                />
                <CallToAction
                    ctaType="blue-full"
                    textColor="white"
                    to={this.props.t('routes:recruitment_process.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'homepage')['section6']['infos']}
                    bgLeftColor="blue"
                />

                <Separator
                    bgLeftColor="grey-medium"
                    bgRightColor="white"
                    leftSide="white"
                    rightSide="grey-medium"
                />
                <SeparatorMobile
                    leftColor="white"
                    rightColor="grey-medium"
                />
                <OrangeTitle
                    uppercase
                    squarePosition="left"
                    title={this.props.t('homepage:section7.title')}
                />
                <Team
                    team={this.props.i18n.getResource(this.props.i18n.language, 'homepage')['section7']['team']}
                />
                <SeparatorMobile
                    leftColor="orange-medium"
                    rightColor="blue"
                />
                <CallToAction
                    ctaType="white"
                    to={this.props.t('routes:jobs.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'homepage')['section8']['infos']}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Homepage);

