import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import TextBlock from '../../components/UI/TextBlock/TextBlock';
import * as actions from '../../store/actions/cookies';

class CookiesPolicy extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="cookies_policy" />
                <TextBlock {...this.props}
                    bgColor="white"
                    textColor="blue"
                    bgColorLeft="white"
                    bgColorRight="white"
                    multiple
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'cookiesPolicy')['infos']}
                    btn
                    btnText={this.props.t('cookiesPolicy:buttonText')}
                    click={() => this.props.onSetBannervisibility("show")} />
            </Auxiliary>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        onSetBannervisibility: (value) => dispatch(actions.setBannerVisibility(value))
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(CookiesPolicy));
