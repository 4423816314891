import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const FAQType = props => {

    let icon = props.icon ? require('../../../assets/icons/homepage/' + props.icon) : '';

    return (
        <Container className={'FAQType-container d-flex flex-column bg-' + props.color}>
            <div className='title d-flex'>
                <Col xl={2} className="icon-container d-flex" >
                    <Container className='icon'>
                        {props.icon && <img src={icon} className="img-fluid" alt='' />}
                    </Container>
                </Col>
                <Col className='d-flex align-items-center'>
                    <span className='font-weight-800 text-uppercase'>{props.title}</span>
                </Col>
            </div>
            <div className={'content d-flex ' + props.color}>
                <Col xl={2}></Col>
                <div className='d-flex flex-column justify-content-between align-items-start'>
                    <Row>
                        <span className='py-2 font-weight-600'>{props.text}</span>
                    </Row>
                    <Row>
                        <Link to={props.to} className='text-uppercase font-weight-800 py-3'>{props.buttonText}</Link>
                    </Row>
                </div>
            </div>
        </Container>

    );
}


export default FAQType;