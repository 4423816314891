import React, { Suspense } from 'react'
import { Container, Row } from 'react-bootstrap'
import TeamItem from '../UI/TeamItem/TeamItem'
import Carousel from "../UI/Carousel/Carousel";
import Spinner from '../UI/Spinner/Spinner';

const Team = (props) => {

    let team = props.team;
    let teamKeys = Object.keys(team);

    let teamDisplay = teamKeys.map((key) => {
        return (
            <TeamItem
                key={key}
                name={team[key]['name']}
                email={team[key]['email']}
                position={team[key]['position']}
                description={team[key]['description']}
                img={team[key]['image']}
            />
        )
    })

    return (
        <div className="team">
            <Container>
                <Row className="justify-content-center">
                    <Suspense fallback={<Spinner />}>
                        <Carousel className="teamSlide">
                            {teamDisplay}
                        </Carousel>
                    </Suspense>
                </Row>
            </Container>
        </div>
    )
}

export default Team