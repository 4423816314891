import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';


const SeparatorWithTextMobile = props => {

    return (
        <Row className='separatorWithTextMobile d-flex gx-0 h-63px w-100 d-lg-none'>
            <Col xs={1} className='bg-orange-medium h-100' />
            <Col xs={10} className="d-flex align-items-center justify-content-center">
                <span className='text-uppercase text-orange-medium font-weight-800 text'>{props.text}</span>
            </Col>
            <Col xs={1} className='bg-orange-medium h-100' />
        </Row>
    );
}


export default SeparatorWithTextMobile;