import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Banner from "../../components/UI/Banner/Banner";
import SeparatorWithText from '../../components/UI/SeparatorWithText/SeparatorWithText';
import ImageInfo from '../../components/ImageInfo/ImageInfo';
import CallToAction from '../../components/CallToAction/CallToAction'
import Separator from '../../components/UI/Separator/Separator';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import JobOffers from '../../components/UI/JobOffers/JobOffers';
import Auxiliary from '../../hoc/Auxiliary';
import imageBanner from '../../../src/assets/images/banners/ofertas_emprego.jpg';
import img1 from '../../assets/images/job_offers/candidatura.jpg';
import SeparatorMobile from '../../components/UI/SeparatorMobile/SeparatorMobile';
import SeparatorWithTextMobile from '../../components/UI/separatorWithTextMobile/separatorWithTextMobile';

class Jobs extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="jobs" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section1']['infos']}
                    img={imageBanner}
                />
                <OrangeTitle
                    squarePosition="left"
                    squareColor="grey-light"
                    title={this.props.t('jobs:section2.title')}
                />
                <JobOffers {...this.props}
                    iconsFolder="jobs"
                    modalInfo={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section3']}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section2']}
                    workspace={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section2']['workspace']}
                    location={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section2']['location']}
                    modalBtn={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section2']['modalBtn']}
                    more={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section2']['more']}
                />
                <SeparatorWithText text={this.props.t('jobs:separator.title')}
                    spaceEnd={false}
                    textSide="right"
                    bgStart="orange"
                    bgEnd="white"
                />
                <Separator bgLeftColor="white" bgRightColor="grey-medium" leftSide="grey-medium" rightSide="grey-medium" leftCube />
                <SeparatorWithTextMobile text={this.props.t('jobs:separator.title')} />
                <ImageInfo
                    imagePos="left"
                    orangeBefore
                    img={img1}
                    titleModal={this.props.t('jobs:section4.titleModal')}
                    title={this.props.t('jobs:section4.title')}
                    text={this.props.t('jobs:section4.text')}
                    btnText={this.props.t('jobs:section4.btn')}
                    modal
                />
                <Separator bgLeftColor="orange-medium" bgRightColor="blue" leftSide="white" rightSide="white" />
                <SeparatorMobile leftColor="orange-light" rightColor="blue" />
                <CallToAction
                    ctaType="2btn"
                    btn1to={this.props.t('routes:FAQ_candidate.path')}
                    btn2to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section5']['infos']}
                />
                <Separator bgLeftColor="grey-light" bgRightColor="orange-medium" leftSide="grey-medium" rightSide="grey-medium" />
                <CallToAction
                    ctaType="grey-light"
                    to={this.props.t('routes:recruitment_selection.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'jobs')['section6']['infos']}
                />
                <Separator bgLeftColor="grey-medium" bgRightColor="white" leftSide="grey-medium" rightSide="grey-medium" />
            </Auxiliary>
        )
    }
}


export default withTranslation()(Jobs)
