import * as actionsTypes from "./actionsTypes";

export const fetchPosts = () => {
    return {
        type: actionsTypes.FETCH_POSTS,
    }
};

export const failFetchPosts = () => {
    return {
        type: actionsTypes.FETCH_POSTS_FAIL,
    }
};
export const successFetchPosts = (posts) => {
    return {
        type: actionsTypes.FETCH_POSTS_SUCCESS,
        posts: posts
    }
};


