import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

const OfferTitle = (props) => {
    return (
        <Row className="offer-title gx-0">
            <Col xs={1} className='bg-orange h-100 d-md-none' />
            <Col xs={11} md={12}>
                <Container className="bg-white d-flex align-items-center">
                    {props.title && <h1 className="text-uppercase text-blue font-weight-800">{props.title}</h1>}
                </Container>
            </Col>

        </Row>
    )
}

export default OfferTitle