import React, { Component } from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import CallToAction from '../../components/CallToAction/CallToAction'
import ImageInfo from '../../components/ImageInfo/ImageInfo';
import HalfSeparator from '../../components/UI/HalfSeparator/HalfSeparator';
import TitleInfo from '../../components/UI/TitleInfo/TitleInfo';
import SeparatorWithText from '../../components/UI/SeparatorWithText/SeparatorWithText';
import Services from '../../components/Services/Services';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import Separator from '../../components/UI/Separator/Separator';
import img1 from '../../../src/assets/images/mission_values/proposito.jpg'
import img2 from '../../../src/assets/images/mission_values/missao.jpg'

class MissionValues extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="mission_values" />
                <TitleInfo
                    half
                    uppercase
                    bgLeftColor="orange"
                    bgRightColor="white"
                    text={this.props.t('missionValues:section1.title')}
                />
                <HalfSeparator bg="grey-medium" pos="right" />
                <ImageInfo
                    imagePos="left"
                    title={this.props.t('missionValues:section1.infos.1.title')}
                    text={this.props.t('missionValues:section1.infos.1.text')}
                    img={img1}
                />
                <SeparatorWithText text="Human" textSide="right" spaceEnd={false} bgStart="grey" bgEnd="white" />
                <Separator bgLeftColor="white" bgRightColor="orange-medium" leftSide="orange-medium" rightSide="orange-medium" leftCube />
                <HalfSeparator bg="grey-medium" pos="left" />
                <ImageInfo
                    imagePos="right"
                    title={this.props.t('missionValues:section1.infos.2.title')}
                    text={this.props.t('missionValues:section1.infos.2.text')}
                    img={img2}
                />
                <SeparatorWithText text="profiler" textSide="left" spaceEnd={true} bgStart="white" bgEnd="blue" />
                <OrangeTitle
                    squarePosition="right"
                    squareColor="white"
                    title={this.props.t('missionValues:section2.title')}
                />
                <Services
                    {...this.props}
                    btn={false}
                    titleColor="orange"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'missionValues')['section2']['infos']}
                    iconsFolder="mission_values"
                />
                <CallToAction
                    ctaType="white"
                    to={this.props.t('routes:jobs.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'missionValues')['section3']['infos']} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(MissionValues)