import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Banner from '../../components/UI/Banner/Banner';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import InfoBlocks from '../../components/UI/InfoBlocks/InfoBlocks';
import InfoIcons from '../../components/UI/InfoIcons/InfoIcons';
import CallToAction from '../../components/CallToAction/CallToAction';
import TextBlock from '../../components/UI/TextBlock/TextBlock';
import CheckboxGroup from '../../components/UI/CheckboxGroup/CheckboxGroup';
import Separator from '../../components/UI/Separator/Separator';
import imageBanner from '../../../src/assets/images/banners/nearshoring_it.jpg';
import HalfSeparator from '../../components/UI/HalfSeparator/HalfSeparator';

class Nearshoring extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="nearshoring" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'nearshoring')['section1']['infos']}
                    search={false}
                    twoSquares
                    leftSquareColor="white"
                    rightSquareColor="blue"
                    img={imageBanner}
                />
                <OrangeTitle title={this.props.t('nearshoring:section2.title')}
                    squareColor="white"
                    squarePosition="right" />
                <InfoIcons
                    iconsFolder="nearshoring" cube
                    content={this.props.i18n.getResource(this.props.i18n.language, 'nearshoring')['section2']['infos']}
                />
                <OrangeTitle title={this.props.t('nearshoring:section3.title')}
                    squareColor="grey-light"
                    squarePosition="left" />
                <InfoBlocks square infos={this.props.i18n.getResource(this.props.i18n.language, 'nearshoring')['section3']['infos']} />
                <OrangeTitle title={this.props.t('nearshoring:section4.title')}
                    squareColor="white"
                    squarePosition="right" />
                <TextBlock bgColor="blue" textColor="white" bgColorLeft="grey-medium" bgColorRight="grey-medium"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'nearshoring')['section4']['infos']} />
                <Separator bgLeftColor="white" bgRightColor="white" leftSide="grey-medium" rightSide="white" />
                <OrangeTitle title={this.props.t('nearshoring:section5.title')}
                    squareColor="white"
                    squarePosition="left" />
                <CheckboxGroup infos={this.props.i18n.getResource(this.props.i18n.language, 'nearshoring')['section5']['infos']} />
                <CallToAction
                    ctaType="blue-full"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'nearshoring')['section6']['infos']}
                />
                <Separator bgLeftColor="white" bgRightColor="white" leftSide="grey-medium" rightSide="white" />
                <HalfSeparator
                    bg="orange-light"
                    pos="left"
                    mobile
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Nearshoring)