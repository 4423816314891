import React from 'react'

import { Container, FormControl, InputGroup } from 'react-bootstrap';

const SearchBar = props => {
    const search = (e) => {
        props.data(e.target.value);
    }
    return (
        <Container fluid className='SearchBar'>
            <p className='search-title font-weight-800'>{props.searchTitle}</p>
            <InputGroup className="mb-md-3">
                <FormControl
                    onKeyUp={e => { search(e) }}
                    placeholder={props.searchPlaceHolder}
                />
            </InputGroup>
        </Container>
    );
}


export default SearchBar;