import { React } from 'react';
import { Container, Image, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RecentArticle = props => {

    return (
        <Link to={props.link} className="text-decoration-none">
            <Container className={props.fluid ? 'RecentArticle d-flex align-items-center mb-4 p-0' : 'RecentArticle d-flex align-items-center mb-4'}>
                {/* image */}
                <Col xs={3} md={2} lg={3} xl={3}>
                    <Image src={props.img} fluid="true" />
                </Col>

                {/* title */}
                <Col>
                    <p className='recent-article m-0 ps-4' dangerouslySetInnerHTML={{ __html: props.text }} ></p>
                </Col>
            </Container>
        </Link>

    )
}

export default RecentArticle
