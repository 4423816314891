import React from 'react';
import { Container, Col } from 'react-bootstrap';
import Auxiliary from '../../../hoc/Auxiliary';

const OrangeTitle = props => {
    return (

        <Col xs={12} className={"bg-orange-medium orange-title square-" + props.squarePosition + " square-" + props.squareColor}>
            <Container className={props.justifyStart ? "bg-orange-medium py-3 h-md-100 d-flex align-items-center justify-content-start" : "bg-orange-medium py-3 h-md-100 d-flex align-items-center justify-content-center"}>
                <div className="title">
                    {props.subtitle ?
                        <Auxiliary>
                            <h2 className={props.uppercase ? "text-white text-uppercase font-weight-800 text-center" : "text-white font-weight-800 text-center"}>{props.title}</h2>
                            <h2 className="subtitle text-white text-center font-weight-600">{props.subtitle}</h2>
                        </Auxiliary> :
                        <h2 className={props.uppercase ? "text-white font-weight-800 text-uppercase" : "text-center text-white font-weight-800"}>{props.title}</h2>
                    }
                </div>
            </Container>
        </Col>

    )
}

export default OrangeTitle