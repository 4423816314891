import React from 'react'
import { Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Button from '../../Button/Button';

const ArticleItem = props => {
    return (
        <div className="articleItem">
            <div className=' d-flex article-banner flex-column' >
                {props.bannerImage ? <img src={props.bannerImage} /> : null}
                <div className='date'>
                    <p className='text-uppercase m-0 font-weight-700'>{props.date}</p>
                </div>
            </div>
            <div className='article-description d-flex'>
                <Col className='d-flex flex-column justify-content-center align-items-center'>
                    <p className='description-title font-weight-800' dangerouslySetInnerHTML={{ __html: props.title }}></p>
                    <p className='description font-weight-600' dangerouslySetInnerHTML={{ __html: props.description }}></p>
                    <Button className="description-btn" text={props.buttonText} to={props.buttonLink} />
                </Col>
            </div>
        </div >
    );
}


export default ArticleItem;