import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Accordion, Container } from 'react-bootstrap';
import AccordionItem from '../AccordionItem/AccordionItem';

const AccordionGroup = props => {
    const infos = props.infos;
    const infoKeys = Object.keys(infos);
    let i = null;
    let displayInfo;
    let content = null;

    const max = parseInt(infoKeys.length / props.columns);
    const left = parseInt(infoKeys.length % props.columns);
    let column = 1;
    let max1, max2 = 0;

    if (left === 1) {
        max1 = max + 1;
        max2 = max1 + max;
    } else if (left === 2) {
        max1 = max + 1;
        max2 = max1 + max + 1;
    } else {
        max1 = max;
        max2 = 2 * max;
    }

    useEffect(() => {
        if (props.searchText) {
            window.scrollTo(0, document.getElementsByClassName("accordion")[0].offsetTop)
        }
    });

    const getAccordion = (i) => {
        return (
            <Accordion key={i} className={i}>
                <AccordionItem {...props}
                    key={i}
                    title={infos[i]['title']}
                    text={infos[i]['text']}
                    number={i}
                />
            </Accordion>
        )
    }
    displayInfo = infoKeys.map(key => {
        if (parseInt(key) === 1 || parseInt(key) === max1 + 1 || parseInt(key) === max2 + 1) {
            content = [];
            i = parseInt(key);
            let total = column === 2 ? max2 : max1;
            while (i <= total) {
                if (props.searchText && props.searchText !== "") {
                    if (infos[i].text.toLowerCase().includes(props.searchText.toLowerCase()) || infos[i].title.toLowerCase().includes(props.searchText.toLowerCase())) {
                        let accordion = getAccordion(i);
                        content.push(
                            accordion
                        )
                    }
                } else if (!props.searchText) {
                    let accordion = getAccordion(i);
                    content.push(
                        accordion
                    )
                }
                i += 1;
            }
            column += 1;
            return (
                <div className="col p-0" key={key}>
                    {content.length >= 1 ?
                        content :
                        <p className='font-weight-700 text-blue ms-4 mb-0'>{props.noResult}</p>
                    }
                </div>
            )
        }
    })
    return (
        <Container fluid="true" className={"accordion padding-39tb bg-" + props.bg}>
            <Container className='p-0'>
                {
                    props.columns !== 1 ?
                        <div className="row gx-0 row-cols-1 row-cols-md-2 py-0">
                            {displayInfo}
                        </div>
                        :
                        <div className="row gx-0 row-cols-1 py-0 ">
                            {displayInfo}
                        </div>
                }
            </Container>
        </Container>
    )
}
const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}
export default connect(mapStateToProps)(AccordionGroup)
