import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from '../../../shared/functions';
import Separator from '../../../components/UI/Separator/Separator';
import JobDetails from '../../../components/UI/JobOffers/JobDetails/JobDetails';
import Head from '../../../components/Partials/Head/Head';
import ThankYouPage from '../../../components/ThankYouPage/ThankYouPage';

class Job extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: false,
        }
    }

    componentDidMount() {
        //get offer id
        const id = this.props.location.pathname ? this.props.location.pathname.split('/')[2] : false;
        this.setState({
            ...this.state,
            id: id,
        })
    }

    render() {
        if (window.location.pathname.split('/')[2] === this.props.t("routes:thank_you.id")) {
            return (
                <>
                    <Head lang={this.props.i18n.language} {...this.props} id="thank-you" />
                    <ThankYouPage {...this.props} title={this.props.t("thankYou:offer.title")} text={this.props.t("thankYou:offer.text")} socials1={this.props.t("thankYou:offer.socials-1")} />
                </>
            )
        } else {
            return (
                <>
                    <Head lang={this.props.i18n.language} {...this.props} id="jobs" />
                    <JobDetails
                        {...this.props}
                        jobID={this.state.id}
                    />
                    <Separator
                        bgLeftColor="orange"
                        bgRightColor="grey-medium"
                        leftSide="white"
                        rightSide="white"
                    />
                </>
            )
        }
    }
}



export default withRouter(withTranslation()(Job))