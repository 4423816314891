import React, { Suspense } from 'react';

const spinner = (props) => {
    return (
        <Suspense>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </Suspense>
    )
}

export default spinner;