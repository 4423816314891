import React from 'react'
import { Col } from 'react-bootstrap'
import TeamItemBottom from './TeamItemBottom'
import TeamItemTop from './TeamItemTop'

const TeamItem = (props) => {
    return (
        <Col className="team-item d-flex flex-column align-items-center">
            <TeamItemTop
                position={props.position}
                email={props.email}
                img={props.img}
                name={props.name}
            />
            <TeamItemBottom
                name={props.name}
                description={props.description}
            />
        </Col>
    )
}

export default TeamItem