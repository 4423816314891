import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { instanceWordpress } from '../../axios';


export function* tryFetchRecentPostsSaga(action) {
    /*     try {
            const response = yield instanceWordpress.get('wp-json/wp/v2/posts?_embed&_fields=_links.wp:featuredmedia,_embedded, id, date, slug, title,excerpt&per_page=3');
            if (!response.status === 200) {
                yield put(actions.failFetchRecentPosts());
            }
    
            const recentPosts = response.data;
    
            let filteredPosts = recentPosts.filter((recentPost) => {
                if (!recentPost.excerpt["protected"]) {
                    return recentPost
                } else return false
            })
            yield sessionStorage.setItem('recentPosts', JSON.stringify(filteredPosts));
            yield put(actions.successFetchRecentPosts(filteredPosts));
        } catch (error) {
            yield put(actions.failFetchRecentPosts());
        } */
}