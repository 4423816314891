import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Separator from '../../components/UI/Separator/Separator';
import PageNotFound from '../../components/UI/PageNotFound/PageNotFound';

class Page404 extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="page404" />
                <Separator bgLeftColor="orange-medium" bgRightColor="white" leftSide="orange-medium" rightSide="orange-medium" />
                <PageNotFound {...this.props} />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Page404)