import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import FormBase from "../FormBase/FormBase";
import FormInput from '../FormInput/FormInput';

const ContactForm = (props) => {
    const refFieldMessage = useRef();

    return (
        <>
            <FormBase
                type="Contact">
                <FormInput
                    name="name"
                    type="text"
                    title={props.t("partials:forms.name.title")}
                    placeHolder={props.t("partials:forms.name.placeHolder")}
                    minLength={3}
                    requiredMessage={props.t("partials:forms.name.requiredMessage")}
                    errorMessage={props.t("partials:forms.name.errorMessage")}
                    required
                />
                <FormInput
                    type="email"
                    name="email"
                    title={props.t("partials:forms.email.title")}
                    placeHolder={props.t("partials:forms.email.placeHolder")}
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    required
                    requiredMessage={props.t("partials:forms.email.requiredMessage")}
                    errorMessage={props.t("partials:forms.email.errorMessage")}
                />
                <FormInput
                    type="text"
                    name="phone"
                    minLength={9}
                    title={props.t("partials:forms.phone.title")}
                    placeHolder={props.t("partials:forms.phone.placeHolder")}
                    pattern="^[+\d](?:.*\d)?$"
                    errorMessage={props.t("partials:forms.phone.errorMessage")}
                />
                <FormInput
                    _ref={refFieldMessage}
                    type="textarea"
                    name="message"
                    title={props.t("partials:forms.message.title")}
                    placeHolder={props.t("partials:forms.message.placeHolder")}
                    required
                    requiredMessage={props.t("partials:forms.message.requiredMessage")}
                    errorMessage={props.t("partials:forms.message.errorMessage")}
                    minLength={10}
                />
                <FormInput
                    id='terms'
                    name="terms"
                    type="checkbox"
                    link={props.t('routes:privacy_policy.path')}
                    title={props.t("partials:forms.check.text")}
                />
            </FormBase>
        </>
    )
}

export default withTranslation()(ContactForm)
