import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Auxiliary from '../../../hoc/Auxiliary';
import LanguageChanger from '../LanguageChanger/LanguageChanger';
import logo from '../../../assets/images/logos/logo_header.svg';
//import Search from '../Search/Search';
import Droplink from '../Menu/DropLink';
import { currentRouteKey } from '../../../shared/functions';
import { useLocation } from 'react-router-dom'


const Header = props => {

    const [showMenu, setShowMenu] = useState(false)
    const [showSubmenu, setShowSubmenu] = useState()
    const [languageMenu, setLanguageOpen] = useState()
    const [currentKey, setCurrentKey] = useState()
    const location = useLocation();

    useEffect(() => {
        let [key, id] = currentRouteKey(props.i18n)
        setCurrentKey(key)
    }, [location])

    useEffect(() => {
        if (languageMenu) {
            setShowMenu(false)
        }
    })

    /* function to toggle menu */
    function toggleMenu() {
        setShowMenu(!showMenu)
        setLanguageOpen(false)
    }
    /* function to toggle submenu inside the menu */
    function toggleSubmenu(id) {
        if (showSubmenu === id) {
            setShowSubmenu("")
        } else {
            setShowSubmenu(id)
        }
    }

    /* get data from language component */
    const variableData = (data) => {
        setLanguageOpen(data)
    }

    return (
        <Auxiliary>
            <header className={showMenu || languageMenu ? "dark-bg" : ""}>
                <Container fluid className='p-0'>
                    <nav className="d-flex justify-content-between align-items-center px-3 px-md-5">
                        <div className="col-5 col-lg-2 me-auto pl-lg-0">
                            <a className="navbar-brand" href={props.t('routes:homepage.path')}>
                                <img src={logo} data-at2x={logo} className="default-logo" alt="logo" />
                            </a>
                        </div>
                        {/* menu mobile */}
                        {/* <div className='col-auto pe-0 d-lg-none'>
                            <Search />
                        </div> */}

                        <div className='col-auto pe-0 d-lg-none'>
                            {currentKey !== 'blog' && currentKey !== 'post' ?
                                <LanguageChanger {...props}
                                    setLanguageOpen={variableData}
                                    languageMenu={languageMenu}
                                    toggleMenu={toggleMenu}
                                    onChangeLanguage={(lng) => props.onChangeLanguage(lng)}
                                    currentLang={props.i18n.language}
                                /> : null}
                        </div>
                        <div className="col-auto menu-order px-lg-0 d-lg-none">
                            <button onClick={() => { toggleMenu() }} className={showMenu ? "navbar-toggler close float-end" : "navbar-toggler float-end"} type="button" aria-label="Toggle navigation" >
                                <span className="navbar-toggler-line"></span>
                                <span className="navbar-toggler-line"></span>
                                <span className="navbar-toggler-line"></span>
                                <span className="navbar-toggler-line"></span>
                            </button>
                            <div className={showMenu ? "navbar-collapse justify-content-center" : "navbar-collapse justify-content-center collapse"} id="navbarNav">
                                <ul className="navbar-nav alt-font">
                                    <li className="nav-item ">
                                        <NavLink onClick={(e) => { toggleMenu() }} to={props.t('routes:mission_values.path')} className="nav-link">
                                            {props.t('partials:menu.item.1')}
                                        </NavLink>
                                    </li>
                                    <li className={showSubmenu === "company" ? "nav-item dropdown active" : "nav-item dropdown"}>
                                        <NavLink id="company" to="#" className="submenu" onClick={e => { toggleSubmenu(e.target.id) }}>
                                            {props.t('partials:menu.item.2')}
                                            <i className="fa fa-angle-down dropdown-toggle"></i>
                                        </NavLink>
                                        <ul className={showSubmenu === "company" ? "dropdown-menu show" : "dropdown-menu"}>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:recruitment_selection.path')} className="nav-link">
                                                    {props.t('partials:menu.company.recruitment_selection')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:executive_search.path')} className="nav-link">
                                                    {props.t('partials:menu.company.executive_search')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:outsourcing.path')} className="nav-link">
                                                    {props.t('partials:menu.company.outsourcing')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:nearshoring.path')} className="nav-link">
                                                    {props.t('partials:menu.company.nearshoring')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:industries.path')} className="nav-link">
                                                    {props.t('partials:menu.company.industries')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:embedded_recruitment.path')} className="nav-link">
                                                    {props.t('partials:menu.company.embedded_recruitment')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={showSubmenu === "job" ? "nav-item dropdown active" : "nav-item dropdown"}>
                                        <NavLink id="job" to="#" className="submenu" onClick={e => { toggleSubmenu(e.target.id) }}>
                                            {props.t('partials:menu.item.3')}
                                            <i className="fa fa-angle-down dropdown-toggle"></i>
                                        </NavLink>
                                        <ul className={showSubmenu === "job" ? "dropdown-menu show" : "dropdown-menu"}>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:recruitment_process.path')} className="nav-link">
                                                    {props.t('partials:menu.jobs.recruitment_process')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:jobs.path')} className="nav-link">
                                                    {props.t('partials:menu.jobs.jobs')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a href='https://xchangejob.com/' target="_blank" rel="noreferrer" className="nav-link">
                                                    {props.t('partials:menu.jobs.xchangejob')}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={showSubmenu === "faq" ? "nav-item dropdown active" : "nav-item dropdown"}>
                                        <NavLink id="faq" to="#" className="submenu" onClick={e => { toggleSubmenu(e.target.id) }}>
                                            {props.t('partials:menu.item.4')}
                                            <i className="fa fa-angle-down dropdown-toggle"></i>
                                        </NavLink>
                                        <ul className={showSubmenu === "faq" ? "dropdown-menu show" : "dropdown-menu"}>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:FAQ_company.path')} className="nav-link">
                                                    {props.t('partials:menu.faqs.FAQ_company')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:FAQ_candidate.path')} className="nav-link">
                                                    {props.t('partials:menu.faqs.FAQ_candidate')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item ">
                                        <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:contacts.path')} className="nav-link">{props.t('partials:menu.item.5')}</NavLink>
                                    </li>
                                    {props.i18n.language === 'pt' ? <li className="nav-item">
                                        <NavLink onClick={() => { toggleMenu() }} to={props.t('routes:blog.path')} className="nav-link">{props.t('partials:menu.item.6')}</NavLink>
                                    </li> : null
                                    }
                                </ul>
                            </div>
                        </div>
                        {/* menu desktop */}
                        <div className="col-auto d-none d-lg-block">
                            <ul className="list-unstyled d-flex mb-0">
                                <li >
                                    <NavLink to={props.t('routes:mission_values.path')} className="nav-link">{props.t('partials:menu.item.1')}</NavLink>
                                </li>
                                <Droplink
                                    {...props}
                                    text={props.t('partials:menu.item.2')}
                                    id="company"
                                    list={props.i18n.getResource(props.i18n.language, 'partials')['menu']['company']}
                                />
                                <Droplink
                                    {...props}
                                    text={props.t('partials:menu.item.3')}
                                    id="employment"
                                    list={props.i18n.getResource(props.i18n.language, 'partials')['menu']['jobs']}
                                />
                                <Droplink
                                    {...props}
                                    text={props.t('partials:menu.item.4')}
                                    id="faqs"
                                    list={props.i18n.getResource(props.i18n.language, 'partials')['menu']['faqs']}
                                />
                                <li>
                                    <NavLink to={props.t('routes:contacts.path')} className="nav-link">{props.t('partials:menu.item.5')}</NavLink>
                                </li>
                                {props.i18n.language === 'pt' ? <li>
                                    <NavLink to={props.t('routes:blog.path')} className="nav-link">{props.t('partials:menu.item.6')}</NavLink>
                                </li> : null}
                                {/* get the key of the current path and check if is the blog */}
                                {currentKey !== 'blog' && currentKey !== 'post' ?
                                    <LanguageChanger {...props}
                                        onChangeLanguage={(lng) => props.onChangeLanguage(lng)}
                                        currentLang={props.i18n.language}
                                    /> : null}
                                {/* <Search /> */}
                            </ul>
                        </div>
                    </nav>
                </Container>
            </header>
        </Auxiliary >
    );
}
export default Header;