import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import ArticleItem from '../ArticleItem/ArticleItem';
import ArticleGroup from '../ArticleGroup/ArticleGroup';
import Pagination from '../Pagination/Pagination';
import Spinner from '../../UI/Spinner/Spinner';
import OrangeTitle from '../../UI/OrangeTitle/OrangeTitle';
import { Col } from 'react-bootstrap';
import { maxChar } from '../../../shared/functions';
import { Link, Outlet } from 'react-router-dom';


const Posts = (props) => {
    const [allPosts, setallPosts] = useState(null)
    const [filteredPosts, setFilteredPosts] = useState(null)
    const [displayPostsList, setDisplayPostsList] = useState(null)

    const [isLoading, setisLoading] = useState(true)
    const [error, seterror] = useState(null)

    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get("page") ? parseInt(queryParams.get("page")) : 1;
    const [currentPage, setcurrentPage] = useState(page)
    const [postsPerPage, setpostsPerPage] = useState(16)
    const [indexOfLastPost, setindexOfLastPost] = useState(1)
    const [indexOfFirtsPost, setindexOfFirtsPost] = useState(1)
    const [allUrls, setAllUrls] = useState(null)

    //postsFetched: false,
    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
        //check if posts are already in localStorage
        if (!allPosts && JSON.parse(sessionStorage.getItem('posts')) !== null) {
            setallPosts(JSON.parse(sessionStorage.getItem('posts')))
            seterror(false)
            setisLoading(false)
            return;
        }
    }, [])

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
        //check if posts are already in localStorage
        if (!allPosts && JSON.parse(sessionStorage.getItem('posts')) !== null) {
            setallPosts(JSON.parse(sessionStorage.getItem('posts')))
            seterror(false)
            setisLoading(false)
            return;
        }

        if (props.posts && props.posts.length > 0 && !props.error) {
            setallPosts(props.posts)
            setisLoading(false)
            seterror(false)
            return;
        }
    }, [props.posts])

    useEffect(() => {
        if (allPosts && allPosts.length > 0) {
            //save all URLS
            let urls = allPosts.map((post) => {
                return <Link to={props.t('routes:blog.path') + '/' + post.slug + '?id=' + post.id} className="d-none" />
            })
            setAllUrls(urls)


            let lastPost = currentPage * postsPerPage;
            let firstPost = lastPost - postsPerPage;
            let posts = allPosts.slice(firstPost, lastPost);
            if (filteredPosts) {
                if (filteredPosts.length > 0) {
                    posts = filteredPosts.slice(firstPost, lastPost);
                }
            }
            setPostsToDisplay(posts)
            setindexOfFirtsPost(firstPost)
            setindexOfLastPost(lastPost)
        }
    }, [allPosts])

    //Change page
    const paginate = (pageNumber) => {
        let lastPost = pageNumber * postsPerPage;
        let firstPost = lastPost - postsPerPage;
        let posts = allPosts.slice(firstPost, lastPost);
        if (filteredPosts) {
            if (filteredPosts.length > 0) {
                posts = filteredPosts.slice(firstPost, lastPost);
            }
        }

        setindexOfFirtsPost(firstPost);
        setindexOfLastPost(lastPost);
        setPostsToDisplay(posts);
        setcurrentPage(pageNumber);
        queryParams.set("page", pageNumber.toString())
        window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
        window.scrollTo(0, 0);
    }



    //define structure of posts displaying by iterating througth posts
    const setPostsToDisplay = (allPosts) => {
        if (allPosts && allPosts.length > 0) {
            let final = allPosts.map((post, index) => {
                const image = post._embedded['wp:featuredmedia']['0']['source_url'] === "undefined" ? null : post._embedded['wp:featuredmedia']['0']['source_url'];

                const dateObj = new Date(post.date);

                return (
                    <Col xs={12} md={6}>
                        <ArticleItem
                            key={index}
                            title={post.title.rendered}
                            date={(dateObj.getDate() + " " + props.t("partials:months." + dateObj.getMonth().toString()) + " " + dateObj.getFullYear())}
                            description={maxChar(post.excerpt.rendered, 100)}
                            buttonText={"Ver post"}
                            buttonLink={props.t('routes:blog.path') + '/' + post.slug + '?id=' + post.id}
                            bannerImage={image}
                            alt={post._embedded['wp:featuredmedia']['0'].alt_text}
                        />
                    </Col>
                )
            })
            setDisplayPostsList(final)
        } else return setDisplayPostsList([])
    }

    const searchPosts = (data) => {
        let lastPost = currentPage * postsPerPage;
        let firstPost = lastPost - postsPerPage;
        if (data !== "") {
            let final = allPosts.filter((item) => {
                if (allPosts) {
                    if (data !== "" && ((item.title.rendered.toLowerCase().includes(data.toLowerCase())) || item.title.rendered.toLowerCase().includes(data.toLowerCase().replace(/ /g, '')))) {
                        return allPosts
                    }
                } else return false
            })
            let posts = final.slice(firstPost, lastPost);
            setFilteredPosts(final)
            setPostsToDisplay(posts)
        } else {
            let posts = allPosts.slice(firstPost, lastPost);
            setPostsToDisplay(posts)
            setFilteredPosts(0)
        }
    };
    return (
        <>  {error ? (<OrangeTitle title={props.t("blog:section1.errorPosts")} />) : isLoading ? <div className='p-5 d-flex align-items-center justify-content-center'><Spinner /></div> :
            (
                <>
                    {/* make sure that all posts are crawled */}
                    {allUrls}
                    <Outlet />
                    <ArticleGroup {...props} data={searchPosts}>
                        {displayPostsList && displayPostsList.length > 0 ? displayPostsList : <h3>{props.t("blog:section2.search.noResults")} </h3>}
                    </ArticleGroup>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={filteredPosts ? filteredPosts.length : allPosts.length}
                        paginate={(n) => paginate(n)}
                        active={currentPage}
                    />
                </>
            )}
        </>
    );
}

const mapStateProps = state => {
    return ({
        posts: state.posts.posts,
        error: state.posts.error,
    });
}

export default connect(mapStateProps)(withTranslation()(Posts))


