import { React, useEffect, useState } from 'react';

import Auxiliary from '../../../hoc/Auxiliary';

import pt from '../../../assets/images/flags/portugal.png';
import en from '../../../assets/images/flags/usa.png';
import fr from '../../../assets/images/flags/france.png';


const LanguageChanger = props => {
    const [languageOpened, setLanguageOpened] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)


    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
    }, [])

    /* closes menu if language menu its open */
    if (props.showMenu && languageOpened) {
        props.setShowMenu(false)
    }
    useEffect(() => {
        if (!props.languageMenu) {
            setLanguageOpened(false)
        }
    }, [props.languageMenu])

    function OpenLanguageMenu() {
        setLanguageOpened(!languageOpened)
        props.setLanguageOpen(!languageOpened)
    }



    return (
        <Auxiliary>
            <div className="nav-link" onClick={windowWidth <= 1023 ? () => { OpenLanguageMenu() } : () => { }} onMouseEnter={windowWidth >= 1024 ? () => setLanguageOpened(true) : () => { }} onMouseLeave={windowWidth >= 1024 ? () => setLanguageOpened(false) : () => { }}>
                <i className="feather icon-feather-globe" ></i>
                <ul className={["menu list-unstyled languages", languageOpened ? "visible" : "invisible"].join(" ")}>
                    <li onClick={() => props.onChangeLanguage('pt')} className={["d-flex", props.currentLang === 'pt' ? 'active' : ""].join(" ")}>
                        <span className="icon-country"><img src={pt} alt="" data-no-retina="" /></span>{props.t('partials:menu.language.pt')}</li>
                    <li onClick={() => props.onChangeLanguage('en')} className={["d-flex", props.currentLang === 'en' ? 'active' : ""].join(" ")}>
                        <span className="icon-country"><img src={en} alt="" data-no-retina="" /></span>{props.t('partials:menu.language.en')}
                    </li>
                    <li onClick={() => props.onChangeLanguage('fr')} className={["d-flex", props.currentLang === 'fr' ? 'active' : ""].join(" ")}>
                        <span className="icon-country"><img src={fr} alt="" data-no-retina="" /></span>{props.t('partials:menu.language.fr')}
                    </li>
                </ul>
            </div>
        </Auxiliary >
    )
}

export default LanguageChanger;