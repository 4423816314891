import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Banner from "../../components/UI/Banner/Banner";
import Process from '../../components/UI/Process/Process';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import InfoBlocks from '../../components/UI/InfoBlocks/InfoBlocks';
import CallToAction from '../../components/CallToAction/CallToAction';
import HalfSeparator from '../../components/UI/HalfSeparator/HalfSeparator';
import Separator from '../../components/UI/Separator/Separator';
import imageBanner from '../../../src/assets/images/banners/recrutamento_e_selecao.jpg';
import SeparatorMobile from '../../components/UI/SeparatorMobile/SeparatorMobile';

class RecruitmentSelection extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="recruitment" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitment')['section1']['infos']}
                    img={imageBanner}
                />
                <OrangeTitle
                    squarePosition="left"
                    squareColor="grey-light"
                    title={this.props.t('recruitment:section2.title')}
                />
                <InfoBlocks infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitment')['section2']['infos']} />
                <Separator bgLeftColor="orange-light" bgRightColor="white" leftSide="orange-light" rightSide="orange-light" rightCube />
                <HalfSeparator bg="orange-light" pos="left" mobile />
                <Process
                    {...this.props}
                    //textLenght="half"
                    // bgLeftColor="grey"
                    title={this.props.t('recruitment:section3.title')}
                    description={this.props.t('recruitment:section3.description')}
                    descriptionColor="orange"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitment')['section3']['infos']}>
                </Process>
                <HalfSeparator bg="grey-light" pos="left" />
                <SeparatorMobile
                    leftColor="orange-light"
                    rightColor="blue"
                />
                <CallToAction
                    ctaType="grey"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitment')['section4']['infos']}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(RecruitmentSelection)