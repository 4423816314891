import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import IconTitleText from '../IconTitleText/IconTitleText'

const InfoIcons = (props) => {

    let content = props.content;
    let contentKeys = Object.keys(content);

    let displayInfo = contentKeys.map((key) => {
        let icon = props.iconsFolder ? props.iconsFolder + '/' + key + '.svg' : ""
        return (
            <Col md={6} lg={props.twoCols ? 6 : 4} key={key}>
                <IconTitleText
                    icon={icon}
                    title={content[key]["title"]}
                    text={content[key]["text"]}
                />
            </Col>

        )
    })

    return (
        <section className={props.cube ? "bg-blue InfoIcons cube" : "bg-blue InfoIcons"}>
            <Container className='padding-63-tb'>
                <Row className={props.centered ? "justify-content-center" : ""}>
                    {displayInfo}
                </Row>
            </Container>

        </section>
    )
}


export default InfoIcons