import i18n from 'i18next';
import languageDetector from "i18next-browser-languagedetector";
import languageDetectorOptions from "./languageDetectorOptions";
import { initReactI18next } from 'react-i18next';

//imports of translation files
import routesEN from './assets/locales/en/routes.json';
import routesPT from './assets/locales/pt/routes.json';
import routesFR from './assets/locales/fr/routes.json';
import partialsEN from './assets/locales/en/partials.json';
import partialsPT from './assets/locales/pt/partials.json';
import partialsFR from './assets/locales/fr/partials.json';
import metatagsEN from './assets/locales/en/metatags.json';
import metatagsPT from './assets/locales/pt/metatags.json';
import metatagsFR from './assets/locales/fr/metatags.json';
import homepageEN from './assets/locales/en/homepage.json';
import homepagePT from './assets/locales/pt/homepage.json';
import homepageFR from './assets/locales/fr/homepage.json';
import recruitmentEN from './assets/locales/en/recruitment.json';
import recruitmentPT from './assets/locales/pt/recruitment.json';
import recruitmentFR from './assets/locales/fr/recruitment.json';
import outsourcingEN from './assets/locales/en/outsourcing.json';
import outsourcingPT from './assets/locales/pt/outsourcing.json';
import outsourcingFR from './assets/locales/fr/outsourcing.json';
import nearshoringEN from './assets/locales/en/nearshoring.json';
import nearshoringPT from './assets/locales/pt/nearshoring.json';
import nearshoringFR from './assets/locales/fr/nearshoring.json';
import faqCompanyEN from './assets/locales/en/faq-company.json';
import faqCompanyPT from './assets/locales/pt/faq-company.json';
import faqCompanyFR from './assets/locales/fr/faq-company.json';
import faqCandidateEN from './assets/locales/en/faq-candidate.json';
import faqCandidatePT from './assets/locales/pt/faq-candidate.json';
import faqCandidateFR from './assets/locales/fr/faq-candidate.json';
import industriesEN from './assets/locales/en/industries.json';
import industriesPT from './assets/locales/pt/industries.json';
import industriesFR from './assets/locales/fr/industries.json';
import recruitmentProcessEN from './assets/locales/en/recruitment-process.json';
import recruitmentProcessPT from './assets/locales/pt/recruitment-process.json';
import recruitmentProcessFR from './assets/locales/fr/recruitment-process.json';
import executiveSearchEN from './assets/locales/en/executive-search.json';
import executiveSearchPT from './assets/locales/pt/executive-search.json';
import executiveSearchFR from './assets/locales/fr/executive-search.json';
import missionValuesEN from './assets/locales/en/mission-values.json';
import missionValuesPT from './assets/locales/pt/mission-values.json';
import missionValuesFR from './assets/locales/fr/mission-values.json';
import jobsEN from './assets/locales/en/jobs.json';
import jobsPT from './assets/locales/pt/jobs.json';
import jobsFR from './assets/locales/fr/jobs.json';
import blogEN from './assets/locales/en/blog.json';
import blogPT from './assets/locales/pt/blog.json';
import blogFR from './assets/locales/fr/blog.json';
import contactsEN from './assets/locales/en/contacts.json';
import contactsPT from './assets/locales/pt/contacts.json';
import contactsFR from './assets/locales/fr/contacts.json';
import modalsEN from './assets/locales/en/modals.json';
import modalsPT from './assets/locales/pt/modals.json';
import modalsFR from './assets/locales/fr/modals.json';
import page404EN from './assets/locales/en/page404.json';
import page404PT from './assets/locales/pt/page404.json';
import page404FR from './assets/locales/fr/page404.json';
import cookiesPolicyPT from './assets/locales/pt/cookies-policy.json';
import cookiesPolicyEN from './assets/locales/en/cookies-policy.json';
import cookiesPolicyFR from './assets/locales/fr/cookies-policy.json';
import privacyPolicyEN from './assets/locales/en/privacy-policy.json';
import privacyPolicyPT from './assets/locales/pt/privacy-policy.json';
import privacyPolicyFR from './assets/locales/fr/privacy-policy.json';
import imsPolicyEN from './assets/locales/en/ims-policy.json';
import imsPolicyPT from './assets/locales/pt/ims-policy.json';
import imsPolicyFR from './assets/locales/fr/ims-policy.json';
import projectSheetEN from './assets/locales/en/project-sheet.json';
import projectSheetPT from './assets/locales/pt/project-sheet.json';
import projectSheetFR from './assets/locales/fr/project-sheet.json';
import personalDataEN from './assets/locales/en/personal-data.json';
import personalDataPT from './assets/locales/pt/personal-data.json';
import personalDataFR from './assets/locales/fr/personal-data.json';
import thankYouEN from './assets/locales/en/thankYou.json';
import thankYouPT from './assets/locales/pt/thankYou.json';
import thankYouFR from './assets/locales/fr/thankYou.json';
import embeddedRecruitmentEN from './assets/locales/en/embedded-recruitment.json';
import embeddedRecruitmentPT from './assets/locales/pt/embedded-recruitment.json';
import embeddedRecruitmentFR from './assets/locales/fr/embedded-recruitment.json';
//associate the translations with locales
const resources = {
    en: {
        routes: routesEN,
        metatags: metatagsEN,
        homepage: homepageEN,
        partials: partialsEN,
        recruitment: recruitmentEN,
        outsourcing: outsourcingEN,
        nearshoring: nearshoringEN,
        faqCompany: faqCompanyEN,
        faqCandidate: faqCandidateEN,
        industries: industriesEN,
        recruitmentProcess: recruitmentProcessEN,
        executiveSearch: executiveSearchEN,
        missionValues: missionValuesEN,
        jobs: jobsEN,
        blog: blogEN,
        contacts: contactsEN,
        modals: modalsEN,
        page404: page404EN,
        cookiesPolicy: cookiesPolicyEN,
        privacyPolicy: privacyPolicyEN,
        imsPolicy: imsPolicyEN,
        projectSheet: projectSheetEN,
        personalData: personalDataEN,
        thankYou: thankYouEN,
        embeddedRecruitment: embeddedRecruitmentEN,
    },
    pt: {
        routes: routesPT,
        metatags: metatagsPT,
        homepage: homepagePT,
        partials: partialsPT,
        recruitment: recruitmentPT,
        outsourcing: outsourcingPT,
        nearshoring: nearshoringPT,
        faqCompany: faqCompanyPT,
        faqCandidate: faqCandidatePT,
        industries: industriesPT,
        recruitmentProcess: recruitmentProcessPT,
        executiveSearch: executiveSearchPT,
        missionValues: missionValuesPT,
        jobs: jobsPT,
        blog: blogPT,
        contacts: contactsPT,
        modals: modalsPT,
        page404: page404PT,
        cookiesPolicy: cookiesPolicyPT,
        privacyPolicy: privacyPolicyPT,
        imsPolicy: imsPolicyPT,
        projectSheet: projectSheetPT,
        personalData: personalDataPT,
        thankYou: thankYouPT,
        embeddedRecruitment: embeddedRecruitmentPT,
    },
    fr: {
        routes: routesFR,
        metatags: metatagsFR,
        homepage: homepageFR,
        partials: partialsFR,
        recruitment: recruitmentFR,
        outsourcing: outsourcingFR,
        nearshoring: nearshoringFR,
        faqCompany: faqCompanyFR,
        faqCandidate: faqCandidateFR,
        industries: industriesFR,
        recruitmentProcess: recruitmentProcessFR,
        executiveSearch: executiveSearchFR,
        missionValues: missionValuesFR,
        jobs: jobsFR,
        blog: blogFR,
        contacts: contactsFR,
        modals: modalsFR,
        page404: page404FR,
        cookiesPolicy: cookiesPolicyFR,
        privacyPolicy: privacyPolicyFR,
        imsPolicy: imsPolicyFR,
        projectSheet: projectSheetFR,
        personalData: personalDataFR,
        thankYou: thankYouFR,
        embeddedRecruitment: embeddedRecruitmentFR,
    }
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(languageDetector)

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        detection: languageDetectorOptions,
        fallbackLng: ["pt", "en", "fr"],

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'] // don't convert to <1></1> if simple react elements
    });


export default i18n;