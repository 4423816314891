import React from 'react';
import { Trans } from 'react-i18next';
import { Modal, Col, Button } from "react-bootstrap";
import FormInput from '../../UI/FormInput/FormInput';
// import Button from "../../Button/Button";


const InfoModal = props => {

    const click = () => {
        props.btn.onClick();
        props.closed(false);
    }

    return (
        <>
            <Modal show={props.modalOpen} animation={false} onHide={() => props.closed(false)} id={props.id} className="InfoModal">
                <div className="btn-close" onClick={() => props.closed(false)}></div>
                <h4 className="text-orange">{props.title}</h4>
                <p>
                    <Trans
                        t={props.t}
                        i18nKey={props.textKey}
                        components={{
                            linkMail: <a href="mailto:recrutamento@humanprofiler.com"></a>,
                            phone: <a href="tel:+351213304403"></a>
                        }}
                    />
                </p>
                {props.checkbox &&
                    <>
                        <FormInput
                            id='terms'
                            name="terms"
                            type="checkbox"
                            link={props.t('routes:' + props.checkbox.link)}
                            title={props.checkbox.label}
                            onChange={props.checkbox.onChange}
                        />
                        {props.checked !== false &&
                            <Col className='d-flex justify-content-start'>
                                <Button onClick={click}>{props.btn.label}</Button>
                            </Col>
                        }
                    </>
                }
                {props.btnClose && <Button onClick={() => props.closed(false)}>{props.btnClose}</Button>}
            </Modal>

        </>)
}

export default InfoModal