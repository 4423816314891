import React from 'react'
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CheckboxItem from '../CheckboxItem/CheckboxItem';
import Auxiliary from '../../../hoc/Auxiliary';

const CheckboxGroup = props => {

    const infos = props.infos;
    const infoKeys = Object.keys(infos);
    let i = null;
    let displayInfo;
    let content = null;

    const getCheck = (i) => {
        return (
            <CheckboxItem
                key={i}
                number={i}
                text={infos[i]['text']}
            />
        )
    }

    displayInfo = infoKeys.map(key => {
        content = [];
        i = parseInt(key);
        let Check = getCheck(i);

        content.push(
            infos[i]['wrapLink'] ? (<Link to={props.t('routes:' + infos[i]['wrapLink'])} key={i}>{Check}</Link>) : Check
        )
        return (
            <Auxiliary key={key}>
                {content}
            </Auxiliary>

        )
    })


    return (
        <Container fluid className='CheckboxGroup p-0'>
            <Container className='content'>
                {displayInfo}
            </Container>
        </Container>
    );
}


export default CheckboxGroup;