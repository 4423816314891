import React, { useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormInput from '../FormInput/FormInput';
import OrangeTitle from '../OrangeTitle/OrangeTitle';
import FormBase from "../FormBase/FormBase";
import { withTranslation } from 'react-i18next';


const JobModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const refFieldMessage = useRef();
    return (
        <div className='JobModal'>
            <Button className={props.btn100w ? 'OpenButton text-uppercase w-100' : 'OpenButton text-uppercase'} onClick={handleShow} ref={props.btnRef}>
                {props.modalBtn}
            </Button>
            <Modal show={show} animation={false} onHide={handleClose} dialogClassName="jobModal-container">
                <div className="btn-close" onClick={handleClose}></div>
                <Modal.Header >
                    <OrangeTitle
                        title={props.titleModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <FormBase
                            type="Jobs"
                        >
                            {/* To distinguish between spontaneous ans not */}
                            <FormInput
                                type="text"
                                classes="d-none"
                                name="idOffer"
                                value={props.offer !== "undefined" ? props.offer : "Candidatura Espontânea"}
                            />
                            <FormInput
                                name="name"
                                type="text"
                                title={props.t("partials:forms.name.title")}
                                placeHolder={props.t("partials:forms.name.placeHolder")}
                                minLength={3}
                                requiredMessage={props.t("partials:forms.name.requiredMessage")}
                                errorMessage={props.t("partials:forms.name.errorMessage")}
                                required
                            />
                            <FormInput
                                type="email"
                                name="email"
                                title={props.t("partials:forms.email.title")}
                                placeHolder={props.t("partials:forms.email.placeHolder")}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                required
                                requiredMessage={props.t("partials:forms.email.requiredMessage")}
                                errorMessage={props.t("partials:forms.email.errorMessage")}
                            />
                            <FormInput
                                type="text"
                                name="phone"
                                minLength={9}
                                title={props.t("partials:forms.phone.title")}
                                placeHolder={props.t("partials:forms.phone.placeHolder")}
                                pattern="^[+\d](?:.*\d)?$"
                                errorMessage={props.t("partials:forms.phone.errorMessage")}
                            />
                            <FormInput
                                _ref={refFieldMessage}
                                type="textarea"
                                name="message"
                                title={props.t("partials:forms.message.title")}
                                placeHolder={props.t("partials:forms.message.placeHolder")}
                                required
                                requiredMessage={props.t("partials:forms.message.requiredMessage")}
                                errorMessage={props.t("partials:forms.message.errorMessage")}
                                minLength={10}
                            />
                            <FormInput
                                type="file"
                                name="cv"
                                required
                                requiredMessage={props.t("partials:forms.file.requiredMessage")}
                                errorMessage={props.t("partials:forms.file.errorMessage")}
                            />
                            <FormInput
                                id='terms'
                                name="terms"
                                type="checkbox"
                                link={props.t('routes:personal_data.path')}
                                title={props.t("partials:forms.checkJobs.text")}
                            />
                        </FormBase>
                    </Container>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default withTranslation()(JobModal)
