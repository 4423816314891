import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import Banner from "../../components/UI/Banner/Banner"
import CallToAction from '../../components/CallToAction/CallToAction';
import TextBlock from '../../components/UI/TextBlock/TextBlock';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import SeparatorWithText from '../../components/UI/SeparatorWithText/SeparatorWithText';
import Separator from '../../components/UI/Separator/Separator';
import ImageInfo from '../../components/ImageInfo/ImageInfo';
import imageBanner from '../../../src/assets/images/banners/executive_search.jpg';
import img1 from '../../assets/images/executive_search/executive.jpg';
import HalfSeparator from '../../components/UI/HalfSeparator/HalfSeparator';

class ExecutiveSearch extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="executive_search" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'executiveSearch')['section1']['infos']}
                    search={false}
                    img={imageBanner}
                />
                <OrangeTitle title={this.props.t('executiveSearch:section2.title')}
                    squareColor="white"
                    squarePosition="left"
                />
                <TextBlock bgColorLeft="white"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'executiveSearch')['section2']['infos']}
                />
                <SeparatorWithText text="executive"
                    textSide="left" spaceEnd={false}
                    bgStart="grey"
                    bgEnd="grey"
                    autoCol
                />
                <Separator bgLeftColor="white" bgRightColor="white" leftSide="grey-medium" rightSide="grey-medium" />
                <ImageInfo
                    imagePos="right"
                    bgColor="grey-light"
                    orangeSquare
                    orangeBefore
                    title={this.props.t('executiveSearch:section3.1.title')}
                    text={this.props.t('executiveSearch:section3.1.text')}
                    btn={this.props.t('executiveSearch:section3.1.btn')}
                    img={img1}
                    to={this.props.t('routes:contacts.path')}
                />
                <CallToAction
                    ctaType="grey"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'executiveSearch')['section4']['infos']}
                />
                <Separator bgLeftColor="grey-medium" bgRightColor="white" leftSide="grey-light" rightSide="white" />
                <HalfSeparator
                    bg="orange-light"
                    pos="left"
                    mobile
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(ExecutiveSearch)
