import React, { useEffect, useState } from 'react';
import { Pagination as P } from 'react-bootstrap';

const Pagination = (props) => {

    let active = props.active ? props.active : 1;
    let items = [];
    let maxPages = Math.ceil(props.totalPosts / props.postsPerPage)
    let nextP = (active + 1) <= maxPages ? (active + 1) : maxPages;
    let prevP = (active - 1) > 1 ? (active - 1) : 1;

    const [nextPage, setNextPage] = useState(nextP)
    const [previousPage, setPreviousPage] = useState(prevP)


    useEffect(() => {
        let np = (active + 1) <= maxPages ? (active + 1) : maxPages;
        let pp = (active - 1) > 1 ? (active - 1) : 1;
        setNextPage(np)
        setPreviousPage(pp)

    }, [active])

    for (let i = 1; i <= maxPages; i++) {
        items.push(
            <P.Item key={i} active={i === active} className="font-weight-800" onClick={() => props.paginate(i)}>
                {i}
            </P.Item>
        );
    }
    return (
        <P className="justify-content-center">
            <P.Prev className="font-weight-800 arrow" onClick={() => props.paginate(previousPage)} />
            {items}
            <P.Next className="font-weight-800 arrow" onClick={() => props.paginate(nextPage)} />
        </P>
    )
}
export default Pagination