import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Process from '../../components/UI/Process/Process';
import ImageInfo from '../../components/ImageInfo/ImageInfo';
import TitleInfo from '../../components/UI/TitleInfo/TitleInfo';
import Separator from '../../components/UI/Separator/Separator';
import CallToAction from '../../components/CallToAction/CallToAction';
import img1 from '../../assets/images/recruitment_process/processo_recrutamento.jpg';
import img2 from '../../assets/images/recruitment_process/processo_pc.jpg';
import SeparatorMobile from '../../components/UI/SeparatorMobile/SeparatorMobile';

class RecruitmentProcess extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="recruitment_process" />
                <TitleInfo
                    half={false} title={true} bgLeftColor="grey" bgRightColor="orange"
                    text={this.props.t('recruitmentProcess:section1.title')}
                />
                <TitleInfo
                    half={false} bgLeftColor="white" bgRightColor="grey"
                    text={this.props.t('recruitmentProcess:section1.subtitle')}
                />
                <ImageInfo
                    imagePos="left"
                    text={this.props.t('recruitmentProcess:section1.text')}
                    img={img1}
                    to=""
                />
                <Separator bgLeftColor="grey-light" bgRightColor="white" leftSide="grey-light" rightSide="grey-light" rightCube />
                <Process
                    {...this.props}
                    textLenght="full"
                    bgLeftColor="white"
                    title={this.props.t('recruitmentProcess:section2.title')}
                    description={this.props.t('recruitmentProcess:section2.description')}
                    descriptionColor="blue"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitmentProcess')['section2']['infos']}
                // img={img2}
                />
                <Separator bgLeftColor="white" bgRightColor="blue" leftSide="white" rightSide="blue" />
                {/* <Separator bgLeftColor="grey-light" bgRightColor="orange-medium" leftSide="grey-medium" rightSide="grey-medium" />
                <CallToAction ctaType="grey-light" to="#" textColor="blue"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitmentProcess')['cta1']['infos']} /> */}
                <Separator bgLeftColor="white" bgRightColor="white" leftSide="grey-medium" rightSide="grey-medium" />
                <SeparatorMobile leftColor="grey-medium" rightColor="orange" />
                <CallToAction noBtn textLeft
                    {...this.props}
                    ctaType="white-noBtn"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitmentProcess')['cta2']['infos']}
                />
                <CallToAction
                    ctaType="blue-full"
                    to={this.props.t('routes:jobs.path')}
                    bgLeftColor="white"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'recruitmentProcess')['cta3']['infos']}
                />
                <Separator bgLeftColor="grey-light" bgRightColor="white" leftSide="white" rightSide="grey-medium" />
            </Auxiliary>
        )
    }
}

export default withTranslation()(RecruitmentProcess)