import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Auxiliary from '../../../hoc/Auxiliary';

const Separator = (props) => {
    return (
        <Container fluid className={'d-none d-md-block separator bgLeftColor-' + props.bgLeftColor + ' bgRightColor-' + props.bgRightColor}>
            <Container >
                <Row>
                    {
                        props.leftCube ?
                            <Auxiliary>
                                <Col xs={1} className={"bg-white"} ></Col>
                                <Col xs={5} className={'left bgColor-' + props.leftSide}></Col>
                            </Auxiliary>
                            :
                            <Col xs={6} className={'left bgColor-' + props.leftSide}></Col>
                    }
                    {
                        props.rightCube ?
                            <Auxiliary>
                                <Col xs={5} className={'right bgColor-' + props.rightSide}></Col>
                                <Col xs={1} className={"bg-white"} ></Col>

                            </Auxiliary>
                            :
                            <Col xs={6} className={'right bgColor-' + props.rightSide}></Col>
                    }
                </Row>
            </Container>
        </Container>
    )
}

export default Separator
