import React, { useState } from 'react'
import { Container, Col, Row, Form } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Button from 'react-bootstrap/Button';

const Banner = props => {
    const infos = props.infos;
    const [searchValue, setSearchValue] = useState("");
    const search = e => {
        e.preventDefault();
        props.parentCallback(searchValue)
    };

    if (props.blog) {
        return (
            <Container fluid className='Banner blog'>
                <Container>
                    <Row>
                        <Col lg={1}></Col>
                        <Col xs={12} lg={10} className='bg-orange px-4 py-4 text-center'>
                            <h1 className='title text-uppercase m-0 font-weight-800'><Trans
                                t={props.t}
                                i18nKey={infos.title}
                                components={
                                    {
                                        br: <br />,
                                        titleBold: <span className="titleBold font-weight-900"></span>,
                                        bold: <span className="font-weight-700"></span>
                                    }
                                }
                            />
                            </h1>
                        </Col>
                        <Col lg={6} ></Col>
                    </Row>
                    <Row>
                        <Col lg={6} ></Col>
                        <Col lg={6} className="rectangle"></Col>
                    </Row>
                </Container>
            </Container>
        )
    } else {
        return (
            <Container fluid className='Banner' >
                <div className={props.bottom ? 'bg-image bottom' : 'bg-image'} style={{ backgroundImage: "url(" + props.img + ")" }}></div>
                <Container className={props.twoSquares ? 'content-container twoSquares leftSquareColor-' + props.leftSquareColor + ' rightSquareColor-' + props.rightSquareColor : 'content-container'}>
                    <Row>
                        <Col lg={1} xl={1}></Col>
                        <Col lg={5} className='bg-orange px-4 px-lg-4 py-3 py-lg-4 d-flex justify-content-center justify-content-lg-start'>
                            <h1 className='title text-uppercase m-0 font-weight-800 d-none d-lg-block'><Trans
                                t={props.t}
                                i18nKey={infos.title}
                                components={
                                    {
                                        br: <br />,
                                        titleBold: <span className="titleBold font-weight-900"></span>,
                                        bold: <span className="font-weight-700"></span>
                                    }
                                }
                            />
                            </h1>
                            <h1 className='title text-uppercase m-0 font-weight-800 d-lg-none'><Trans
                                t={props.t}
                                i18nKey={infos.title}
                                components={
                                    {
                                        br: <span></span>,
                                        titleBold: <span></span>,
                                        bold: <span className="font-weight-900"></span>
                                    }
                                }
                            />
                            </h1>
                        </Col>
                        <Col xl={6} ></Col>
                    </Row>
                    <Row>
                        <Col lg={6} xl={6} className="cube d-none d-lg-block"></Col>
                        {props.search ?
                            <Col lg={5} xl={5} className='bg-white p-0 search d-flex align-items-center justify-content-center'>
                                <Row className='w-100 search-content d-flex justify-content-center align-items-center'>
                                    <Col sm={12} className="d-flex align-items-center justify-content-center p-2">
                                        <Form onSubmit={(e) => search(e)} className="col-12 d-flex">
                                            <Col xs={7} md={10} lg={8} className="mb-lg-2 mb-xl-0">
                                                <Form.Group className='mb-2 mb-lg-0 h-100'>
                                                    <Form.Control
                                                        type="text"
                                                        name="search"
                                                        defaultValue={searchValue}
                                                        onChange={(e) => { setSearchValue(e.target.value) }}
                                                        placeholder={infos.searchPlaceholder}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='d-flex justify-content-around'>
                                                <Button type='submit' onMouseDown={e => { e.preventDefault() }} className="align-self-end align-self-lg-center">{props.btnSearchText}</Button>
                                            </Col>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            :
                            <Col lg={5} xl={5} className='bg-white px-4 py-4'>
                                <h2 className='DescriptionTitle font-weight-800 mb-0'>
                                    {infos.textTitle ?
                                        <Trans
                                            t={props.t}
                                            i18nKey={infos.textTitle}
                                            components={
                                                {
                                                    spanOrange: <span className='text-orange'></span >
                                                }
                                            }
                                        />
                                        :
                                        ""
                                    }
                                </h2>
                                <p className='Description m-0 font-weight-600'>
                                    {infos.text ?
                                        <Trans
                                            t={props.t}
                                            i18nKey={infos.text}
                                            components={
                                                {
                                                    spanOrange: <span className='text-orange'></span >,
                                                    spanOrangeBold: <span className='text-orange font-weight-700'></span >
                                                }
                                            }
                                        />
                                        :
                                        ""
                                    }

                                </p>
                            </Col>
                        }
                    </Row>
                </Container >
            </Container >

        )
    }
}

export default Banner;