import React, { useEffect, useState } from 'react';
import * as actions from '../../../../store/actions/index';
import { structuredDataJob } from '../../../../shared/structuredData';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { OfferContent } from '../OfferContent/OfferContent';
import OfferTitle from '../OfferTitle/OfferTitle';
import Spinner from '../../Spinner/Spinner';
import OrangeTitle from '../../OrangeTitle/OrangeTitle';
import JobsForm from '../../../UI/JobsForm/JobsForm';
import { Navigate } from "react-router-dom";
import ScrollToTop from '../../../../hoc/ScrollToTop';

const JobDetails = (props) => {

    const [error, seterror] = useState(false);
    const [allJobs, setallJobs] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [title, setTitle] = useState(false);
    const [description, setDescription] = useState(false);
    const [requirements, setRequirements] = useState(false);
    const [city, setCity] = useState(false);
    const [categoryPT, setCategoryPT] = useState(false);
    const [categoryEN, setCategoryEN] = useState(false);
    const [offerExists, setOfferExists] = useState(false);
    const jobID = props.jobID ? props.jobID : false;

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
        //check if jobs are already in localStorage
        if (!allJobs && JSON.parse(localStorage.getItem('jobs')) !== null) {
            setallJobs(JSON.parse(localStorage.getItem('jobs')))
            seterror(false)
            return;
        }

        if (props.jobs && props.jobs.length > 0 && !props.error) {
            setallJobs(props.jobs)
            seterror(false)
            return;
        }
    }, [props.jobs])

    useEffect(() => {
        setJobToShow()
    }, [allJobs])

    const setJobToShow = () => {
        if (allJobs !== null && jobID) {
            let entries = Object.keys(allJobs);
            let requirements = ""
            entries.map(key => {
                if (allJobs[key].job_requirements && allJobs[key].job_requirements.includes("Required Profile:")) {
                    requirements = allJobs[key].job_requirements.replace("Required Profile:\r\n", "")
                } else if (allJobs[key].job_requirements && allJobs[key].job_requirements.includes("Perfil requerido:")) {
                    requirements = allJobs[key].job_requirements.replace("Perfil requerido:\r\n", "")
                } else if (allJobs[key].job_requirements && allJobs[key].job_requirements.includes("Perfil Requerido:")) {
                    requirements = allJobs[key].job_requirements.replace("Perfil Requerido:\r\n", "")
                } else {
                    requirements = allJobs[key].job_requirements
                }
                if (allJobs[key].job_id === jobID) {
                    setTitle(allJobs[key].job_title)
                    setDescription(allJobs[key].job_description)
                    setRequirements(requirements)
                    setCity(allJobs[key].city_name)
                    setCategoryPT(allJobs[key].job_categoryPT)
                    setCategoryEN(allJobs[key].job_categoryEN)
                    setOfferExists(true)
                    //set structured Data
                    props.onSetStructuredData(structuredDataJob(allJobs[key]));
                }
            })
            setisLoading(false)

        }
    }

    return (
        <>
            {isLoading ? <div className='p-5 d-flex align-items-center justify-content-center'><Spinner /></div> :
                error ? <OfferTitle title={props.t('jobs:section3.errorOffers')} />
                    : offerExists ? <>
                        <ScrollToTop>
                            <OfferTitle title={title} />
                            <OfferContent
                                {...props}
                                id={jobID}
                                title={title}
                                category={props.i18n.language === "pt" ? categoryPT : categoryEN}
                                description={description}
                                requirements={requirements}
                                city={city}
                            />
                            <OrangeTitle
                                squareColor="orange"
                                title={props.t('jobs:section3.titleModal')}
                                justifyStart
                            />
                            <Container className='Job-Form'>
                                <JobsForm offer={jobID} />
                            </Container>
                        </ScrollToTop>
                    </> : <Navigate replace={true} to="/not-found" />
            }
        </>
    )
}

const mapStateProps = state => {
    return ({
        jobs: state.jobs.jobs,
        error: state.jobs.error,
    });

}

const mapDispatchProps = dispatch => {
    return {
        onSetStructuredData: (data) => dispatch(actions.setJobStructuredData(data)),
    }
}

export default connect(mapStateProps, mapDispatchProps)(withTranslation()(JobDetails));