import React from 'react';

const HalfSeparator = (props) => {
    let classes = props.mobile ? "d-md-none d-flex" : "d-none d-md-flex"
    return (
        props.pos === "right" ?
            (<div className={classes}>
                <div className="bg-white padding-0-lr h-63px w-50"></div>
                <div className={"bg-" + props.bg + " padding-0-lr h-63px w-50"}></div>
            </div>) :
            (<div className={classes}>
                <div className={"bg-" + props.bg + " padding-0-lr h-63px w-50"}></div>
                <div className="bg-white padding-0-lr h-63px w-50"></div>
            </div>)
    )
}

export default HalfSeparator
