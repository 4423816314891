import React from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import img from '../../../assets/images/pageNotFound/ilustracao-404.svg'
import Button from '../../Button/Button';

const PageNotFound = props => {
    return (
        <Container className='PageNotFound p-0' fluid>
            <Container className='content'>
                <Row>
                    <Col className='d-flex justify-content-center align-items-center p-0'>
                        <Image src={img} fluid="true" />
                    </Col>
                    <Col className='d-flex justify-content-start align-items-center ps-5'>
                        <div>
                            <p className='font-weight-800'>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.t('page404:infos.text')}
                                    components={
                                        {
                                            BiggerText: <span className='biggerText text-uppercase'></span >
                                        }
                                    }
                                />
                            </p>
                            <Button to={props.t('routes:homepage.path')} text={props.t('page404:infos.btnText')}></Button>
                        </div>
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}

export default PageNotFound