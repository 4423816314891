import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Auxiliary from '../../../hoc/Auxiliary';
import ProcessStep from '../ProcessStep/ProcessStep';
import Separator from '../Separator/Separator';


const Process = props => {

    const infos = props.infos;
    const infoKeys = Object.keys(infos);
    let i = null;
    let displayInfo;
    let content = null;

    const getStep = (i) => {
        return (
            <ProcessStep {...props}
                key={i}
                number={i}
                title={infos[i]['title']}
                text={infos[i]['text']}
                end={infos[i]['end']}
            />
        )
    }

    displayInfo = infoKeys.map(key => {
        content = [];
        i = parseInt(key);
        let Step = getStep(i);

        content.push(
            infos[i]['wrapLink'] ? (<Link to={props.t('routes:' + infos[i]['wrapLink'])} key={i}>{Step}</Link>) : Step
        )

        return (
            <div className=" p-0" key={key}>
                {content}
            </div>
        )
    })

    return (
        <Auxiliary>
            <Container fluid className='process-title' >
                <Row className='title-bar'>
                    <Col xl="auto" className='d-flex justify-content-center'>
                        <span className='font-weight-900 text-uppercase title'>{props.title}</span>
                    </Col>
                </Row>
            </Container>
            <Separator bgLeftColor="white" bgRightColor="orange-medium" leftSide="white" rightSide="white" />
            <Container className={'process-Container bg-' + props.bgLeftColor}>
                <Container className='content'>
                    <Row className='pt-0 pb-4'>
                        <Col xl={props.textLenght === "half" ? 6 : 12}>
                            <p className={'description font-weight-800 color-' + props.descriptionColor}>{props.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {displayInfo}
                        </Col>
                        {/* <Col xl className='d-flex flex-column justify-content-center align-items-center'>
                            <Image src={props.img} fluid />
                        </Col> */}
                    </Row>
                </Container>
            </Container>

        </Auxiliary>

    );
}


export default Process;