import React from 'react';
import { Col, Row } from 'react-bootstrap';

const SeparatorMobile = (props) => {

    return (
        <Row className='d-flex d-md-none h-63px w-100 m-0'>
            <Col className={"bg-" + props.leftColor}></Col>
            <div className={"bg-" + props.rightColor + " w-80px p-0"}></div>
        </Row>
    )
}

export default SeparatorMobile
