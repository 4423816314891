import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';


const ProcessStep = props => {

    return (
        <Container className='ProgressStep p-0'>
            <Row className='gx-0'>
                <Col xs="auto" className="d-flex flex-column align-items-center">
                    <Row className='gx-0'>
                        <p className='number text-center font-weight-700'>{props.number}</p>
                    </Row>
                    {props.end ? "" : <Row className='line gx-0'></Row>}

                </Col>
                <Col xs className='px-4'>
                    <Row className='gx-0 '>
                        <div className='title d-flex align-items-center justify-content-start'>
                            <h2 className='m-0 text-uppercase font-weight-800'>
                                <Trans
                                    t={props.t}
                                    i18nKey={props.title}
                                    components={
                                        {
                                            LinkJobs: <Link to={props.t('routes:jobs.path')} className='link'></Link>,
                                            LinkBlog: <Link to={props.t('routes:blog.path')} className='link'></Link>,
                                            LinkFAQCandidato: <Link to={props.t('routes:FAQ_candidate.path')} className='link'></Link>,
                                            LinkApplication: <Link to={props.t('routes:jobs.path')} className='link'></Link>,
                                        }
                                    }
                                />
                            </h2>
                        </div>
                    </Row>
                    <Row className='gx-0'>
                        <p className='text font-weight-600'>
                            <Trans
                                t={props.t}
                                i18nKey={props.text}
                                components={
                                    {
                                        LinkJobs: <Link to={props.t('routes:jobs.path')} className='link'></Link>,
                                        LinkBlog: <Link to={props.t('routes:blog.path')} className='link'></Link>,
                                        LinkFAQCandidato: <Link to={props.t('routes:FAQ_candidate.path')} className='link'></Link>,
                                        LinkApplication: <Link to={props.t('routes:jobs.path') + "#spontaneous"} className='link'></Link>,
                                    }
                                }
                            />
                        </p>
                    </Row>
                </Col>
            </Row>
        </Container>

    );
}


export default ProcessStep;