import React, { useState, useRef, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { maxChar } from '../../../shared/functions';
import Spinner from '../Spinner/Spinner';

const FormInput = (props) => {

    const { id, _ref, title, type, required, name, minLength, maxLength, onChange, onKeyUp, pattern, placeholder, errorMessage, requiredMessage, accept, classes, link, checked } = props;
    const valueInput = props.value;
    const [error, setError] = useState(false);
    const [typeError, setTypeError] = useState(null);
    const [loadingFile, setLoadingFile] = useState(false);
    const refInputCv = useRef();
    const [fileLabel, setFileLabel] = useState(props.t("partials:forms.file.btnText"))
    const [value, setValue] = useState(valueInput !== "undefined" ? valueInput : "")
    const clearInput = props.clearInput

    useEffect(() => {
        if (clearInput) {
            if (type === "file") {
                refInputCv.current.value = null
                setFileLabel(props.t("partials:forms.file.btnText"))
            }
            else if (type === "checkbox")
                onChange(false);

            else if (name !== "idOffer")
                setValue("")
        }

    }, [clearInput])



    const checkFieldValidity = (e) => {
        setValue(e.target.value);
        if (e.target.checkValidity()) {
            setError(false);
        } else {
            const type = e.target.value === '' ? 'required' : 'error';
            setError(true);
            setTypeError(type);
        }
    }

    const checkCv = () => {
        //without uploaded file
        if (!refInputCv.current.files[0]) {
            setTypeError('required');
            refInputCv.current.value = null;
            setError(true);
            return;
        }
        //tests file validity
        setLoadingFile(true);
        if (!/vnd.openxmlformats-officedocument.wordprocessingml.document|pdf|png|jpg|jpeg/.test(refInputCv.current.files[0].type) || refInputCv.current.files[0].size > 5242880) {
            refInputCv.current.value = "";
            setError(true);
            setTypeError('format');
        } else {
            setFileLabel(maxChar(refInputCv.current.files[0].name, 15));
            setError(false);
        }
        setLoadingFile(false);
    }

    //build input
    const input = type ?
        (type === 'textarea' ?
            <Form.Group>
                <Form.Control
                    as="textarea"
                    id={id}
                    ref={_ref}
                    value={value}
                    type={type}
                    onKeyUp={onKeyUp}
                    name={name}
                    className={name}
                    rows="7"
                    minLength={minLength}
                    maxLength={maxLength}
                    required={required}
                    placeholder={placeholder ? placeholder : title}
                    onInput={checkFieldValidity}
                />
            </Form.Group>
            : type === "file" ?
                <Form.Group controlId={name} className={"fileInput " + (error ? "error" : "")}>
                    {loadingFile ? <Spinner /> : ""}
                    <Form.Label className='text-uppercase font-weight-900'>{fileLabel}</Form.Label>
                    <Form.Control
                        ref={refInputCv}
                        name={name}
                        type={type}
                        accept='.doc, .docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/jpeg, image/png, application/pdf'
                        required={required}
                        onInput={checkCv}
                    />
                    {error && (errorMessage || requiredMessage) && <p className="error-message">{typeError === 'required' ? requiredMessage : errorMessage.replace(' : ', ` ${minLength} `)}</p>}
                </Form.Group>
                : type === "checkbox" ?
                    <Row>
                        <Col className="check-container d-flex p-0 position-relative justify-content-start">
                            <Form.Group className='d-flex justify-content-center align-items-center'>
                                <Form.Check
                                    id={id}
                                    name={name}
                                    link={link}
                                    label={""}
                                    checked={checked}
                                    onChange={(e) => onChange(e.target.checked)}
                                />
                                <Link className='form-check-label font-weight-600' to={props.link} target="_blank">{title}</Link>
                            </Form.Group>
                        </Col>
                    </Row>
                    :
                    <Form.Group>
                        <Form.Control
                            id={id}
                            ref={_ref}
                            type={type}
                            value={value}
                            name={name}
                            className={name + (type === 'file' ? ' d-none' : '')}
                            onKeyUp={onKeyUp}
                            placeholder={placeholder ? placeholder : title}
                            minLength={minLength}
                            maxLength={maxLength}
                            pattern={pattern}
                            accept={accept}
                            required={required}
                            onInput={checkFieldValidity}
                        />
                    </Form.Group>
        )
        : null;


    return (
        <Row className={'FormInput input-container ' + classes}>
            {(type === "file" || type === "checkbox") ? input :
                <>
                    <Col xs={1} lg={2} className='blue-space'></Col>
                    <Col className={"input d-flex flex-column justify-content-center " + (error ? "error" : "")}>
                        <label className="title m-0 font-weight-700" htmlFor={id}>{title}{required && '*'}</label>
                        {input}
                        {error && (errorMessage || requiredMessage) && type !== 'file' && <p className="error-message">{typeError === 'required' ? requiredMessage : errorMessage.replace(' : ', ` ${minLength} `)}</p>}

                    </Col>
                </>}
        </Row>
    )
}

export default withTranslation()(FormInput)
