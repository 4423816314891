import { React, Suspense } from 'react'
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import videoBanner from '../../../assets/videos/banner_home.mp4';
import Spinner from '../../UI/Spinner/Spinner';

const HomeBanner = props => {
    return (
        <section className="p-0 cover-background" id="home-banner">
            <Suspense fallback={<Spinner />}>
                <video className='videoBanner' loop muted autoPlay>
                    <source src={videoBanner} type="video/mp4" />
                </video>
                <div className="container position-relative" >
                    <div className="row full-screen">
                        <Col sm={10} md={7} lg={6} xl={5} className="d-flex flex-column justify-content-lg-center">
                            <h2 className="text-white font-weight-800"> Today,  <br /> We Work <br />For Your Future</h2>
                        </Col>
                    </div>
                </div>
                <div className="d-md-flex flex-row justify-content-end bottom-info">
                    <div className="row align-items-center justify-content-end mx-0 w-100">
                        <Col xs={12} md={8} className="justify-content-center justify-content-md-start align-items-center d-flex bg-orange-medium">
                            <h5 className="alt-font text-white m-0">
                                {props.t("homepage:section_1.cta1")} <br />
                                <span className="font-weight-800">{props.t("homepage:section_1.cta2")}</span>
                            </h5>
                        </Col>
                        <Col xs={12} md={4} className="p-0 h-100 bg-grey-light">
                            <Link to={props.t('routes:jobs.path')} className="text-center align-items-center d-flex justify-content-center h-100 flex-column">
                                <span className="text-blue text-uppercase d-block font-weight-800">{props.t("homepage:section_1.btn")}</span>
                                <i className="line-icon-Arrow-OutRight icon-extra-large text-blue"></i>
                            </Link>
                        </Col>
                    </div>
                </div>
            </Suspense>
        </section>
    )
}

export default HomeBanner