import * as actionsTypes from "./actionsTypes";

export const fetchArticle = (id) => {
    return {
        type: actionsTypes.FETCH_ARTICLE,
        id
    }
};

export const failFetchArticle = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_FAIL,
    }
};

export const successFetchArticle = (article) => {
    return {
        type: actionsTypes.FETCH_ARTICLE_SUCCESS,
        article: article
    }
};


