import React from 'react';
import { connect } from 'react-redux';
import InfoBorderBlue from '../InfoBorderBlue/InfoBorderBlue';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const InfoBlocks = (props) => {
    const infos = props.infos;
    const infoKeys = Object.keys(infos);

    let content = null;
    let i = null;

    const max = props.twoCols ? parseInt(infoKeys.length / 2) : parseInt(infoKeys.length / 3);
    const left = props.twoCols ? parseInt(infoKeys.length % 2) : parseInt(infoKeys.length % 3);

    let displayInfo;
    let column = 1;
    let max1, max2, max3 = 0;

    if (left === 1) {
        max1 = max + 1;
        max2 = max1 + max;
        max3 = max2 + max;
    } else if (left === 2) {
        max1 = max + 1;
        max2 = max1 + max + 1;
        max3 = max2 + max;
    } else {
        max1 = max;
        max2 = 2 * max;
        max3 = 3 * max;
    }

    //build block indivisually
    const getBlock = (i) => {
        return (
            <InfoBorderBlue
                equalHeight={props.equalHeight ? true : false}
                key={i}
                title={infos[i]['title']}
                text={infos[i]['text']}
            />
        )
    }

    //display from 992px
    if (props.windowWidth >= 992) {

        displayInfo = infoKeys.map(key => {
            //when should start a new column
            if (parseInt(key) === 1 || parseInt(key) === max1 + 1 || parseInt(key) === max2 + 1) {

                content = [];
                i = parseInt(key);

                let total = column === 2 ? max2 : (column === 3 ? max3 : max1);

                //which blocks should be included in which columns
                while (i <= total) {

                    let block = getBlock(i);

                    content.push(
                        infos[i]['wrapLink'] ? (<Link to={props.t('routes:' + infos[i]['wrapLink'])} key={i}>{block}</Link>) : block
                    )
                    i += 1;
                }
                column += 1;
                return (
                    <div className="col p-0" key={key}>
                        {content}
                    </div>
                )
            }
        })

    } else if (props.windowWidth < 992 && props.windowWidth >= 768) {
        const leftTablet = infoKeys.length % 2;
        displayInfo = infoKeys.map(key => {
            //when shoul start a new column
            if (parseInt(key) === 1 || parseInt(key) === Math.ceil(infoKeys.length / 2) + 1) {
                content = [];
                i = parseInt(key);

                let total = column === 1 ? Math.ceil(infoKeys.length / 2) : infoKeys.length;
                //which blocks should be included in which columns
                while (i <= total) {
                    let block = getBlock(i);
                    content.push(
                        infos[i]['wrapLink'] ? (<Link to={props.t('routes:' + infos[i]['wrapLink'])} key={i}>{block}</Link>) : block
                    )
                    i += 1;
                }

                column += 1;

                return (
                    <div className="col" key={key}>
                        {content}
                    </div>
                )
            }
        })
    } else if (props.windowWidth < 768) {
        displayInfo = infoKeys.map(key => {
            //when shoul start a new column
            if (parseInt(key) === 1) {
                content = [];
                i = parseInt(key);

                let total = infoKeys.length;
                //which blocks should be included in which columns
                while (i <= total) {
                    let block = getBlock(i);
                    content.push(
                        infos[i]['wrapLink'] ? (<Link to={props.t('routes:' + infos[i]['wrapLink'])} key={i}>{block}</Link>) : block
                    )
                    i += 1;
                }

                column += 1;

                return (
                    <div className="col md-no-margin margin-10px-tb" key={key}>
                        {content}
                    </div>
                )
            }
        })
    }

    return (
        <section className={"info-blocks " + (props.square ? "square" : "")}>
            <Container className={props.square ? "square" : ""}>
                {props.windowWidth >= 992 && (<div className={props.twoCols ? "row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 justify-content-center" : "row row-cols-1 row-cols-md-2 row-cols-lg-3"}>
                    {displayInfo}
                </div>)}
                {props.windowWidth >= 768 && props.windowWidth <= 991 && <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    {displayInfo}
                </div>}
                {props.windowWidth < 768 && <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                    {displayInfo}
                </div>}
            </Container>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(InfoBlocks);