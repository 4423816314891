import { React } from 'react';
import { Link } from 'react-router-dom';


const Button = props => {

    return (
        <Link to={props.to} className='Button text-uppercase font-weight-800'>{props.text}</Link>
    )
}

export default Button
