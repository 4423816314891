import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Head from '../../components/Partials/Head/Head';
import Auxiliary from '../../hoc/Auxiliary';
import Banner from "../../components/UI/Banner/Banner"
import TextBlock from '../../components/UI/TextBlock/TextBlock';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import imageBanner from '../../../src/assets/images/banners/embedded_recruitment.jpg';
import InfoBlocks from '../../components/UI/InfoBlocks/InfoBlocks';
import InfoIcons from '../../components/UI/InfoIcons/InfoIcons';
import CallToAction from '../../components/CallToAction/CallToAction';

class EmbeddedRecruitment extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="embedded_recruitment" />
                <Banner bottom
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section1']['infos']}
                    search={false}
                    twoSquares
                    leftSquareColor="white"
                    rightSquareColor="blue"
                    img={imageBanner}
                />
                <OrangeTitle title={this.props.t('embeddedRecruitment:section2.title')}
                    squareColor="white"
                    squarePosition="right" />
                <TextBlock bgColor="blue" textColor="white" bgColorLeft="grey-medium" bgColorRight="grey-medium"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section2']['infos']} />
                <OrangeTitle title={this.props.t('embeddedRecruitment:section3.title')}
                    squareColor="grey-light"
                    squarePosition="left" />
                <InfoBlocks twoCols infos={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section3']['infos']} />
                <OrangeTitle title={this.props.t('embeddedRecruitment:section4.title')}
                    squareColor="white"
                    squarePosition="right" />
                <InfoIcons twoCols equalHeight
                    iconsFolder="embedded_recruitment" cube
                    content={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section4']['infos']}
                />
                <OrangeTitle title={this.props.t('embeddedRecruitment:section5.title')}
                    squareColor="grey-light"
                    squarePosition="left" />
                <InfoBlocks equalHeight infos={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section5']['infos']} />
                <OrangeTitle title={this.props.t('embeddedRecruitment:section6.title')}
                    squareColor="white"
                    squarePosition="right" />
                <InfoIcons
                    iconsFolder="embedded_recruitment" cube
                    content={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section6']['infos']}
                />
                <OrangeTitle title={this.props.t('embeddedRecruitment:section7.title')}
                    squareColor="grey-light"
                    squarePosition="left" />
                <InfoBlocks equalHeight infos={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section7']['infos']} />
                <CallToAction
                    ctaType="grey"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'embeddedRecruitment')['section8']['infos']}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(EmbeddedRecruitment)
