import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Head from '../../components/Partials/Head/Head';
import ContactBanner from "../../components/UI/ContactBanner/ContactBanner"
import ContactForm from '../../components/UI/ContactForm/ContactForm';
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import Separator from '../../components/UI/Separator/Separator';
import Auxiliary from '../../hoc/Auxiliary';

class Contacts extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="contacts" />
                <ContactBanner
                    titleBold={this.props.t('contacts:section1.titleBold')}
                    infosContacts={this.props.i18n.getResource(this.props.i18n.language, 'contacts')['section1']['infos']}
                    iconsFolder="contacts"
                />
                {
                    this.props.windowWidth >= 992 ? <Separator bgLeftColor="orange-light" bgRightColor="white" leftSide="white" rightSide="white" /> : <></>
                }
                <OrangeTitle title={this.props.t('contacts:section2.title')}
                    squareColor="white"
                    squarePosition="left" />
                <ContactForm
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'contacts')['section2']['infos']}
                />
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default withTranslation()(connect(mapStateToProps)(Contacts))