import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Form, Row, Button } from 'react-bootstrap';
import JobOffer from './JobOffer/JobOffer';
import CustomScroll from 'react-custom-scroll';
import "react-custom-scroll/dist/customScroll.css";
import Spinner from '../../UI/Spinner/Spinner';
import Auxiliary from '../../../hoc/Auxiliary';
import OfferTitle from './OfferTitle/OfferTitle';


const JobOffers = (props) => {
    const workspaceInfo = props.workspace
    const locationInfo = props.location
    const [workspace, setWorkspace] = useState("");
    const [location, setLocation] = useState("");
    const [allJobs, setallJobs] = useState(null);
    const [displayJobsList, setDisplayJobsList] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [error, seterror] = useState(null)

    const fixLocations = (job) => {
        if (job["job_title"]) {
            job['city_name'] = job['city_name'] ?
                job['city_name'] :
                job["job_title"].toLowerCase().includes("remote") ?
                    "Remote" :
                    job["job_title"].toLowerCase().includes("remoto") ?
                        "Remoto" :
                        job['city_name'];
        }
        return job;
    }

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
        //check if jobs are already in localStorage
        if (!allJobs && JSON.parse(localStorage.getItem('jobs')) !== null) {
            setallJobs(JSON.parse(localStorage.getItem('jobs')))
            seterror(false)
            setisLoading(false)
            return;
        }
    }, [])

    useEffect(() => {
        if (props.error) {
            seterror(true)
            return;
        }
        //check if jobs are already in localStorage
        if (!allJobs && JSON.parse(localStorage.getItem('jobs')) !== null) {
            setallJobs(JSON.parse(localStorage.getItem('jobs')))
            seterror(false)
            setisLoading(false)
            return;
        }

        if (props.jobs && props.jobs.length > 0 && !props.error) {
            setallJobs(props.jobs)
            setisLoading(false)
            seterror(false)
            return;
        }
    }, [props.jobs])

    useEffect(() => {
        setJobsToDisplay(allJobs)
    }, [allJobs])

    useEffect(() => {
        setJobsToDisplay(allJobs)
    }, [props.i18n.language])

    const setJobsToDisplay = (jobs) => {
        if (jobs) {
            let icon = props.iconsFolder ? props.iconsFolder + '/location.svg' : ""
            let iconSrc = icon ? require('../../../assets/icons/' + icon) : "";
            let final = Object.keys(jobs).map((job, index) => {
                if (jobs[job]["job_title"]) {
                    let job_ = fixLocations(jobs[job])
                    return (
                        < JobOffer
                            {...props}
                            key={index}
                            icon={iconSrc}
                            title={job_["job_title"]}
                            location={job_["city_name"]}
                            id={job_['job_id']}
                        />
                    )
                }
                else return false

            })
            setDisplayJobsList(final)
            setisLoading(false)
        }
    }

    const search = e => {
        e.preventDefault();
        /* check what input is reciving values */
        if (e.target.id === "workspaceInput") {
            setWorkspace(e.target.value)
        } else if (e.target.id === "locationInput") {
            setLocation(e.target.value)
        }
        if (allJobs) {
            let icon = props.iconsFolder ? props.iconsFolder + '/location.svg' : ""
            let iconSrc = icon ? require('../../../assets/icons/' + icon) : "";
            let final = Object.keys(allJobs).filter((item) => {
                /* check if there is a job title */
                if (allJobs[item]["job_title"]) {
                    /* check if the location input is not empty and if there is a city name in allJobs value */
                    if (location !== "" && allJobs[item]["city_name"]) {
                        /* load content that only includes the location value */
                        if (location !== "" && workspace === ""
                            && allJobs[item]["city_name"].toLowerCase().includes(location.toLowerCase())) {
                            return allJobs
                        }
                        /* load content that includes both location and workspace values */
                        else if (workspace !== "" && location !== ""
                            && allJobs[item]["city_name"].toLowerCase().includes(location.toLowerCase())
                            && allJobs[item]["job_title"].toLowerCase().includes(workspace.toLowerCase())) {
                            return allJobs
                        }
                    }
                    /* loads all content */
                    if (workspace === "" && location === "") {
                        return allJobs
                    }
                    /* loads content that icludes the workspace value */
                    else if (workspace !== "" && location === ""
                        && allJobs[item]["job_title"].toLowerCase().includes(workspace.toLowerCase())) {
                        return allJobs
                    }
                }
                /* if the element dont have job_title return false */
                else return false
            }).map((jobs, index) => {
                return (
                    <JobOffer
                        {...props}
                        key={index}
                        icon={iconSrc}
                        title={allJobs[jobs]["job_title"]}
                        location={allJobs[jobs]['city_name']}
                        id={allJobs[jobs]['job_id']}
                    />
                )
            })
            setDisplayJobsList(final)
        }
    };

    return (
        <Auxiliary>
            {error ? "" : <Container fluid className='JobSearch p-0'>
                {/* mobile */}
                <Container className='content-jobSearch d-lg-none'>
                    <Row className='form-container gx-0'>
                        <Form className='d-flex p-0' onSubmit={(e) => search(e)}>
                            <Col xs={1} className='blue-cube'></Col>
                            <Col xs={11} className="d-flex flex-column align-items-center p-3">
                                <div className='d-flex flex-column justify-content-around p-0 w-95 h-100'>
                                    <p className='title m-0 font-weight-900'>{workspaceInfo.title}</p>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder={workspaceInfo.placeHolder}
                                            value={workspace}
                                            pattern="^[A-Za-z ,.'-]+$"
                                            onChange={e => (setWorkspace(e.target.value))}
                                            onInput={e => (setWorkspace(e.target.value))}
                                            onKeyUp={e => (setWorkspace(e.target.value))}
                                            id="workspaceInput"
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    <Row className='form-container gx-0'>
                        <Form className='d-flex p-0' onSubmit={(e) => search(e)}>
                            <Col xs={1} className='blue-cube'></Col>
                            <Col xs={11} className="d-flex flex-column align-items-center p-3">
                                <div className='d-flex flex-column justify-content-around p-0 w-95 h-100'>
                                    <p className='title m-0 font-weight-900'>{locationInfo.title}</p>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            placeholder={locationInfo.placeHolder}
                                            value={location}
                                            pattern="^[A-Za-z ,.'-]+$"
                                            onChange={e => (setLocation(e.target.value))}
                                            onInput={e => (setLocation(e.target.value))}
                                            onKeyUp={e => (setLocation(e.target.value))}
                                            id="locationInput"
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Form>
                    </Row>
                    <Row className=' gx-0'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <Button className='w-40' onClick={(e) => search(e)}>{props.t("jobs:section2.btnText")}</Button>
                        </div>
                    </Row>
                </Container>
                {/* desktop */}
                <Container className='content-jobSearch d-none d-lg-block'>
                    <Row className='form-container'>
                        <Form className='d-flex p-0' onSubmit={(e) => search(e)}>
                            <Col md={1} className='blue-cube'></Col>
                            <Col md={7} className="d-flex flex-column justify-content-around p-3">
                                <p className='title m-0 font-weight-900'>{workspaceInfo.title}</p>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        placeholder={workspaceInfo.placeHolder}
                                        value={workspace}
                                        pattern="^[A-Za-z ,.'-]+$"
                                        onChange={e => (setWorkspace(e.target.value))}
                                        onInput={(e) => search(e)}
                                        onKeyUp={(e) => search(e)}
                                        id="workspaceInput"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4} className="d-flex flex-column justify-content-around p-3">
                                <p className='title m-0 font-weight-900'>{locationInfo.title}</p>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        placeholder={locationInfo.placeHolder}
                                        value={location}
                                        pattern="^[A-Za-z ,.'-]+$"
                                        onChange={e => (setLocation(e.target.value))}
                                        onInput={(e) => search(e)}
                                        onKeyUp={(e) => search(e)}
                                        id="locationInput"
                                    />
                                </Form.Group>
                            </Col>
                        </Form>
                    </Row>
                </Container>
            </Container>}
            <Container fluid className='JobOffers p-0'>
                {isLoading ? <div className='p-5 d-flex align-items-center justify-content-center'><Spinner /></div> : <Container className='content-jobOffers'>
                    <CustomScroll>
                        <Container className='scroll-container' >
                            {error ? <OfferTitle title={props.t('jobs:section3.errorOffers')} /> : displayJobsList && displayJobsList.length <= 0 ? <p className='noOffer font-weight-700'>{props.t("jobs:section3.job_content.infos.search")}</p> : displayJobsList}
                        </Container>
                    </CustomScroll>
                </Container>}
            </Container>
        </Auxiliary>
    )
}

const mapStateProps = state => {
    return ({
        jobs: state.jobs.jobs,
        error: state.jobs.error,
    });
}

export default connect(mapStateProps)(JobOffers)