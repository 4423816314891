import React, { useEffect, useRef, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./Map";
import Marker from "./Marker";
import { connect } from 'react-redux';


const Banner = props => {

    const add1 = useRef(null);
    const add2 = useRef(null);
    const refs = [add1, add2]

    useEffect(() => {
        if (add1.current) {
            add1.current.classList.add("active")
        }
    }, []);

    const [center, setCenter] = useState({
        lat: 38.76981375350806,
        lng: -9.0897
    }, []);

    const [zoom, setZoom] = useState(16, []);

    const markers = [
        { lat: 38.76981375350806, lng: -9.098074839038917 },
        { lat: 40.21308391479899, lng: -8.431869057115122 }
    ];

    let displayInfo;

    function ChangeMap(address) {
        if (address === "address1") {
            add1.current.classList.add("active")
            add2.current.classList.remove("active")
            setCenter({ lat: 38.76981375350806, lng: -9.0923 })
            setZoom(16)
        }
        if (address === "address2") {
            add1.current.classList.remove("active")
            add2.current.classList.add("active")
            setCenter({ lat: 40.21308391479899, lng: -8.427 })
            setZoom(16)
        }
    }

    const infosContacts = props.infosContacts;
    const infoKeys = Object.keys(infosContacts);
    let content = null;

    const getAddress = (key, index) => {
        return (
            <div key={key} className="height-100">
                {infosContacts[key]['city'] ?
                    <div className='addresses-container' ref={refs[index]} id={key} onClick={() => { ChangeMap(key) }}>
                        <Row className="contact-wrap align-items-center gx-0">
                            <div className=" iconAddress p-0">
                            </div>
                            <div className='w-75'>
                                <p className='addresses-city text-uppercase font-weight-800 m-0'>{infosContacts[key]['city']}</p>
                                <p className='addresses-address font-weight-700 m-0'>{infosContacts[key]['address']}</p>
                            </div>
                        </Row>
                    </div >
                    :
                    <div className='phoneEmail-container'>
                        {infosContacts[key]['phone'] ?
                            <a href={"tel:" + infosContacts[key]['phone'] + ""} >
                                <Row className="contact-wrap">
                                    <Col className='d-flex align-items-center'>
                                        <div className="iconPhone d-flex align-items-center justify-content-center p-0"></div>
                                        <div>
                                            <p className='contact-phone text-uppercase font-weight-700 m-0 d-flex'>{infosContacts[key]['phone']}</p>
                                            <p className='addresses-address font-weight-700 m-0 info-phone'><small>({infosContacts[key]['info']})</small></p>
                                        </div>
                                    </Col>
                                </Row>
                            </a>
                            :
                            <a className='w-100' href={"mailto:" + infosContacts[key]['email'] + ""} >
                                <Row className='w-100 contact-wrap'>
                                    <Col className='d-flex align-items-center'>
                                        <div className="iconEmail d-flex align-items-center justify-content-center p-0">
                                        </div>
                                        <div>
                                            <p className='contact-email font-weight-700 m-0'>{infosContacts[key]['email']}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </a>
                        }
                    </div>
                }
            </div >
        )
    }

    displayInfo = infoKeys.map((key, index) => {
        content = [];
        let Address = getAddress(key, index);
        content.push(
            Address
        )
        return (
            <div key={key} className="height-15 p-0">
                {content}
            </div>
        )
    })

    const render = (status) => {
        if (status === Status.FAILURE) return <h1>Erro</h1>;
        return <h1>Loading</h1>;
    };



    return (
        props.windowWidth >= 992 ?
            <Container fluid className='ContactBanner contacts'>
                <Container fluid className='map-container m-0 p-0'>
                    <Wrapper apiKey={"AIzaSyDGIPl-2DuF2ukQYcyB9COCNSC6QFRtQqU"} render={render}>
                        <Map
                            center={center}
                            zoom={zoom}
                            style={{ flexGrow: "1", height: "100%" }}
                            minZoom={4}
                            maxZoom={20}
                            zoomControl={false}
                            mapTypeControl={false}
                            scaleControl={false}
                            streetViewControl={false}
                            rotateControl={false}
                            fullscreenControl={false}
                        >
                            {markers.map((marker, index) => {
                                return <Marker position={marker} key={index} />;
                            })}
                        </Map>
                    </Wrapper>
                </Container>
                <Container className='content-container contacts'>
                    <Row className='height-100'>
                        <Col lg={{ span: 6, offset: 6 }} xl={{ span: 5, offset: 6 }} className="p-0 ">
                            <Col className='bg-orange px-5 py-4'>
                                <p className='titleBold text-uppercase m-0 font-weight-800'>{props.titleBold}</p>
                            </Col>
                            {displayInfo}
                        </Col>
                    </Row>
                </Container>
            </Container > :
            <div fluid className='ContactBanner contacts mobile'>
                <Container className='content-container contacts'>
                    <Row className='height-100'>
                        <Col lg={{ span: 6, offset: 6 }} xl={{ span: 5, offset: 6 }} className="p-0 ">
                            <Col className='bg-orange px-lg-5 py-4'>
                                <p className='titleBold text-center text-uppercase m-0 font-weight-800'>{props.titleBold}</p>
                            </Col>
                            {displayInfo}
                        </Col>
                    </Row>
                </Container>
            </div >
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(Banner);