import React, { Component } from 'react';
import Slider from "react-slick";
import { NextArrow, PrevArrow } from "../Arrows/Arrows"

export default class Carousel extends Component {

  render() {
    const settings = {
      infinite: true,
      dots: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      speed: 500,
      autoplaySpeed: 5000,
      cssEase: "linear",
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      className: this.props.className,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {this.props.children}
      </Slider>
    );
  }
}