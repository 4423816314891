import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import IconWithInfo from '../UI/IconWithInfo/IconWithInfo'
import { connect } from 'react-redux';

const Services = (props) => {

    const infos = props.infos;
    let infoKeys = Object.keys(infos.list ? infos.list : infos);

    let displayList = infoKeys.map((key) => {
        if (props.btn) {
            return (
                <Col xs={6} md={4} lg={3} key={key} className="mb-md-4 mb-lg-0">
                    <IconWithInfo
                        link={props.t('routes:' + key + '.path')}
                        title={infos.list[key]}
                        titleColor={props.titleColor}
                        btn={props.t('homepage:section3.btn')}
                        icon={props.iconsFolder + '/' + key + '.svg'}
                    />
                </Col>
            )
        } else {
            return (
                <Col xs={6} md={6} lg={4} key={key}>
                    <IconWithInfo
                        title={infos[key].title}
                        text={infos[key].text}
                        titleColor={props.titleColor}
                        icon={props.iconsFolder + '/' + key + '.svg'}
                    />
                </Col>
            )
        }

    })

    return (
        <div className="bg-blue padding-63-tb">
            <Container>
                <Row>
                    {displayList}
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        windowWidth: state.window.windowWidth,
        windowHeight: state.window.windowHeight
    }
}

export default connect(mapStateToProps)(Services)