import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { elementsToJson } from '../../../shared/functions';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/index';
import { useNavigate } from 'react-router';
import InfoModal from "../../Partials/InfoModal/InfoModal";

const FormBase = (props) => {
    const navigate = useNavigate()
    const type = props.type;
    const maxColumn = Math.floor(props.children.length / 2)
    const refForm = useRef();
    const refBtnSubmit = useRef();
    const [terms, setTerms] = useState(false);
    const [clearInput, setClearInput] = useState(false);
    const [spontaneous, setSpontaneous] = useState(false);
    const [inputValues, setInputValues] = useState(null);

    const { link_bucket, submited } = props;
    //modal content
    const [modalContent, setModalContent] = useState({
        title: null,
        textKey: null,
        btn: null,
        checkbox: null
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [openedOnce, setOpenedOnce] = useState(false);

    const checkTerms = (event, form) => {
        if (!terms) {
            // open modal with info if not opened before
            if (!openedOnce) {
                if (type === "Contact") {
                    setModalContent({
                        title: props.t('modals:termsMissingContact.title'),
                        textKey: props.t('modals:termsMissingContact.text'),
                        btn: {
                            label: props.t("partials:forms.buttonText"),
                            onClick: () => refBtnSubmit.current.click()
                        },
                        checkbox: {
                            link: props.t('routes:privacy_policy.path'),
                            label: props.t("partials:forms.check.text"),
                            onChange: setTerms
                        }
                    })
                }
                if (type === "Jobs") {
                    setModalContent({
                        title: props.t('modals:termsMissingApplication.title'),
                        textKey: props.t('modals:termsMissingApplication.text'),
                        btn: {
                            label: props.t("partials:forms.buttonText"),
                            onClick: () => refBtnSubmit.current.click()
                        },
                        checkbox: {
                            link: props.t('routes:personal_data.path'),
                            label: props.t("partials:forms.checkJobs.text"),
                            onChange: setTerms
                        }
                    })
                }
                setModalOpen(true);
                //to open only once
                setOpenedOnce(true);
            } else {
                //open modal saying it didn't submit the form
                if (type === "Contact") {
                    setModalContent({
                        title: props.t('modals:termsMissingContact.error.title'),
                        textKey: props.t('modals:termsMissingContact.error.text'),
                    })
                }
                if (type === "Jobs") {
                    setModalContent({
                        title: props.t('modals:termsMissingApplication.error.title'),
                        textKey: props.t('modals:termsMissingApplication.error.text'),
                    })
                }
                setModalOpen(true);
                //clear inputs
                setClearInput(true);
                setOpenedOnce(false);
            }
        } else {
            //check if bot input is filled
            if (event.target.form.elements.honey && event.target.form.elements.honey.value !== '') {
                setModalContent({
                    title: props.t('modals:errorSend.title'),
                    textKey: props.t('modals:errorSend.text')
                });
                setModalOpen(true);
                setClearInput(true);
                return;
            }

            //submit data
            const _elements = elementsToJson(form.elements);
            setInputValues(_elements);

            refBtnSubmit.current.disabled = true;
            refForm.current.classList.add('loading');
            //upload file to bucket
            if (form.elements.cv && form.elements.cv.files.length) {
                const file = form.elements.cv.files[0];
                props.ontryGetLinkbucket(file);
            } else {
                submitForm(_elements);
            }
        }
    }

    const handleSubmit = (event) => {
        const form = event.target.form;
        event.preventDefault();
        setClearInput(false);
        if (form.checkValidity()) {
            //check if terms are accepted
            checkTerms(event, form)
        } else {
            let scroll = true;
            const _elements = Object.entries(event.target.form.elements);
            _elements.forEach((obj) => {
                if (!isNaN(parseInt(obj[0]))) {
                    //create event
                    const event = new Event('input', {
                        bubbles: true,
                        cancelable: true,
                    });
                    //dispatch event to the input with error
                    obj[1].dispatchEvent(event);
                    if (scroll && obj[0] !== "0") {
                        scroll = false;
                        obj[1].scrollIntoView({
                            behavior: 'smooth',
                            block: 'nearest',
                            inline: 'nearest'
                        });
                    }
                }
            })
        }
    };

    const submitForm = (elements) => {
        if (elements.honey === "")
            delete elements.honey;
        refBtnSubmit.current.disabled = true;
        refForm.current.classList.add('loading');

        //submit form when link_bucket changes (useEffect)
        if (type === "Jobs") {
            elements.cv = link_bucket;
            props.onTrySubmitForm('offer', elements);
            if (elements.idOffer === "Candidatura Espontânea") {
                elements.idOffer = "";
                setSpontaneous(true)
            }
            else
                setSpontaneous(false)
        }
        if (type === "Contact") {
            props.onTrySubmitForm('contacts', elements);
        }
    }

    //open success on fail modal after submiting post
    useEffect(() => {
        if (props.submited) {
            setClearInput(true);
            refForm.current.classList.remove('loading');
            refBtnSubmit.current.disabled = false;
            props.onResetSubmit();
            setOpenedOnce(false);
            //success 
            if (type === "Contact") {
                navigate(
                    props.t("routes:contacts.path") + '/' + props.t("routes:thank_you.id"),
                    {
                        state: {
                            title: props.t("thankYou:contacts.title"),
                            text: props.t("thankYou:contacts.text"),
                            socials1: props.t("thankYou:contacts.socials-1"),
                            socials2: props.t("thankYou:contacts.socials-2")
                        }
                    }
                );
            }
            if (type === "Jobs") {
                if (spontaneous) {
                    navigate(
                        props.t("routes:jobs.path") + '/' + props.t("routes:thank_you.id"),
                        {
                            state: {
                                title: props.t("thankYou:spontaneous.title"),
                                text: props.t("thankYou:spontaneous.text"),
                                socials1: props.t("thankYou:spontaneous.socials-1")
                            }
                        }
                    );
                } else {
                    navigate(
                        props.t("routes:jobs.path") + '/' + props.t("routes:thank_you.id"),
                        {
                            state: {
                                title: props.t("thankYou:offer.title"),
                                text: props.t("thankYou:offer.text"),
                                socials1: props.t("thankYou:offer.socials-1")
                            }
                        }
                    );
                }
            }


        } else if (props.submited === false) {
            setModalContent({
                title: props.t('modals:errorSend.title'),
                textKey: props.t('modals:errorSend.text')
            });
            refForm.current.classList.remove('loading');
            refBtnSubmit.current.disabled = false;
            setModalOpen(true);
            props.onResetSubmit();
            setOpenedOnce(false);
        }
    }, [submited]);

    //when is setted a new bucket_link, send email 
    useEffect(() => {
        if (link_bucket) {
            submitForm(inputValues);
        }
        else if (link_bucket === false) {
            setModalContent({
                title: props.t('modals:jobs.errorSend.title'),
                textKey: 'modals:jobs.errorSend.text',
            });
            setModalOpen(true);
            refBtnSubmit.current.disabled = false;
            refForm.current.classList.remove('loading');
            props.onResetSubmit();

        }
    }, [link_bucket]);

    return (
        <>
            <InfoModal
                {...props}
                id={props.type + 'Modal'}
                title={modalContent.title}
                textKey={modalContent.textKey}
                btn={modalContent.btn}
                checkbox={modalContent.checkbox}
                checked={terms}
                modalOpen={modalOpen}
                closed={setModalOpen} />

            <Container fluid className={props.type + 'Form'}>
                <Container className='p-0'>
                    <Form noValidate action="POST" autoComplete="off" ref={refForm} className="form">
                        {/* to detect bots */}
                        <input type="text" name="honey" id="honey" className="d-none" autoComplete="off" />
                        <Row>
                            <Col xs={12} md={12} lg={6} className="px-0 px-lg-2">
                                {props.children.map((child, index) => {
                                    while (index <= maxColumn) {
                                        return React.cloneElement(child, { clearInput: clearInput, key: index })
                                    }
                                })}
                            </Col>
                            <Col xs={12} md={12} lg={6} className="px-0 px-lg-2">
                                {props.children.map((child, index) => {
                                    if (index > maxColumn && index <= props.children.length - 1) {
                                        if (child.props.name === "terms")
                                            return React.cloneElement(child, { clearInput: clearInput, key: index, onChange: setTerms, checked: terms })

                                        else
                                            return React.cloneElement(child, { clearInput: clearInput, key: index })
                                    }
                                })}

                            </Col>
                            <Col className='d-flex justify-content-center justify-content-md-end align-items-end px-4'>
                                <Button ref={refBtnSubmit} type='submit' onMouseDown={(e) => e.preventDefault()} onClick={(e) => handleSubmit(e)}>{props.t("partials:forms.buttonText")}</Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Container>
        </>
    )
}

const mapStateProps = state => {
    return {
        submited: state.forms.submited,
        link_bucket: state.forms.link_bucket,
    }
}

const mapDispatchProps = dispatch => {
    return {
        onTrySubmitForm: (typeForm, info) => dispatch(actions.submitForm(typeForm, info)),
        onResetSubmit: () => dispatch(actions.resetSubmit()),
        ontryGetLinkbucket: (file) => dispatch(actions.tryGetLinkBucket(file)),
    }
}


export default connect(mapStateProps, mapDispatchProps)(withTranslation()(FormBase))
