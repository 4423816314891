import React from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import Auxiliary from '../../../hoc/Auxiliary';


const SeparatorWithText = props => {

    return (
        <Container fluid="true" className={'d-none d-lg-block SeparatorWithText p-0 before-after bg-end-' + props.bgEnd + ' bg-start-' + props.bgStart}>
            <Container className='p-0'>
                <Row className='gx-0'>
                    {props.textSide === "left" ? (
                        <Auxiliary>
                            {props.autoCol ?
                                <Col className="text-container" >
                                    <span className='text font-weight-800 text-uppercase m-0 d-flex align-items-center justify-content-end'>{props.text}</span>
                                </Col>
                                :
                                <Col className="text-container" xs={6} >
                                    <span className='text font-weight-800 text-uppercase m-0 d-flex align-items-center justify-content-end'>{props.text}</span>
                                </Col>
                            }
                            <Col className="rectangle"></Col>
                            {props.spaceEnd ?
                                <Col xs={1} className="cube bg-white"></Col> : ""}
                        </Auxiliary>
                    ) :
                        <Auxiliary>
                            <Col className="rectangle"></Col>
                            {props.autoCol ?
                                <Col className="text-container" >
                                    <span className='text font-weight-800 text-uppercase m-0 d-flex align-items-center justify-content-start'>{props.text}</span>
                                </Col>
                                :
                                <Col className="text-container " xs={6}>
                                    <span className='text font-weight-800 text-uppercase m-0 d-flex align-items-center justify-content-start'>{props.text}</span>
                                </Col>
                            }
                        </Auxiliary>
                    }

                </Row>
            </Container>
        </Container >

    );
}


export default SeparatorWithText;