import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Auxiliary from '../../hoc/Auxiliary';
import Head from '../../components/Partials/Head/Head';
import Banner from '../../components/UI/Banner/Banner'
import OrangeTitle from '../../components/UI/OrangeTitle/OrangeTitle';
import InfoBlocks from '../../components/UI/InfoBlocks/InfoBlocks';
import InfoIcons from '../../components/UI/InfoIcons/InfoIcons';
import CallToAction from '../../components/CallToAction/CallToAction'
import AccordionGroup from '../../components/UI/AccordionGroup/AccordionGroup';
import Separator from '../../components/UI/Separator/Separator';
import imageBanner from '../../../src/assets/images/banners/outsourcing-em-it.jpg';
import HalfSeparator from '../../components/UI/HalfSeparator/HalfSeparator';

class Outsourcing extends Component {
    render() {
        return (
            <Auxiliary>
                <Head lang={this.props.i18n.language} {...this.props} id="outsourcing" />
                <Banner
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'outsourcing')['section1']['infos']}
                    search={false}
                    img={imageBanner}
                />
                <OrangeTitle title={this.props.t('outsourcing:section2.title')}
                    squareColor="grey-light"
                    squarePosition="left" />
                <InfoBlocks infos={this.props.i18n.getResource(this.props.i18n.language, 'outsourcing')['section2']['infos']} />
                <HalfSeparator
                    bg="orange-light"
                    pos="left"
                    mobile
                />
                <OrangeTitle title={this.props.t('outsourcing:section3.title')}
                    squareColor="white"
                    squarePosition="right" />
                <InfoIcons
                    iconsFolder="outsourcing"
                    content={this.props.i18n.getResource(this.props.i18n.language, 'outsourcing')['section3']['infos']}
                />
                <Separator bgLeftColor="orange-light" bgRightColor="white" leftSide="white" rightSide="grey-medium" />
                <OrangeTitle title={this.props.t('outsourcing:section4.title')}
                    squareColor="white"
                    squarePosition="left" />
                <AccordionGroup
                    {...this.props}
                    columns={2}
                    bg="white"
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'outsourcing')['section4']['infos']}
                />
                <CallToAction
                    ctaType="grey"
                    to={this.props.t('routes:contacts.path')}
                    infos={this.props.i18n.getResource(this.props.i18n.language, 'outsourcing')['section5']['infos']}
                />
            </Auxiliary>
        )
    }
}

export default withTranslation()(Outsourcing)