
//WINDOW
export const UPDATING_WINDOW_WIDTH_HEIGHT = 'UPDATING_WINDOW_WIDTH_HEIGHT';
export const UPDATING_HEADER_HEIGHT = 'UPDATING_HEADER_HEIGHT';

//ARTICLE
export const FETCH_ARTICLE = 'FETCH_ARTICLE';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAIL = 'FETCH_ARTICLE_FAIL';

//RECENT POSTS
export const FETCH_RECENTPOSTS = 'FETCH_POSTS';
export const FETCH_RECENTPOSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_RECENTPOSTS_FAIL = 'FETCH_POSTS_FAIL';

//POSTS
export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAIL = 'FETCH_POSTS_FAIL';

//JOBS
export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAIL = 'FETCH_JOBS_FAIL';

//FORMS
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_ERROR = 'FORM_SUBMIT_ERROR';
export const RESET_SUBMIT = 'RESET_SUBMIT';

//UPLOAD FILE TO BUCKET
export const GET_LINK_BUCKET = 'GET_LINK_BUCKET';
export const GET_LINK_BUCKET_SUCESS = 'GET_LINK_BUCKET_SUCESS';
export const GET_LINK_BUCKET_FAIL = 'GET_LINK_BUCKET_FAIL';

//COOKIES
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const REMOVE_COOKIES = 'REMOVE_COOKIES';
export const SET_BANNER_VISIBILITY = 'SET_BANNER_VISIBILITY';

//structured Data
export const SET_JOB_STRUCTURED_DATA = 'SET_JOB_STRUCTURED_DATA';