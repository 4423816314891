import * as actionTypes from '../actions/actionsTypes.js';
import { updateObject } from '../../shared/functions';


const initialSatate = {
    posts: null,
    error: false
}

const posts = (state = initialSatate, action) => {
    switch (action.type) {
        case actionTypes.FETCH_POSTS_FAIL:
            return updateObject(state, { posts: null, error: true });
        case actionTypes.FETCH_POSTS_SUCCESS:
            return updateObject(state, { posts: action.posts });
        default:
            return state;
    }
};

export default posts;