import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import InfoBorderBlue from '../UI/InfoBorderBlue/InfoBorderBlue'


const Expertise = (props) => {

    let content = props.i18n.getResource(props.i18n.language, 'homepage')['section4']['list'];
    let contentKeys = Object.keys(content)

    let display = contentKeys.map((key) => {
        return content[key]['text'] ? (
            <InfoBorderBlue
                key={key}
                title={content[key]['title']}
                text={content[key]['text']}
            />) :
            (<InfoBorderBlue
                key={key}
                title={content[key]['title']}
            />)
    })

    return (
        <Container className="expertise-mobile">
            <div className="d-flex d-md-none">
                <InfoBorderBlue>
                    {content}
                </InfoBorderBlue>

            </div>
            <Row className="d-none d-md-flex">
                <Col lg={6} className="padding-0-lr padding-63-tb d-flex flex-column justify-content-center">
                    <div>{display}</div>
                </Col>
                <Col lg={6} className="p-0">
                    <img src={props.img} className="img-fluid d-none d-lg-block" alt='' />
                </Col>
            </Row>
        </Container>
    )
}

export default Expertise