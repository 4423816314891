import React, { Component, Suspense } from "react";
import './App.scss';

import i18n from './i18n';

//import functions
import { currentRouteKey, getPathNewLang, shouldChangeLanguage, getLanguageByRoute } from './shared/functions'
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import { withRouter } from './shared/functions';

import {
    Routes,
    Route,
    Navigate,
    Link
} from "react-router-dom";

//actions
import { updateWidthAndHeight } from './store/actions/window';

//import containers
import Homepage from './containers/Homepage/Homepage';
import ExecutiveSearch from './containers/ExecutiveSearch/ExecutiveSearch';
import FAQCandidate from './containers/FAQCandidate/FAQCandidate';
import FAQCompany from './containers/FAQCompany/FAQCompany';
import Industries from './containers/Industries/Industries';
import Jobs from './containers/Jobs/Jobs';
import Job from './containers/Jobs/Job/Job';
import MissionValues from './containers/MissionValues/MissionValues';
import Nearshoring from './containers/Nearshoring/Nearshoring';
import Outsourcing from './containers/Outsourcing/Outsourcing';
import RecruitmentProcess from './containers/RecruitmentProcess/RecruitmentProcess';
import RecruitmentSelection from './containers/RecruitmentSelection/RecruitmentSelection';
import Blog from "./containers/Blog/Blog";
import Post from "./containers/Post/Post";
import Contacts from "./containers/Contacts/Contacts";
import EmbeddedRecruitment from "./containers/EmbeddedRecruitment/EmbeddedRecruitment";

//import components
import Header from "./components/Partials/Hearder/Header";
import Footer from "./components/Partials/Footer/Footer";
import ScrollToTop from "./hoc/ScrollToTop";

import CookiesPolicy from "./containers/CookiesPolicy/CookiesPolicy";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import CookiesBanner from "./components/Partials/CookiesBanner/CookiesBanner";
import PersonalData from "./containers/PersonalData/PersonalData";
import Spinner from "./components/UI/Spinner/Spinner";
import FixedSocials from "./components/UI/FixedSocials/FixedSocials";
import ThankYou from "./containers/ThankYou/ThankYou";

const Page404 = React.lazy(() => import('./containers/Page404/Page404'));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: {
                'en': i18n.getResource('en', 'routes'),
                'pt': i18n.getResource('pt', 'routes'),
                'fr': i18n.getResource('fr', 'routes'),
            },
            language: null,
            redirectComponent: null,
            modalContent: {
                id: null,
                title: null,
                textKey: null,
                btnClose: null
            },
            modalOpen: false,
        }
    }

    componentDidMount() {
        //check if should change lang by verifying the route
        if (shouldChangeLanguage(i18n)) {
            const routeLanguage = getLanguageByRoute(window.location.pathname, i18n);
            if (routeLanguage) {
                i18n.changeLanguage(routeLanguage);
                this.setState(
                    { ...this.state, redirectComponent: <Navigate to={window.location.pathname} />, language: routeLanguage },
                    () => this.setState({ ...this.state, redirectComponent: null })
                );
            }
        }

        //set initial window and haeder size
        this.updateWindowMeasures();

        //add event listener for window size
        window.addEventListener('resize', this.updateWindowMeasures);

        //fetch blog posts
        if (!this.props.posts || this.props.posts.length === 0) {
            this.props.onTryFetchPosts();
        }

        //fetch job offers
        if (!this.props.jobs || this.props.jobs.length === 0) {
            this.props.onTryFetchJobs();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowMeasures);
    }

    /** Update window and headersize*/
    updateWindowMeasures = () => {
        this.props.onUpdateWidthAndHeight(window.innerWidth, window.innerHeight)
    };

    render() {
        //changeLanguage function
        const changeLanguage = (lng) => {
            //get the key of the current path
            let [currentKey, id] = currentRouteKey(i18n);

            if (!currentKey) {
                currentKey = 'homepage';
            }
            //change language 
            i18n.changeLanguage(lng);
            //get the path in new lang
            let newPath = getPathNewLang(i18n, lng, currentKey);

            if (id)
                newPath = newPath.includes(":id") ? newPath.replace(":id", id) : newPath.includes(":slug") ? newPath.replace(":slug", id) : newPath;

            this.setState(
                { ...this.state, redirectComponent: <Navigate to={newPath} />, language: lng },
                () => this.setState({ ...this.state, redirectComponent: null })
            );
        }

        //Copy paste event
        document.addEventListener('copy', (event) => {
            const pagelink = '\n\n' + this.props.t('partials:copyText') + document.location.href;
            event.clipboardData.setData('text/plain', document.getSelection() + pagelink);
            event.preventDefault();
        });

        return (
            <div className="position-relative padding-74-t">
                {this.state.redirectComponent}
                <Header {...this.props} onChangeLanguage={(lang) => changeLanguage(lang)} />
                <FixedSocials {...this.props} />
                <ScrollToTop>
                    <Routes>
                        {[
                            this.state.routes.pt.blog.path,
                            this.state.routes.en.blog.path,
                            this.state.routes.fr.blog.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Blog />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.post.path,
                            this.state.routes.en.post.path,
                            this.state.routes.fr.post.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Suspense fallback={<Spinner />}><Post /></Suspense>}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.contacts.path,
                            this.state.routes.en.contacts.path,
                            this.state.routes.fr.contacts.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Contacts />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.homepage.path,
                            this.state.routes.en.homepage.path,
                            this.state.routes.fr.homepage.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Homepage />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.executive_search.path,
                            this.state.routes.en.executive_search.path,
                            this.state.routes.fr.executive_search.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<ExecutiveSearch />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.FAQ_candidate.path,
                            this.state.routes.en.FAQ_candidate.path,
                            this.state.routes.fr.FAQ_candidate.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<FAQCandidate />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.FAQ_company.path,
                            this.state.routes.en.FAQ_company.path,
                            this.state.routes.fr.FAQ_company.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<FAQCompany />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.industries.path,
                            this.state.routes.en.industries.path,
                            this.state.routes.fr.industries.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Industries />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.jobs.path,
                            this.state.routes.en.jobs.path,
                            this.state.routes.fr.jobs.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Jobs />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.offer.path,
                            this.state.routes.en.offer.path,
                            this.state.routes.fr.offer.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Job />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.mission_values.path,
                            this.state.routes.en.mission_values.path,
                            this.state.routes.fr.mission_values.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<MissionValues />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.nearshoring.path,
                            this.state.routes.en.nearshoring.path,
                            this.state.routes.fr.nearshoring.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Nearshoring />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.outsourcing.path,
                            this.state.routes.en.outsourcing.path,
                            this.state.routes.fr.outsourcing.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<Outsourcing />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.recruitment_process.path,
                            this.state.routes.en.recruitment_process.path,
                            this.state.routes.fr.recruitment_process.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<RecruitmentProcess />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.recruitment_selection.path,
                            this.state.routes.en.recruitment_selection.path,
                            this.state.routes.fr.recruitment_selection.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<RecruitmentSelection />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.cookies_policy.path,
                            this.state.routes.en.cookies_policy.path,
                            this.state.routes.fr.cookies_policy.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<CookiesPolicy />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.privacy_policy.path,
                            this.state.routes.en.privacy_policy.path,
                            this.state.routes.fr.privacy_policy.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<PrivacyPolicy />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.personal_data.path,
                            this.state.routes.en.personal_data.path,
                            this.state.routes.fr.personal_data.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<PersonalData />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.thank_you.path,
                            this.state.routes.en.thank_you.path,
                            this.state.routes.fr.thank_you.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<ThankYou />}
                                exact
                            />
                        ))}
                        {[
                            this.state.routes.pt.embedded_recruitment.path,
                            this.state.routes.en.embedded_recruitment.path,
                            this.state.routes.fr.embedded_recruitment.path
                        ].map(path => (
                            <Route
                                key={path}
                                path={path}
                                element={<EmbeddedRecruitment />}
                                exact
                            />
                        ))}
                        {/* Page 404 */}
                        <Route path="not-found" element={<Suspense><Page404 /></Suspense>} />
                        <Route path="*" element={<Suspense><Page404 /></Suspense>} />
                    </Routes>
                </ScrollToTop>
                {/* Force crawl FR and EN routes */}
                <Link to={this.state.routes.fr.homepage.path} className="d-none" />
                <Link to={this.state.routes.en.homepage.path} className="d-none" />
                {/* crawl personal data policy */}
                <Link to={this.state.routes.pt.personal_data.path} className="d-none" />
                <Link to={this.state.routes.en.personal_data.path} className="d-none" />
                <Link to={this.state.routes.fr.personal_data.path} className="d-none" />
                <Footer {...this.props} onChangeLanguage={(lang) => changeLanguage(lang)} />
                <CookiesBanner {...this.props} />

                {/* Crawl thank you pages */}
                <Link to={this.state.routes.fr.contacts.path + "/" + this.state.routes.fr.thank_you.id} className="d-none" />
                <Link to={this.state.routes.pt.contacts.path + "/" + this.state.routes.pt.thank_you.id} className="d-none" />
                <Link to={this.state.routes.en.contacts.path + "/" + this.state.routes.en.thank_you.id} className="d-none" />
                <Link to={this.state.routes.fr.jobs.path + "/" + this.state.routes.fr.thank_you.id} className="d-none" />
                <Link to={this.state.routes.pt.jobs.path + "/" + this.state.routes.pt.thank_you.id} className="d-none" />
                <Link to={this.state.routes.en.jobs.path + "/" + this.state.routes.en.thank_you.id} className="d-none" />
            </div>
        );
    };
}

const mapStateProps = state => {
    return ({
        posts: state.posts.posts,
        jobs: state.jobs.jobs,
    });
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateWidthAndHeight: (width, height) => dispatch(updateWidthAndHeight(width, height)),
        onTryFetchPosts: () => dispatch(actions.fetchPosts()),
        onTryFetchJobs: () => dispatch(actions.fetchJobs())
    }
};

export default withRouter(connect(mapStateProps, mapDispatchToProps)(withTranslation()(App)));
