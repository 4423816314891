import React, { useState } from 'react'
import { Row } from 'react-bootstrap';

const TeamItemTop = (props) => {
    const [open, setOpen] = useState(false);
    let teamImg = props.img ? require('../../../assets/images/team/' + props.img) : "";

    return (
        <>
            <Row className='bg-grey-light w-100 align-items-center d-lg-none name-mobile'>
                <p className="font-weight-700 text-orange-medium">{props.name}</p>
            </Row>
            <div className="member-image" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                {props.img && <img src={teamImg} className="img-fluid" alt='' />}
                <figcaption className={["bg-blue", "d-none", "d-lg-flex", "align-items-center", "flex-column", "justify-content-center", open ? "visible" : "invisible"].join(" ")}>
                    <p >{props.position}</p>
                    <span>
                        <a href={"mailto:" + props.email} className="text-white text-decoration-none">
                            {props.email}
                        </a>
                    </span>
                </figcaption>
            </div>
            <Row className='bg-blue w-100 m-0 align-items-center d-lg-none info-mobile'>
                <p className="text-white text-uppercase text-center">{props.position}</p>
                <span className="text-center">
                    <a href={"mailto:" + props.email} className="text-white text-decoration-none">
                        {props.email}
                    </a>
                </span>

            </Row>
        </>
    )
}

export default TeamItemTop
