import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { instanceWordpress } from '../../axios';


export function* tryFetchPostsSaga(action) {
    try {
        const response = yield instanceWordpress.get('wp-json/wp/v2/posts?_embed&_fields=_links.wp:featuredmedia,_embedded, id, date, slug, title,excerpt&per_page=100');
        if (!response.status === 200) {
            yield put(actions.failFetchPosts());
        }
        const posts = response.data;
        let filteredPosts = posts.filter((post) => {
            if (!post.excerpt["protected"]) {
                return post
            } else return false
        })

        yield sessionStorage.setItem('posts', JSON.stringify(filteredPosts));
        yield put(actions.successFetchPosts(filteredPosts));
    } catch (error) {
        yield put(actions.failFetchPosts());
    }
}