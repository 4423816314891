import { useNavigate, useLocation } from "react-router";


//check if should change language
export const shouldChangeLanguage = (i18n) => {
    let routes = getRoutesByLocale(i18n);
    let routeKeys = Object.values(routes);
    routeKeys.forEach((key) => {
        if (routes.key && routes.key.path === window.location.pathname) {
            return false;
        }
    })
    return true;
}

// return all routes by locale 
export const getRoutesByLocale = (i18n, lang) => {
    let language = lang ? lang : i18n.language;
    const routes = i18n.getResource(language, 'routes');
    return routes;
}

//get the key of the current path
export const currentRouteKey = (i18n) => {
    let allRoutes = getRoutesByLocale(i18n);
    const currentPathname = window.location.pathname;
    let routeKey = false;
    let id = false;

    for (let key in allRoutes) {

        let pathTemp = currentPathname;
        if (pathTemp.substring(pathTemp.length - 1) === '/' && pathTemp.length > 1) {
            pathTemp = pathTemp.substring(0, pathTemp.length - 1);
        }

        if (pathTemp.split('/').length > 2) {
            id = pathTemp.split('/')[2]
            //in case it is job offer of blog post
            pathTemp = !isNaN(id) ? "/" + pathTemp.split('/')[1] + "/:id" : "/" + pathTemp.split('/')[1] + "/:slug";
        }
        if (allRoutes[key].path === pathTemp) {
            routeKey = key;
        }
    }

    return [routeKey, id];
}

//get the path in new lang
export const getPathNewLang = (i18n, lng, key) => {
    return i18n.t(`routes:${key}.path`, { lng: lng });
}

//get lang that corresponds to current path
export const getLanguageByRoute = (route, i18n) => {
    let possibleLanguages = i18n.languages;
    let routesByLang = null;
    let response = false;
    if (route.substring(route.length - 1) === '/' && route.length > 1)
        route = route.substring(0, route.length - 1);

    if (route.split('/').length > 2)
        route = "/" + route.split('/')[1] + "/:id";

    possibleLanguages.forEach((lang) => {
        routesByLang = getRoutesByLocale(i18n, lang);
        if (Object.values(routesByLang).find((routeLang) => routeLang.path === route))
            response = lang;
    });

    return response;
}

//remove duplicated form elements
export const removeDuplicatesElements = elms => {
    let _elements = { ...elms };
    Object.keys(_elements).forEach((key) => {
        if (!isNaN(parseInt(key)))
            delete _elements[key];
    }
    );
    if (!Object.keys(_elements).length) {
        elms.forEach(e => {
            _elements[e.name] = e;
        });
    }
    return _elements;
}

//transform form elements to JSON
export const elementsToJson = elms => {
    if (!Array.isArray(elms)) {
        elms = Array.from(elms)
    }
    const _elms = removeDuplicatesElements(elms);
    let json = {};
    Object.keys(_elms).forEach((key) => {
        json[key] = key === 'terms' ? 'sim' : _elms[key].value;
    });
    return json;
}

//update object function
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
}
//add ... when number of chars is bigger than max
export const maxChar = (text, max) => {
    if (text.length > max)
        return text.substr(0, max) + ' (...)';
    else
        return text
}

// //check if we are on IE
// export const isIE = () => (navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode);



//Hook to replace withRouter
export function withRouter(Child) {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();

        return <Child {...props} navigate={navigate} location={location} />;
    }
}

/* Function to replace entity codes */
var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
})();