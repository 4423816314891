import { React, Suspense, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

const Head = props => {

    const linkServer = 'https://www.humanprofiler.com';
    const { i18n, id } = props;
    const title = 'metatags:' + id + '.title';
    let blogTitle = props.title ? props.title.replace(/<[^>]*>/g, '') : null;;
    const description = 'metatags:' + id + '.description';
    let blogDescription = props.description ? props.description.replace(/<[^>]*>/g, '') : null;
    const currentLink = navigator.userAgent !== 'ReactSnap' ? window.location.href : (linkServer + window.location.pathname);

    //shared image
    const imageName = id + '.image';
    //folder where to look for the share
    const lang = props.lang ? props.lang : props.i18n.language;
    const langFolder = lang === "pt" ? "pt" : "en";
    const img = props.i18n.exists('metatags:' + id + '.image', langFolder) ? require("../../../assets/images/shared/" + langFolder + props.i18n.getResource(langFolder, 'metatags', imageName)) : null;
    let blogShared = props.img ? props.img : null;

    useEffect(() => {
        blogTitle = props.title;
    }, [props.title]);

    useEffect(() => {
        blogDescription = props.description;

    }, [props.description]);

    useEffect(() => {
        blogShared = props.img;

    }, [props.img]);

    //Structured Data
    const [displayStructuredData, setDisplayStructuredData] = useState(props.structuredData);

    useEffect(() => {
        setDisplayStructuredData(props.structuredData)

    }, [props.structuredData]);

    useEffect(() => {
        {/* Uncomment for prod version*/ }
        if (props.cookies) {
            const head = document.querySelector("head");
            const script = document.createElement("script");
            script.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=UA-72968223-1");
            head.appendChild(script);

            const script2 = document.createElement("script");
            script2.type = "text/javascript";
            script2.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag() { window.dataLayer.push(arguments); }gtag('js', new Date()); gtag('config', 'UA-72968223-1');";
            head.appendChild(script2);
        }
    }, [props.cookies])

    let imgLink = null;

    if (img)
        imgLink = navigator.userAgent !== 'ReactSnap' ? window.location.origin + img : linkServer + img;

    if (blogShared)
        imgLink = blogShared;

    return (
        <Suspense>
            <Helmet titleTemplate="%s | Human Profiler" defaultTitle="Human Profiler">
                <html lang={lang} amp />
                {props.id === 'post' ? <meta name="title" property="og:title" content={blogTitle} /> : <meta name="title" property="og:title" content={props.i18n.getResource(langFolder, 'metatags', id + '.title')} />}
                {props.id === 'post' ? <title>{blogTitle}</title> : <title>{props.i18n.getResource(langFolder, 'metatags', id + '.title')}</title>}
                {id !== "cookiesPolicy" && id !== "privacyPolicy" &&
                    (props.id === 'post' ? <meta name="description" content={blogDescription} /> : <meta name="description" content={props.i18n.getResource(langFolder, 'metatags', id + '.description')} />)}
                {id !== "cookiesPolicy" && id !== "privacyPolicy" &&
                    (props.id === 'post' ? <meta name="og:description" content={blogDescription} /> : <meta name="og:description" content={props.i18n.getResource(langFolder, 'metatags', id + '.description')} />)}
                <meta property="og:url" content={currentLink} />
                <meta property="og:type" content="WebPage" />
                <meta property="og:site_name" content="Human Profiler" />
                {imgLink ? (<meta name="image" property="og:image" content={imgLink} />) : null}
                {/* adicionar */}
                <meta name="google-site-verification" content="EHZYy5jMtY93_Krcgg1cecyxqgL7XmEmHzyCCs9Nc8s" />
                {/* Uncomment for prod version*/}
                {displayStructuredData && <script type="application/ld+json">{displayStructuredData}</script>}
            </Helmet>
        </Suspense>
    )
}

const mapStateProps = state => {
    return {
        structuredData: state.jobs.jobStructuredData,
        cookies: state.cookies.accepted_cookies,
    }
}

export default connect(mapStateProps)(Head);