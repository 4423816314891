import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionsTypes';

import {
    tryFetchArticleSaga
} from './article';

import {
    tryFetchRecentPostsSaga
} from './recentPosts';

import {
    tryFetchPostsSaga
} from './posts';

import {
    tryFetchJobsSaga
} from './jobs';

import {
    trySubmitForm,
    getLinkBucket
} from './forms';

export function* watchArticle() {
    yield all([
        takeEvery(actionTypes.FETCH_ARTICLE, tryFetchArticleSaga),
    ]);
}

export function* watchRecentPosts() {
    yield all([
        takeEvery(actionTypes.FETCH_RECENTPOSTS, tryFetchRecentPostsSaga),
    ]);
}

export function* watchPosts() {
    yield all([
        takeEvery(actionTypes.FETCH_POSTS, tryFetchPostsSaga),
    ]);
}

export function* watchJobs() {
    yield all([
        takeEvery(actionTypes.FETCH_JOBS, tryFetchJobsSaga),
    ]);
}

export function* watchForm() {
    yield all([
        takeEvery(actionTypes.GET_LINK_BUCKET, getLinkBucket)
    ]);
    yield all([
        takeEvery(actionTypes.SUBMIT_FORM, trySubmitForm),
    ]);
}