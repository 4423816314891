import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons"
import Auxiliary from '../../../hoc/Auxiliary';
import RecentPosts from '../../Blog/RecentPosts/RecentPosts';
import logo from '../../../assets/images/logos/logo_footer.svg';
import iso9001 from '../../../assets/images/logos/iso-9001.svg';
import iso27001 from '../../../assets/images/logos/iso-27001.svg';
import iso9001PDF from '../../../assets/files/ISO-9001.pdf';
import iso27001PDF from '../../../assets/files/ISO-27001.pdf';
import { Trans } from 'react-i18next';
import FooterModal from '../../UI/FooterModal/FooterModal';


const Footer = props => {
    const currentYear = new Date().getFullYear();

    return (
        <Auxiliary>
            <footer>
                <Container className='pt-5 pb-1 px-5 px-lg-0'>
                    {/* logo */}
                    <Row>
                        <Container className='logo'>
                            <img src={logo} alt="humam profiler logo"></img>
                        </Container>
                    </Row>
                    {/* links */}
                    <Row className='d-flex justify-content-center allign-items-center'>
                        {/* certifications */}
                        <Col xs={12} md={4} lg={2} xl={2} className="m-0 m-lg-1 order-4 order-md-1">
                            {/* title */}
                            <p className='column-title font-weight-800'>{props.t('partials:footer.column1.title')}</p>
                            {/* logos */}
                            <Row className='gx-0 certifications'>
                                <Col xs={3} sm={2} md={4} lg={6} xl={5} className='ps-0 pt-0 pe-3'>
                                    <a href={iso9001PDF} target="_blank" rel="noreferrer"><Image src={iso9001} fluid="true" /></a>
                                </Col>
                                <Col xs={3} sm={2} md={4} lg={6} xl={5} className='ps-0 pt-0 pe-3'>
                                    <a href={iso27001PDF} target="_blank" rel="noreferrer"><Image src={iso27001} fluid="true" /></a>
                                </Col>
                            </Row>
                            <Row>
                                <FooterModal ims
                                    modalBtn={props.t('partials:footer.column1.columnText.1')}
                                    content={props.i18n.getResource(props.i18n.language, 'imsPolicy')['infos']}
                                />
                                <FooterModal project
                                    modalBtn={props.t('partials:footer.column1.columnText.2')}
                                    content={props.i18n.getResource(props.i18n.language, 'projectSheet')['infos']}
                                />
                                <Link to={props.t('routes:privacy_policy.path')}>{props.t('partials:footer.column1.columnText.3')}</Link>
                                <Link to={props.t('routes:cookies_policy.path')}>{props.t('partials:footer.column1.columnText.4')}</Link>
                            </Row>
                        </Col>
                        {/* socials */}
                        <Col xs={12} md={4} lg={2} xl={2} className="m-0 m-lg-1 order-5 order-md-2">
                            {/* icons */}
                            <Row className='column-title font-weight-800 gx-0'>
                                {/* linkein */}
                                {/* <Col xs md={2} lg={3} xl={2}> */}
                                <a href="https://www.linkedin.com/company/humanprofiler/" target="_blank" rel="noreferrer" className='me-2 social-icons  d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                </a>
                                {/* </Col> */}
                                {/* instagram */}
                                {/* <Col xs md={2} lg={3} xl={2}> */}
                                <a href="https://www.instagram.com/human_profiler/" target="_blank" rel="noreferrer" className='me-2 social-icons  d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                {/* </Col> */}
                                {/* youtube */}
                                {/* <Col xs md={2} lg={3} xl={2}> */}
                                <a href="https://www.youtube.com/channel/UC5uAOWarjU3Sr4h4rbork7g" target="_blank" rel="noreferrer" className='me-2 social-icons  d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faYoutube} />
                                </a>
                                {/* </Col> */}
                                {/* facebook */}
                                {/* <Col xs md={2} lg={3} xl={2}> */}
                                <a href="https://www.facebook.com/HumanProfiler/" target="_blank" rel="noreferrer" className='me-2 social-icons  d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </a>
                                {/* </Col> */}
                            </Row>
                            {/* links */}
                            <Col>
                                <Link to={props.t('routes:mission_values.path')}>{props.t('partials:footer.column2.columnText.1')}</Link>
                                <Link to={props.t('routes:contacts.path')}>{props.t('partials:footer.column2.columnText.2')}</Link>
                                <Link to={props.t('routes:FAQ_company.path')}>{props.t('partials:footer.column2.columnText.3')}</Link>
                                <Link to={props.t('routes:FAQ_candidate.path')}>{props.t('partials:footer.column2.columnText.4')}</Link>
                            </Col>
                        </Col>
                        {/* company */}
                        <Col xs={12} md={4} lg={2} xl={2} className="m-0 m-lg-1 order-1 order-md-3">
                            {/* title */}
                            <p className='column-title font-weight-800'>{props.t('partials:footer.column3.title')}</p>
                            {/* links */}
                            <Col>
                                <Link to={props.t('routes:recruitment_selection.path')}>{props.t('partials:footer.column4.columnText.2')}</Link>
                                <Link to={props.t('routes:executive_search.path')}>{props.t('partials:footer.column4.columnText.3')}</Link>
                                <Link to={props.t('routes:outsourcing.path')}>{props.t('partials:footer.column3.columnText.1')}</Link>
                                <Link to={props.t('routes:nearshoring.path')}>{props.t('partials:footer.column3.columnText.2')}</Link>
                                <Link to={props.t('routes:industries.path')}>{props.t('partials:footer.column3.columnText.3')}</Link>
                            </Col>
                        </Col>
                        {/* job */}
                        <Col xs={12} md={4} lg={2} xl={2} className="m-0 m-lg-1 order-2 order-md-4">
                            {/* title */}
                            <p className='column-title font-weight-800'>{props.t('partials:footer.column4.title')}</p>
                            {/* links */}
                            <Col>
                                <Link to={props.t('routes:recruitment_process.path')}>{props.t('partials:footer.column4.columnText.1')}</Link>
                                <Link to={props.t('routes:jobs.path')}>{props.t('partials:footer.column4.columnText.5')}</Link>
                                <a href="https://xchangejob.com/" target="_blank" rel="noreferrer">{props.t('partials:footer.column4.columnText.6')}</a>
                            </Col>
                        </Col>
                        {/* blog */}
                        {props.i18n.language === 'pt' ?
                            (<Col xs={12} md={8} lg={3} xl={3} className="m-0 m-lg-1 order-3 order-md-5">
                                {/* title */}
                                <p className='column-title font-weight-800'>{props.t('partials:footer.column5.title')}</p>
                                {/* links */}
                                <Col>
                                    <RecentPosts {...props} total={2} fluid={true} />
                                </Col>
                            </Col>) : null}
                    </Row>
                    {/* copyright */}
                    <Row className='text-center copyright'>
                        <p className="text-orange-light">
                            <Trans
                                t={props.t}
                                i18nKey={props.t('partials:footer.copyRight.text')}
                                components={
                                    {
                                        HereAndNow: <a href={props.t('partials:footer.copyRight.link')} target="_blank" rel="noreferrer" className='link text-orange-light'></a>,
                                        year: `${currentYear}`
                                    }
                                }
                            />
                        </p>
                    </Row>
                </Container>
            </footer>
        </Auxiliary >
    );
}

export default Footer;