//To support IE
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';
import createSagaMiddleware from 'redux-saga';
import { watchRecentPosts } from './store/sagas/index';
import { watchArticle } from './store/sagas/index';
import { watchPosts } from './store/sagas/index';
import { watchJobs } from './store/sagas/index';
import { watchForm } from './store/sagas/index';
// react-snap
import { hydrate, render } from "react-dom";

//routes
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

//REDUCERS
import windowReducer from './store/reducers/window';
import recentPostsReducer from './store/reducers/recentPosts';
import postsReducer from './store/reducers/posts';
import jobsReducer from './store/reducers/jobs';
import formsReducer from './store/reducers/forms';
import cookiesReducer from './store/reducers/cookies';
import articleReducer from './store/reducers/article';

//sagas
const composeEnhancers = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : (null || compose);
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
    window: windowReducer,
    article: articleReducer,
    recentPosts: recentPostsReducer,
    posts: postsReducer,
    jobs: jobsReducer,
    forms: formsReducer,
    cookies: cookiesReducer
});
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk, sagaMiddleware)
));

sagaMiddleware.run(watchRecentPosts);
sagaMiddleware.run(watchPosts);
sagaMiddleware.run(watchArticle);
sagaMiddleware.run(watchJobs);
sagaMiddleware.run(watchForm);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode >,
        rootElement
    );
} else {
    render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        rootElement
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
